import { _delete, get, patch, post, put } from "./request";

export async function getAllFolios(domain, token) {
  const data = await get(domain, token, "/api/folios");
  return data;
}

export async function getOneFolio(domain, token, id) {
  const data = await get(domain, token, `/api/folios/${id}`);
  return data;
}

export async function postFolio(domain, token, body) {
  const data = await post(domain, token, "/api/folios", body);
  return data;
}

export async function putFolio(domain, token, body, id) {
  const data = await put(domain, token, `/api/folios/${id}`, body);
  return data;
}

export async function patchFolio(domain, token, body, id) {
  const data = await patch(domain, token, `/api/folios/${id}`, body);
  return data;
}

export async function deleteFolio(domain, token, id) {
  const data = await _delete(domain, token, `/api/folios/${id}`);
  return data;
}
