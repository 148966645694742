import React, { useContext, useEffect, useState } from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import Pencil from '../assets/icons/pencil.svg';
import Plus from '../assets/icons/plus-1.svg';
import Modal from '../components/common/Modal'
import { ReactSVG } from 'react-svg'
import WarehouseForm from '../components/warehouse/WarehouseForm';
import { AppContext } from '../context/AppContext';
import { getAllWarehouses } from '../api/warehouses';
import { DataTable } from '../components/ui/data-table';
import Eye from '../assets/icons/eye.svg';

function Warehouses() {
  const [loader, setLoader] = useState(false)
  const { domain, token } = useContext(AppContext);
  const [warehouses, setWarehouses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [editMode, setEditMode] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true)
        const warehouses = await getAllWarehouses(domain, token)
        setWarehouses(warehouses)
        setLoader(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [])

  async function addWarehouse(name, branchId) {
    setLoader(true)
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    const response = await fetch(`${urlApi}/api/warehouse`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name: name,
        branch_id: branchId
      })
    })
    await response.json()
    const data = await getAllWarehouses(domain, token)
    setWarehouses(data)
    setLoader(false)
  }

  const columns = [
    {
      accessorKey: "id",
      header: "#",
    },
    {
      accessorKey: "name",
      header: "Nombre",
    },
    {
      accessorKey: "branch",
      header: "Sucursal",
      cell: ({ row }) => {
        const warehouse = row.original
        return (
          <>
            <p>{warehouse.branch.name}</p>
          </>
        )
      }
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const warehouse = row.original
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className='text-dark' />}
              title={warehouse.name}
              className={"px-2 button-no-style"}
              content={<p>hi</p>}
            />
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className='text-dark' />}
              title={warehouse.name}
              className={"px-2 button-no-style"}
              content={<p>hi</p>}
            />
          </>
        )
      },
    }
  ]

  async function exportToExcel() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(warehouses);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelBlob, "data" + fileExtension)
  }

  return (
    <div>
      <h2>Bodegas</h2>
      <DataTable
        data={warehouses}
        columns={columns}
        exportToExcel={exportToExcel}
        Extra={<Modal
          name={
            <>Nueva bodega <ReactSVG src={Plus} className='text-black ml-2' /></>
          }
          title="Nueva bodega"
          className={"w-100 mx-md-2 btn"}
          content={<WarehouseForm addWarehouse={addWarehouse} />}
        />}
      />
    </div>
  )
}

export default Warehouses