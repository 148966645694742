import { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { getAllPacks, deletePacks } from "../api/packs"; // Ensure deleteClients is imported
import Swal from "sweetalert2";
import Eye from "../assets/icons/eye.svg";
import Pencil from "../assets/icons/pencil.svg";
import Trash from "../assets/icons/trash.svg";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ReactSVG } from "react-svg";
import Modal from "../components/common/Modal";
import { DataTable } from "../components/ui/data-table";
import PackForm from "../components/packs/PackForm";
import { getAllProducts } from "../api/products";

function Packs() {
  const { domain, token } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [packs, setPacks] = useState([]);
  const [products, setProducts] = useState([]);

  const columns = [
    {
      accessorKey: "name_pack",
      header: "Nombre de la promoción",
      filterFn: (row, columnId, filterValue) => {
        return row.original.names.includes(filterValue);
      },
    },
    {
      accessorKey: "type",
      header: "Tipo de descuento",
    },
    {
      accessorKey: "amount_pack",
      header: "Valor de la promoción",
      filterFn: (row, columnId, filterValue) => {
        // Corrected typo here
        return row.original.last_names.includes(filterValue);
      },
    },
    {
      accessorKey: "discount",
      header: "Descuento",
      filterFn: (row, columnId, filterValue) => {
        // Corrected typo here
        return row.original.last_names.includes(filterValue);
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const pack = row.original;
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className="text-dark" />}
              title={pack.name}
              className={"px-2 button-no-style"}
              content={
                <PackForm
                  initialValues={pack}
                  products={products}
                  onlyView={true}
                />
              }
            />
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className="text-dark" />}
              title={pack.name}
              className={"px-2 button-no-style"}
              content={
                <PackForm
                  initialValues={pack}
                  products={products}
                  edit={true}
                  updatePacks={setPacks}
                />
              }
            />
            <button
              className="px-2 button-no-style"
              onClick={() => {
                deletePacksHandler(pack); // Updated function call
              }}
            >
              <ReactSVG src={Trash} className="text-dark" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  async function getPacks() {
    const data = await getAllPacks(domain, token); // Added await
    console.log("Works");
    setPacks(data);
  }

  async function getProducts() {
    const data = await getAllProducts(domain, token);
    console.log(data);
    setProducts(data);
  }

  async function deletePacksHandler(item) {
    // Renamed function to avoid conflict
    if (item.id) {
      await deletePacks(domain, token, item.id);
      await getPacks();
      Swal.fire({
        title: "Promoción Eliminada con éxito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoader(false);
    }
  }

  async function exportToExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Prepara los datos
    const data = packs.map((element) => ({
      Nombre_promocion: element.name_pack,
      Tipo_de_descuento: element.type,
      Valor: element.amount_pack,
    }));

    // Convierte los datos a una hoja de Excel
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { Promociones: ws }, SheetNames: ["Promociones"] };

    // Genera el archivo en formato array
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convierte el buffer a un Blob y lo guarda
    const excelBlob = new Blob([excelBuffer], { type: fileType });

    // Usa FileSaver para descargar el archivo
    FileSaver.saveAs(excelBlob, `promociones${fileExtension}`);
  }

  const fetchData = async () => {
    try {
      setLoader(true);
      await getPacks();
      await getProducts(); // Added await
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const extra = (
    <>
      <Modal
        name="Añadir promoción"
        title="Nuevo promoción"
        className={"w-100"}
        content={
          <PackForm newPack={true} updatePacks={setPacks} products={products} />
        }
      />
    </>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            className="w-20 h-20 animate-spin" // Corrected class attribute
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Promociones</h2>
          <DataTable
            exportToExcel={exportToExcel}
            columns={columns}
            data={packs}
            isProduct={true}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Packs;
