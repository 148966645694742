import React, { useState, useEffect, useContext } from "react";
import { object, string, boolean } from "yup";
import { Formik } from "formik";
import { AppContext } from "../../context/AppContext";
import {
  getAllClients,
  postClients,
  patchClients,
  putClients,
} from "../../api/clients";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
const swal = require("sweetalert2");
const validationSchema = object().shape({
  kind: string().required("Tipo de cliente es requerido"),
  names: string().required("Nombres son requeridos"),
  last_names: string().required("Apellidos son requeridos"),
  rut: string()
    .matches(/^\d{1,2}\.\d{3}\.\d{3}-[0-9Kk]$/, "Rut no es válido")
    .required("Rut es requerido"),
  business_name: string().nullable(),
  activity: string().nullable(),
  district: string().required("Comuna es requerida"),
  city: string().required("Ciudad es requerida"),
  address: string().required("Dirección es requerida"),
  email: string().email("Email no es válido").required("Email es requerido"),
  phone: string().required("Celular es requerido"),
  foreing: boolean(),
});

const ClientForm = ({
  initialValues,
  onlyView,
  edit,
  updateClients,
  handleClose,
}) => {
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);
  const [client, setClient] = useState({
    kind: "",
    names: "",
    last_names: "",
    rut: "",
    business_name: null,
    activity: null,
    district: "",
    city: "",
    address: "",
    email: "",
    phone: "",
    foreing: false,
  });
  const kinds = ["Cliente", "Empresa"];

  // Si se proporcionan initialValues, establecer el estado inicial del producto
  // useEffect(() => {
  //   setLoader(true);
  //   if (initialValues) {
  //     setClient({
  //       kind: initialValues.kind,
  //       names: initialValues.name,
  //       last_names: initialValues.last_names,
  //       rut: initialValues.rut,
  //       business_name: initialValues.business_name,
  //       district: initialValues.district,
  //       city: initialValues.city,
  //       address: initialValues.address,
  //       email: initialValues.email,
  //       phone: initialValues.phone,
  //       foreing: initialValues.foreing,
  //     });
  //     setLoader(false);
  //   }
  //   setLoader(false);
  // }, [initialValues]);

  useEffect(() => {
    if (initialValues) {
      setClient(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoader(true);
    try {
      const clients = await getAllClients(domain, token);
      if (clients.some((client) => client.rut === values.rut)) {
        swal.fire({
          title: "Usuario ya ingresado anteriormente",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      const res = await postClients(domain, token, values);
      if (res.id) {
        await updateClients(clients);
        setClient({
          kind: "",
          names: "",
          last_names: "",
          rut: "",
          business_name: "",
          district: "",
          city: "",
          address: "",
          email: "",
          phone: "",
          foreing: false,
        });
        handleClose();
        swal.fire({
          title: "Cliente Ingresado con exito",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        window.location.reload();
      } else {
        swal.fire({
          title: "Error al ingresar cliente",
          text: `Detalle: ${JSON.stringify(res)}`,
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      swal.fire({
        title: "Error al ingresar cliente",
        text: `Detalle: ${error.message}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   setLoader(true);
  //   e.preventDefault();
  //   if (
  //     client.kind !== "" &&
  //     client.names !== "" &&
  //     client.last_names !== "" &&
  //     client.rut !== "" &&
  //     client.email !== "" &&
  //     client.district !== "" &&
  //     client.city !== "" &&
  //     client.phone !== ""
  //   ) {
  //     const data = {
  //       kind: client.kind,
  //       names: client.names,
  //       last_names: client.last_names,
  //       rut: client.rut,
  //       business_name: client.business_name,
  //       district: client.district,
  //       city: client.city,
  //       address: client.address,
  //       email: client.email,
  //       phone: client.phone,
  //       foreing: client.foreing,
  //     };
  //     console.log("Data being sent:", data); // Log the data being sent
  //     try {
  //       const res = await postClients(domain, token, data);
  //       console.log("Response from API:", res); // Log the response from the API
  //       if (res.id) {
  //         const clients = await getAllClients(domain, token);
  //         await updateClients(clients);
  //         setClient({
  //           kind: "",
  //           names: "",
  //           last_names: "",
  //           rut: "",
  //           business_name: "",
  //           district: "",
  //           city: "",
  //           address: "",
  //           email: "",
  //           phone: "",
  //           foreing: false,
  //         });
  //         setLoader(false);
  //         handleClose();
  //         swal.fire({
  //           title: "Cliente Ingresado con exito",
  //           icon: "success",
  //           toast: true,
  //           timer: 6000,
  //           position: "top-right",
  //           timerProgressBar: true,
  //           showConfirmButton: false,
  //         });
  //       } else {
  //         swal.fire({
  //           title: "Error al ingresar cliente",
  //           text: `Detalle: ${JSON.stringify(res)}`,
  //           icon: "error",
  //           toast: true,
  //           timer: 6000,
  //           position: "top-right",
  //           timerProgressBar: true,
  //           showConfirmButton: false,
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error during API call:", error); // Log any errors
  //       swal.fire({
  //         title: "Error al ingresar cliente",
  //         text: `Detalle: ${error.message}`,
  //         icon: "error",
  //         toast: true,
  //         timer: 6000,
  //         position: "top-right",
  //         timerProgressBar: true,
  //         showConfirmButton: false,
  //       });
  //     }
  //   } else {
  //     setLoader(false);
  //     swal.fire({
  //       title: "Error al ingresar cliente, faltan atributos",
  //       icon: "error",
  //       toast: true,
  //       timer: 6000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //   }
  // };

  const handleUpdate = async (values, { setSubmitting }) => {
    setLoader(true);
    const data = {
      kind: values.kind,
      names: values.names,
      last_names: values.last_names,
      rut: values.rut,
      business_name: values.business_name,
      district: values.district,
      city: values.city,
      address: values.address,
      email: values.email,
      phone: values.phone,
      foreing: values.foreing,
    };

    try {
      await putClients(domain, token, data, initialValues.id);
      const clients = await getAllClients(domain, token);
      await updateClients(clients);
      handleClose();
      swal.fire({
        title: "Cliente Actualizado con exito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      swal.fire({
        title: "Error al actualizar cliente",
        text: `Detalle: ${error.message}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={client}
      validationSchema={validationSchema}
      onSubmit={edit ? handleUpdate : handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {loader ? (
            <div className="flex justify-center w-full align-center items-center">
              <img
                className="w-20 h-20 animate-spin"
                src="https://www.svgrepo.com/show/70469/loading.svg"
                alt="Loading icon"
              ></img>
            </div>
          ) : (
            <>
              <div className="columns-2">
                <div className="">
                  <div className="mb-3">
                    <Label htmlFor="names">
                      Nombres
                      <Input
                        type="text"
                        className="form-control"
                        id="names"
                        name="names"
                        value={values.names}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.names && touched.names && (
                        <div className="text-red-500">{errors.names}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="last_names">
                      Apellidos
                      <Input
                        type="text"
                        className="form-control"
                        id="last_names"
                        name="last_names"
                        value={values.last_names}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.last_names && touched.last_names && (
                        <div className="text-red-500">{errors.last_names}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="kind">Tipo de cliente:</Label>
                    <Select
                      id="kind"
                      name="kind"
                      value={values.kind}
                      onValueChange={(value) => {
                        handleChange({
                          target: {
                            name: "kind",
                            value: value,
                            type: "select",
                          },
                        });
                      }}
                      disabled={onlyView && true}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Seleccionar Tipo de cliente" />
                      </SelectTrigger>
                      <SelectContent>
                        {kinds.map((kind) => (
                          <SelectItem key={kind} value={`${kind}`}>
                            {kind}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.kind && touched.kind && (
                      <div className="text-red-500">{errors.kind}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="rut">
                      Rut
                      <Input
                        type="text"
                        step="0.01"
                        id="rut"
                        name="rut"
                        value={values.rut}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.rut && touched.rut && (
                        <div className="text-red-500">{errors.rut}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="activity">
                      Actividad
                      <Input
                        type="text"
                        id="activity"
                        name="activity"
                        value={values.activity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={onlyView && true}
                      />
                      {errors.activity && touched.activity && (
                        <div className="text-red-500">{errors.activity}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="business_name">
                      Nombre del negocio
                      <Input
                        type="text"
                        id="business_name"
                        name="business_name"
                        value={values.business_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={onlyView && true}
                      />
                      {errors.business_name && touched.business_name && (
                        <div className="text-red-500">
                          {errors.business_name}
                        </div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="district">
                      Comuna
                      <Input
                        type="text"
                        id="district"
                        name="district"
                        value={values.district}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.district && touched.district && (
                        <div className="text-red-500">{errors.district}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="city">
                      Ciudad
                      <Input
                        type="text"
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.city && touched.city && (
                        <div className="text-red-500">{errors.city}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="email">
                      Email
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.email && touched.email && (
                        <div className="text-red-500">{errors.email}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="phone">
                      Celular
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.phone && touched.phone && (
                        <div className="text-red-500">{errors.phone}</div>
                      )}
                    </Label>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="address">
                      Dirección
                      <Input
                        type="text"
                        id="address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={onlyView && true}
                      />
                      {errors.address && touched.address && (
                        <div className="text-red-500">{errors.address}</div>
                      )}
                    </Label>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="foreing">
                      Extranjero
                      <Checkbox
                        id="foreing"
                        name="foreing"
                        checked={values.foreing}
                        onCheckedChange={(checked) => {
                          handleChange({
                            target: {
                              name: "foreing",
                              value: checked,
                              type: "checkbox",
                              checked,
                            },
                          });
                        }}
                        disabled={onlyView && true}
                        className="ml-2"
                      />
                      {errors.foreing && touched.foreing && (
                        <div className="text-red-500">{errors.foreing}</div>
                      )}
                    </Label>
                  </div>
                </div>
              </div>

              {!onlyView && !edit && (
                <div className="flex flex-col">
                  <Button type="submit">Añadir cliente</Button>
                </div>
              )}
              {edit && (
                <div className="flex flex-col">
                  <Button type="submit" className="w-full">
                    Actualizar cliente
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
      )}
    </Formik>
    // <form onSubmit={handleSubmit}>
    //   {loader ? (
    //     <div className="flex justify-center w-full align-center items-center">
    //       <img
    //         class="w-20 h-20 animate-spin"
    //         src="https://www.svgrepo.com/show/70469/loading.svg"
    //         alt="Loading icon"
    //       ></img>
    //     </div>
    //   ) : (
    //     <>
    //       <div className="columns-2">
    //         <div className="">
    //           <div className="mb-3">
    //             <Label htmlFor="names">
    //               Nombres
    //               <Input
    //                 type="text"
    //                 className="form-control"
    //                 id="names"
    //                 name="names"
    //                 value={client.names}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="last_names">
    //               Apellidos
    //               <Input
    //                 type="text"
    //                 className="form-control"
    //                 id="last_names"
    //                 name="last_names"
    //                 value={client.last_name}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="kind">Tipo de cliente:</Label>
    //             <Select
    //               id="kind"
    //               name="kind"
    //               value={client.kind}
    //               onValueChange={(value) => {
    //                 handleChange({
    //                   target: {
    //                     name: "kind",
    //                     value: value,
    //                     type: "select",
    //                   },
    //                 });
    //               }}
    //               disabled={onlyView && true}
    //             >
    //               <SelectTrigger className="w-[180px]">
    //                 <SelectValue placeholder="Seleccionar Tipo de cliente" />
    //               </SelectTrigger>
    //               <SelectContent>
    //                 {kinds.map((kind) => (
    //                   <SelectItem key={kind} value={`${kind}`}>
    //                     {kind}
    //                   </SelectItem>
    //                 ))}
    //               </SelectContent>
    //             </Select>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="rut">
    //               Rut
    //               <Input
    //                 type="text"
    //                 step="0.01"
    //                 id="rut"
    //                 name="rut"
    //                 value={client.rut}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="activity">
    //               Actividad
    //               <Input
    //                 type="text"
    //                 id="activity"
    //                 name="activity"
    //                 value={client.activity}
    //                 onChange={handleChange}
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="business_name">
    //               Nombre del negocio
    //               <Input
    //                 type="text"
    //                 id="business_name"
    //                 name="business_name"
    //                 value={client.business_name}
    //                 onChange={handleChange}
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="district">
    //               Comuna
    //               <Input
    //                 type="text"
    //                 id="district"
    //                 name="district"
    //                 value={client.district}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="city">
    //               Ciudad
    //               <Input
    //                 type="text"
    //                 id="city"
    //                 name="city"
    //                 value={client.city}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="email">
    //               Email
    //               <Input
    //                 type="text"
    //                 id="email"
    //                 name="email"
    //                 value={client.email}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="phone">
    //               Celular
    //               <Input
    //                 type="text"
    //                 id="phone"
    //                 name="phone"
    //                 value={client.phone}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>
    //           <div className="mb-3">
    //             <Label htmlFor="address">
    //               Dirección
    //               <Input
    //                 type="text"
    //                 id="address"
    //                 name="address"
    //                 value={client.address}
    //                 onChange={handleChange}
    //                 required
    //                 disabled={onlyView && true}
    //               />
    //             </Label>
    //           </div>

    //           <div className="mb-3">
    //             <Label htmlFor="foreing">
    //               Extranjero
    //               <Checkbox
    //                 id="foreing"
    //                 name="foreing"
    //                 checked={client.foreing}
    //                 onCheckedChange={(checked) => {
    //                   handleChange({
    //                     target: {
    //                       name: "foreing",
    //                       value: checked,
    //                       type: "checkbox",
    //                       checked,
    //                     },
    //                   });
    //                 }}
    //                 disabled={onlyView && true}
    //                 className="ml-2"
    //               />
    //             </Label>
    //           </div>
    //         </div>
    //       </div>

    //       {!onlyView && !edit && (
    //         <div className="flex flex-col">
    //           <Button type="submit">Añadir cliente</Button>
    //         </div>
    //       )}
    //       {edit && (
    //         <div className="flex flex-col">
    //           <Button onClick={handleUpdate} className="w-full">
    //             Actualizar producto
    //           </Button>
    //         </div>
    //       )}
    //     </>
    //   )}
    // </form>
  );
};

export default ClientForm;
