import React from "react";
import { DataTable } from "../ui/data-table";
import { Button } from "../ui/button";
import RefundEdit from "./RefundEdit";
import Modal from "../common/Modal";

const RefundList = ({ folios, deleteFolio, editFolio }) => {
  const columns = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "folio",
      header: "Folio",
    },
    {
      accessorKey: "kind_of_note",
      header: "Tipo de Nota",
    },
    {
      accessorKey: "motive",
      header: "Motivo",
    },
    {
      accessorKey: "actions",
      header: "Acciones",
      cell: ({ row }) => {
        const folio = row.original;
        return (
          <>
            <Modal
              name="Editar"
              title="Editar devolución"
              className={"w-100 mb-2 btn-primary"}
              content={<RefundEdit folio={folio} editFolio={editFolio} />}
            />
            <Button
              onClick={() => {
                deleteFolio(folio.id);
              }}
            >
              Eliminar devolución
            </Button>
          </>
        );
      },
    },
  ];
  const extra = (
    <>
      <Modal
        name="Añadir Devulución"
        title="Nuevo producto"
        className={"w-100"}
        content={<RefundEdit />}
      />
    </>
  );

  return (
    <div>
      <h4>Lista de Devoluciones</h4>
      <DataTable columns={columns} data={folios} Extra={extra} />
    </div>
  );
};

export default RefundList;
