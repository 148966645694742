import { _delete, get, patch, post, put } from "./request";

export async function getAllSales(domain,token,){
    const data = await get(domain,token,'/api/sales')
    return data
}

export async function getOneSale(domain,token,id){
    const data = await get(domain,token,`/api/sales/${id}`)
    return data
}

export async function postSale(domain,token,body){
    const data = await post(domain,token,'/api/sales',body)
    return data
}

export async function putSale(domain,token,body,id){
    const data = await put(domain,token,`/api/sales/${id}`,body)
    return data
}

export async function patchSale(domain,token,body,id){
    const data = await patch(domain,token,`/api/sales/${id}`,body)
    return data
}

export async function deleteSale(domain,token,id){
    const data = await _delete(domain,token,`/api/sales/${id}`)
    return data
}