import React, { useContext, useEffect, useState } from 'react';
import SideBar from '../components/common/SideBar'
import { AppContext } from '../context/AppContext';
import AuthContext from '../context/AuthContext';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';

const PrivateLayout = ({ children }) => {
    const {
        domain,
        token,
        openSideBar,
        setOpenSideBar,
        setSuccesSale,
        branchId,
        setBranchId,
    } = useContext(AppContext);
    const { user, logoutUser } = useContext(AuthContext);

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                await getBranches();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    async function getBranches() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/branch`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        const data = await response.json();
        setBranches(data);
    }

    return (
        <>
            <div>
                {/* Navbar */}
                <nav>
                    <div className="flex justify-between items-center p-2">
                        <div className="flex items-center">
                            <button onClick={() => { setOpenSideBar(!openSideBar) }} className='pl-2'>
                                {openSideBar ? (
                                    <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18" stroke="black" strokeWidth="2" />
                                        <line x1="6" y1="6" x2="18" y2="18" stroke="black" strokeWidth="2" />
                                    </svg>
                                ) : (
                                    <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                    >
                                        <line x1="3" y1="13" x2="23" y2="13" stroke="black" strokeWidth="2" />
                                        <line x1="3" y1="7" x2="23" y2="7" stroke="black" strokeWidth="2" />
                                        <line x1="3" y1="19" x2="23" y2="19" stroke="black" strokeWidth="2" />
                                    </svg>
                                )}
                            </button>
                            <div className='ml-3'>
                                <p style={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    margin: 0,
                                    padding: 0
                                }}>{process.env.REACT_APP_ERP_NAME}</p>
                            </div>
                        </div>
                        <div className='flex items-center justify-center'>
                            <Select
                                onChange={(e) => { setBranchId(e.target.value) }}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Sucursal" />
                                </SelectTrigger>
                                <SelectContent>
                                    {branches.map((branch) => (
                                        <SelectItem value={branch.id}>{branch.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <DropdownMenu>
                                <DropdownMenuTrigger>
                                    <Avatar>
                                        <AvatarImage src="https://github.com/shadcn.png" />
                                        <AvatarFallback>CN</AvatarFallback>
                                    </Avatar>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuLabel>Mi cuenta</DropdownMenuLabel>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem>
                                        <button onClick={logoutUser}>Cerrar Sesión</button>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                </nav>

                {/* Sidebar */}
                {openSideBar && <SideBar />}

                {/* Contenido principal */}
                <main role="main" className="ml-sm-auto col-lg-12 p-3" 
                    onClick={() => {
                        setOpenSideBar(false);
                        setSuccesSale(false);
                    }}
                >
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <div className='w-100 pt-3 px-5' style={{ minHeight: "calc(100vh - 92px)" }}>
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default PrivateLayout;
