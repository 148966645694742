import React from "react";
import { DataTable } from "../ui/data-table";
import { Button } from "../ui/button";
import FolioForm from "./FolioForm";
import Modal from "../common/Modal";

const FolioList = ({ folios, deleteFolio, editFolio }) => {
  const columns = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "type",
      header: "Tipo",
    },
    {
      accessorKey: "current_folio",
      header: "Folio",
    },
    {
      accessorKey: "start_folio",
      header: "Folio inicial",
    },
    {
      accessorKey: "end_folio",
      header: "Folio final",
    },
    {
      accessorKey: "actions",
      header: "Acciones",
      cell: ({ row }) => {
        const folio = row.original;
        return (
          <>
            <Modal
              name="Editar"
              title="Editar folio"
              className={"w-100 mb-2 btn-primary"}
              content={<FolioForm folio={folio} editFolio={editFolio} />}
            />
            <Button
              onClick={() => {
                deleteFolio(folio.id);
              }}
            >
              Eliminar folio
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <h4>Lista de Folios</h4>
      <DataTable columns={columns} data={folios} />
    </div>
  );
};

export default FolioList;
