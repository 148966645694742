import React, { useContext, useState } from 'react';
import Billete1000 from '../../assets/images/1000.jpg';
import Billete2000 from '../../assets/images/2000.jpg';
import Billete5000 from '../../assets/images/5000.jpg';
import Billete10000 from '../../assets/images/10000.jpg';
import Billete20000 from '../../assets/images/20000.jpg';

import Moneda10 from '../../assets/images/10-money.png';
import Moneda50 from '../../assets/images/50-money.png';
import Moneda100 from '../../assets/images/100-money.png';
import Moneda500 from '../../assets/images/500-money.png';
import { AppContext } from '../../context/AppContext';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';

function OpenPos({ posId, setIsOpen, setCashRegister }) {
    const { domain, token, user } = useContext(AppContext);

    const [loader, setLoader] = useState(false)
    const [amounts, setAmounts] = useState({
        moneda10: 0,
        moneda50: 0,
        moneda100: 0,
        moneda500: 0,
        billete1000: 0,
        billete2000: 0,
        billete5000: 0,
        billete10000: 0,
        billete20000: 0,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(event.target.name)
        setAmounts({ ...amounts, [name]: parseInt(value) || '' });
    };

    const calculateTotal = () => {
        const total =
            amounts.moneda10 * 10 +
            amounts.moneda50 * 50 +
            amounts.moneda100 * 100 +
            amounts.moneda500 * 500 +
            amounts.billete1000 * 1000 +
            amounts.billete2000 * 2000 +
            amounts.billete5000 * 5000 +
            amounts.billete10000 * 10000 +
            amounts.billete20000 * 20000;
        return parseInt(total, 10);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const totalAmount = calculateTotal();
        await createLog(totalAmount)
    };

    async function createLog(totalAmount) {
        setLoader(true)
        const cashRegister = {
            opened_by: user.email,
            closed_by: user.email,
            open: true,
            starting_money: totalAmount,
            final_money: 0,
            start_coins_10: parseInt(amounts.moneda10, 10),
            start_coins_50: parseInt(amounts.moneda50, 10),
            start_coins_100: parseInt(amounts.billete1000, 10),
            start_coins_500: parseInt(amounts.billete1000, 10),
            start_bills_1000: parseInt(amounts.billete1000, 10),
            start_bills_2000: parseInt(amounts.billete1000, 10),
            start_bills_5000: parseInt(amounts.billete1000, 10),
            start_bills_10000: parseInt(amounts.billete1000, 10),
            start_bills_20000: parseInt(amounts.billete1000, 10),
            pos: posId,
        };
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const res = await fetch(`${urlApi}/api/cash_register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(cashRegister)
        })
        const data = await res.json()
        setLoader(false)
        setIsOpen(true)
        setCashRegister(data)
    }

    const bills = [
        {
            image: Billete1000,
            value: amounts.billete1000,
            name: '1000',
        },
        {
            image: Billete2000,
            value: amounts.billete2000,
            name: '2000',
        },
        {
            image: Billete5000,
            value: amounts.billete5000,
            name: '5000',
        },
        {
            image: Billete10000,
            value: amounts.billete10000,
            name: '10000',
        },
        {
            image: Billete20000,
            value: amounts.billete20000,
            name: '20000',
        }
    ]

    const coins = [
        {
            image: Moneda10,
            value: amounts.moneda10,
            name: '10',
        },
        {
            image: Moneda50,
            value: amounts.moneda50,
            name: '50',
        },
        {
            image: Moneda100,
            value: amounts.moneda100,
            name: '100',
        },
        {
            image: Moneda500,
            value: amounts.moneda500,
            name: '500',
        },
    ]

    return (
        <div className="lg:p-3">
            <form onSubmit={handleSubmit}>
                <div className="columns-1 md:columns-2">
                    <div className="">
                        <h3 className='font-bold text-xl'>Billetes</h3>
                        {
                            bills.map((bill) => (
                                <>
                                    <div className='flex'>
                                        <img className='object-cover h-22 w-44' src={bill.image} alt={bill.name} />

                                        <Label>
                                            Cantidad de billetes de {bill.name}
                                            <Input name={"billete" + bill.name} value={bill.value} onChange={handleInputChange} />
                                        </Label>
                                    </div>
                                    <div>
                                    </div>
                                </>
                            ))
                        }
                    </div>

                    <div className="">
                        <h3 className='font-bold text-xl'>Monedas</h3>
                        {
                            coins.map((coin) => (
                                <>
                                    <div className='flex'>
                                        <img className='object-cover h-24' src={coin.image} alt={coin.name} />
                                        <Label>
                                            Cantidad de monedas de {coin.name}
                                            <Input name={"moneda" + coin.name} value={coin.value} onChange={handleInputChange} />
                                        </Label>
                                    </div>
                                    <div>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </div>

                <div className="mt-2">
                    <h3 className='font-bold'>Total: {calculateTotal()} pesos</h3>
                    <Button className={"w-100"} type="submit">Abrir Caja</Button>
                </div>
            </form>
        </div>
    );
}

export default OpenPos;
