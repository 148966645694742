import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import React, { useContext, useEffect, useState } from "react";
import Eye from "../assets/icons/eye.svg";
import Pencil from "../assets/icons/pencil.svg";
import Trash from "../assets/icons/trash.svg";
import { ReactSVG } from "react-svg";
import Modal from "../components/common/Modal";
import ProductForm from "../components/product/ProductForm";
import { AppContext } from "../context/AppContext";
import Swal from "sweetalert2";
import { getAllProducts, deleteProduct } from "../api/products";
import { DataTable } from "../components/ui/data-table";
import { Button } from "../components/ui/button";
import { ArrowUpDown } from "lucide-react";
import Category from "../components/category/Category";
import Brand from "../components/brand/Brand";
import Attribute from "../components/attribute/Attribute";

function Products() {
  const { domain, token } = useContext(AppContext);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);

  const columns = [
    {
      accessorKey: "internal_code",
      header: "Codigo Interno",
    },
    {
      accessorKey: "sku",
      header: "Codigo de barras",
      cell: ({ row }) => {
        const product = row.original;
        return (
          <>
            {product.sku.map((sku) => (
              <>
                <span key={sku.text}>{sku.text}</span>
                <br />
              </>
            ))}
          </>
        );
      },
      filterFn: (row, columnId, filterValue) => {
        const listSku = row.original.sku;

        return listSku.some((item) => {
          const sku = item.text.trim();
          return sku.includes(filterValue.trim());
        });
      },
    },
    {
      accessorKey: "name",
      header: "Nombre",
    },
    {
      accessorKey: "gross_price",
      header: "Precio de venta",
      cell: ({ row }) => {
        const amount = parseInt(row.getValue("gross_price"));
        const formatted = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "CLP",
        }).format(amount);
        return <div>{formatted}</div>;
      },
      filterFn: (row, columnId, filterValue) => {
        return row.original.gross_price.toString().includes(filterValue);
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const product = row.original;
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className="text-dark" />}
              title={product.name}
              className={"px-2 button-no-style"}
              content={<ProductForm initialValues={product} onlyView={true} />}
            />
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className="text-dark" />}
              title={product.name}
              className={"px-2 button-no-style"}
              content={
                <ProductForm
                  initialValues={product}
                  onlyView={false}
                  edit={true}
                  updateProducts={setProducts}
                />
              }
            />

            <button
              className="px-2 button-no-style"
              onClick={() => {
                deleteProducts(product);
              }}
            >
              <ReactSVG src={Trash} className="text-dark" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        await getProducts();
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  async function getProducts() {
    const data = await getAllProducts(domain, token);
    setProducts(data);
  }

  async function deleteProducts(item) {
    if (item.id) {
      setLoader(true);
      await deleteProduct(domain, token, item.id);
      await getProducts();
      Swal.fire({
        title: "Producto Eliminado con éxito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoader(false);
    }
  }

  async function exportToExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const data = [];
    for (let index = 0; index < products.length; index++) {
      const element = products[index];
      let stringSku = "";
      for (let j = 0; j < element.sku.length; j++) {
        stringSku += element.sku[j].text + ",";
      }

      const product = {
        Id: element.id,
        Codigo_Interno: element.internal_code,
        Codigo_de_Barras: stringSku,
        Nombre: element.name,
        Marca: element.brand.name,
        Categoria: element.category.name,
        Precio_de_compra: element.buy_price,
        Precio_neto: element.net_price,
        Precio_de_venta: element.gross_price,
        IVA: element.total_vat,
        Margen_de_ganancia: element.margin_of_gain,
        Faccionable: element.fractionable ? "Si" : "No",
        Precio_fracción: element.price_fraction,
        Descripción: element.description,
        Bioequivalente: element.bioequivalent ? "Si" : "No",
        Receta: element.recipe ? "Si" : "No",
        Principio_Activo: element.active_principle,
      };
      data.push(product);
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelBlob, "data" + fileExtension);
  }

  const extra = (
    <>
      <Modal
        name="Añadir producto"
        title="Nuevo producto"
        className={"w-100"}
        content={<ProductForm updateProducts={setProducts} />}
      />
      <Modal
        name="Categorias"
        title="Categorias"
        className={"w-100"}
        content={<Category />}
      />
      <Modal
        name="Marcas"
        title="Marcas"
        className={"w-100"}
        content={<Brand />}
      />
      <Modal
        name="Atributos"
        title="Atributos"
        className={"w-100"}
        content={<Attribute />}
      />
    </>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Productos</h2>
          <DataTable
            exportToExcel={exportToExcel}
            columns={columns}
            data={products}
            isProduct={true}
            filterColumns={[
              {
                name: "Nombre",
                value: "name",
              },
              {
                name: "Codigo interno",
                value: "internal_code",
              },
              {
                name: "Codigo de barra",
                value: "sku",
              },
            ]}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Products;
