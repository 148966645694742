import { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { getAllClients, deleteClients } from "../api/clients"; // Ensure deleteClients is imported
import Swal from "sweetalert2";
import Eye from "../assets/icons/eye.svg";
import Pencil from "../assets/icons/pencil.svg";
import Trash from "../assets/icons/trash.svg";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ReactSVG } from "react-svg";
import Modal from "../components/common/Modal";
import ClientForm from "../components/clients/ClientForm";
import { DataTable } from "../components/ui/data-table";

function Clients() {
  const { domain, token } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [clients, setClients] = useState([]);

  const columns = [
    {
      accessorKey: "kind",
      header: "Tipo",
    },
    {
      accessorKey: "names",
      header: "Nombres",
      filterFn: (row, columnId, filterValue) => {
        return row.original.names.includes(filterValue);
      },
    },
    {
      accessorKey: "last_names",
      header: "Apellidos",
      filterFn: (row, columnId, filterValue) => {
        // Corrected typo here
        return row.original.last_names.includes(filterValue);
      },
    },
    {
      accessorKey: "rut",
      header: "Rut",
      filterFn: (row, columnId, filterValue) => {
        return row.original.rut.includes(filterValue);
      },
    },
    {
      accessorKey: "business_name",
      header: "Nombre de la empresa",
      filterFn: (row, columnId, filterValue) => {
        return row.original.business_name.includes(filterValue);
      },
    },
    {
      accessorKey: "district",
      header: "Comuna",
      filterFn: (row, columnId, filterValue) => {
        return row.original.district.includes(filterValue);
      },
    },
    {
      accessorKey: "city",
      header: "Ciudad",
      filterFn: (row, columnId, filterValue) => {
        return row.original.city.includes(filterValue);
      },
    },
    {
      accessorKey: "address",
      header: "Dirección",
      filterFn: (row, columnId, filterValue) => {
        return row.original.address.includes(filterValue);
      },
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "foreing",
      header: "Extranjero",
      cell: ({ row }) => {
        const foreing = row.getValue("foreing");
        let formatted = "";
        if (foreing) {
          formatted = "Sí";
        } else {
          formatted = "No";
        }

        return <div>{formatted}</div>;
      },
      filterFn: (row, columnId, filterValue) => {
        return row.original.address.includes(filterValue);
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const client = row.original;
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className="text-dark" />}
              title={client.name}
              className={"px-2 button-no-style"}
              content={<ClientForm initialValues={client} onlyView={true} />}
            />
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className="text-dark" />}
              title={client.name}
              className={"px-2 button-no-style"}
              content={
                <ClientForm
                  initialValues={client}
                  onlyView={false}
                  edit={true}
                  updateClients={setClients}
                />
              }
            />
            <button
              className="px-2 button-no-style"
              onClick={() => {
                deleteClientsHandler(client); // Updated function call
              }}
            >
              <ReactSVG src={Trash} className="text-dark" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  async function getClients() {
    const data = await getAllClients(domain, token); // Added await
    console.log("Works");
    setClients(data);
  }

  async function deleteClientsHandler(item) {
    // Renamed function to avoid conflict
    if (item.id) {
      await deleteClients(domain, token, item.id);
      await getClients();
      Swal.fire({
        title: "Cliente Eliminado con éxito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoader(false);
    }
  }

  async function exportToExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const data = [];
    for (let index = 0; index < clients.length; index++) {
      const element = clients[index];

      const client = {
        Tipo: element.kind,
        Nombres: element.names,
        Apellidos: element.last_names,
        Rut: element.rut,
        Actividad: element.activity,
        Nombre_Negocio: element.business_name,
        Comuna: element.district,
        Ciudad: element.city,
        Dirección: element.address,
        Email: element.email,
        Celular: element.phone,
        Extranjero: element.foreing,
      };
      data.push(client);
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["clientes"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelBlob, "data" + fileExtension);
  }

  const fetchData = async () => {
    try {
      setLoader(true);
      await getClients(); // Added await
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const extra = (
    <>
      <Modal
        name="Añadir cliente"
        title="Nuevo cliente"
        className={"w-100"}
        content={<ClientForm updateClients={setClients} />}
      />
    </>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            className="w-20 h-20 animate-spin" // Corrected class attribute
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Clientes</h2>
          <DataTable
            exportToExcel={exportToExcel}
            columns={columns}
            data={clients}
            isProduct={true}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Clients;
