import React from 'react'
import ProductForm from '../components/product/ProductForm'

function NewProduct() {
  return (
    <div>
        <h2>Nuevo producto</h2>
        <ProductForm/>
    </div>
  )
}

export default NewProduct