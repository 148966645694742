import React, { useContext, useEffect, useState } from 'react'
import Eye from '../assets/icons/eye.svg';
import Pencil from '../assets/icons/pencil.svg';
import Trash from '../assets/icons/trash.svg';
import Plus from '../assets/icons/plus-1.svg';
import { ReactSVG } from 'react-svg';
import SlidingPanel from '../components/common/SlidingPanel';
import Modal from '../components/common/Modal';
import Category from '../components/category/Category';
import Brand from '../components/brand/Brand';
import Attribute from '../components/attribute/Attribute';
import ProductForm from '../components/product/ProductForm';
import { AppContext } from '../context/AppContext';
import Supplier from '../components/supplier/Supplier';
import PurchaseForm from '../components/purchases/PurchaseForm';

function Purchases() {
    const { domain, token } = useContext(AppContext);
    const [purchases, setPurchases] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                await getPurchases();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [])

    async function getPurchases() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/purchases`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        setPurchases(data)
        setLoader(false)
    }

    return (
        <div>
            <div className="container my-3">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <input
                        placeholder='Buscar por Folio'
                        className="form-control mb-3 mb-md-0 me-md-3"
                    />
                    <div className="d-flex flex-column flex-md-row">
                        <div className="mb-2 mb-md-0 p-2">
                            <Modal
                                name={<span className="d-inline-flex align-items-center text-nowrap">
                                    Nueva compra <ReactSVG src={Plus} className='text-white ml-2' />
                                </span>}
                                title="Nueva compra"
                                large={true}
                                className={"w-100 mx-md-2 btn"}
                                content={<PurchaseForm />}
                            />
                        </div>
                        <div className="mb-2 mb-md-0 p-2">
                            <Modal
                                name="Proveedores"
                                title="Proveedores"
                                className={"w-100 mx-md-2 btn-secondary"}
                                content={<Supplier />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <div className="d-flex justify-content-center my-2">
                        <div className="spinner-border" role="status" />
                    </div> :
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchases.map((purchase) => (
                                    <tr>
                                        <td>{purchase.folio}</td>
                                        <td>{purchase.order_date}</td>
                                        <td>{purchase.total_order}</td>
                                        <td>
                                            <Modal
                                                large={true}
                                                name={<ReactSVG src={Eye} className='text-dark' />}
                                                title={purchase.folio}
                                                className={"px-2 button-no-style"}
                                                content={<PurchaseForm purchaseDefault={purchase}/>}
                                            />
                                            <button className='px-2 button-no-style'>
                                                <ReactSVG src={Trash} className='text-dark' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default Purchases