import React, { useContext, useEffect, useState} from 'react';
import { AppContext } from '../context/AppContext';
import { getAllSales } from '../api/sales';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Button } from '../components/ui/button';
import { patchCashRegister } from '../api/cashRegister';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
export default function ClosePos() {
  const { domain, token, user } = useContext(AppContext);
  const [logId, setLogId] = useState(0);
  const [loader, setLoader] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [userDebito, setUserDebito] = useState(0);
  const [userCredito, setUserCredito] = useState(0);
  const [userEfectivo, setUserEfectivo] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    setLogId(id);

    async function fetchData(id) {
      try {
        setLoader(true);
        await getSales(id);
        setLoader(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData(id);
  }, []);

  async function getSales(id) {
    const data = await getAllSales(domain, token);
    if (data.length > 0) {
      const filteredSales = data.filter(sale => parseInt(sale.cashRegisterLog.id, 10) === parseInt(id, 10));
      setSalesData(filteredSales);
    }
  }

  const calculateSystemTotals = () => {
    let debito = 0;
    let credito = 0;
    let efectivo = 0;

    salesData.forEach(sale => {
      JSON.parse(sale.payment_method).forEach(payment => {
        if (payment.method === 'debito') {
          debito += payment.amount;
        } else if (payment.method === 'credito') {
          credito += payment.amount;
        } else if (payment.method === 'efectivo') {
          efectivo += payment.amount;
        }
      });
    });

    return { debito, credito, efectivo };
  };

  const systemTotals = calculateSystemTotals();
  const systemTotal = systemTotals.debito + systemTotals.credito + systemTotals.efectivo;
  const userTotal = userDebito + userCredito + userEfectivo;
  const difference = {
    debito: userDebito - systemTotals.debito,
    credito: userCredito - systemTotals.credito,
    efectivo: userEfectivo - systemTotals.efectivo,
    total: userTotal - systemTotal
  };

  async function updatePOS(event){
    event.preventDefault()
    setLoader(true)
    const now = new Date();
    const utcDate = new Date(Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    ));
    const cashRegister = {
      closed_at: utcDate.toISOString(),
      closed_by: user.email,
      open: false,
    };
    await patchCashRegister(domain,token,cashRegister,logId)
    setLoader(false)
    history.push('/pos/list');
  }

  return (
    <div className="container mt-4">
      {loader ? (
        <div className="d-flex justify-content-center my-2">
          <div className="spinner-border" role="status" />
        </div>
      ) : (
        <>
          <form onSubmit={updatePOS}>
            <h3>Cierre de caja</h3>
            {salesData.length > 0 && (
              <><p className="mb-2"><strong>Hora de apertura:</strong> {new Date(salesData[0].cashRegisterLog.opened_at).toLocaleString()}</p><p className="mb-2"><strong>Usuario:</strong> {salesData[0].cashRegisterLog.opened_by}</p><p className="mb-4"><strong>Estado:</strong> {salesData[0].cashRegisterLog.open ? 'Abierto' : 'Cerrado'}</p><hr className="my-4" /><h5 className="text-lg font-semibold mb-4">Según sistema</h5><p className="mb-2"><strong>Caja inicial:</strong> {salesData[0].cashRegisterLog.starting_money}</p><p className="mb-2"><strong>Debito:</strong> {systemTotals.debito}</p><p className="mb-2"><strong>Credito:</strong> {systemTotals.credito}</p><p className="mb-4"><strong>Efectivo:</strong> {systemTotals.efectivo}</p><p className="mb-4"><strong>Total:</strong> {systemTotal}</p><hr className="my-4" /><h5 className="text-lg font-semibold mb-4">Según usuario</h5><div className="mb-4">
                <Label className="block text-sm font-medium text-gray-700">Debito</Label>
                <Input
                  type="number"
                  className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={userDebito}
                  onChange={(e) => setUserDebito(parseFloat(e.target.value))} />
              </div><div className="mb-4">
                  <Label className="block text-sm font-medium text-gray-700">Credito</Label>
                  <Input
                    type="number"
                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={userCredito}
                    onChange={(e) => setUserCredito(parseFloat(e.target.value))} />
                </div><div className="mb-4">
                  <Label className="block text-sm font-medium text-gray-700">Efectivo</Label>
                  <Input
                    type="number"
                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={userEfectivo}
                    onChange={(e) => setUserEfectivo(parseFloat(e.target.value))} />
                </div><p className="mb-4"><strong>Total:</strong> {userTotal}</p><hr className="my-4" /><h5 className="text-lg font-semibold mb-4">Diferencia</h5><p className="mb-2"><strong>Debito:</strong> {difference.debito}</p><p className="mb-2"><strong>Credito:</strong> {difference.credito}</p><p className="mb-4"><strong>Efectivo:</strong> {difference.efectivo}</p><p className="mb-4"><strong>Total:</strong> {difference.total}</p></>
            )}
            <Button type="submit" className="w-full">Cierre de caja</Button>
          </form>
        </>
      )}
    </div>
  );
}