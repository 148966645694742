import React, { useContext, useEffect, useState } from 'react'
import AttributeForm from './AttributeForm';
import AttributeList from './AttributeList';
import { AppContext } from '../../context/AppContext';
import { getAllAttributes, deleteAttribute as _deleteAttribute, putAttribute, postAttribute } from '../../api/attributes';

function Attribute() {
    const [attributes, setAttributes] = useState([]);
    const [loader, setLoader] = useState(false);
    const { domain, token } = useContext(AppContext);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                const data = await getAllAttributes(domain, token)
                setAttributes(data)
                setLoader(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [])

    const deleteAttribute = async (id) => {
        setLoader(true)
        await _deleteAttribute(domain, token, id)
        const data = await getAllAttributes(domain, token)
        setAttributes(data)
        setLoader(false)
    };

    async function editAttribute(id, newName) {
        setLoader(true)
        console.log(newName)
        console.log(id)
        await putAttribute(domain, token, {
            name: newName
        }, id)
        const data = await getAllAttributes(domain, token)
        setAttributes(data)
        setLoader(false)
    };

    async function addAttribute(name) {
        setLoader(true)
        await postAttribute(domain, token, { name: name })
        const data = await getAllAttributes(domain, token)
        setAttributes(data)
        setLoader(false)
    }

    return (
        <div>
            <AttributeForm addAttribute={addAttribute} />
            <AttributeList attributes={attributes} deleteAttribute={deleteAttribute} editAttribute={editAttribute} />
        </div>
    );
}

export default Attribute