import React, { useState } from 'react';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';

const CategoryForm = ({ addCategory,category,editCategory }) => {
  const [name, setName] = useState(category ? category.name : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      if (editCategory && category) {
        editCategory(category.id,name)
      }
      else{
        addCategory(name);
        setName('');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlForf="name">
        Nombre de categoria
        <Input
          type="text"
          id="name"
          className="form-control"
          placeholder="Ingresa aqui el nombre de categoria"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Label>
      <Button type="submit" className='mt-3 btn'>
        {category ? 'Actualizar categoria': 'Añadir categoria'}
      </Button>
    </form>
  );
};

export default CategoryForm;
