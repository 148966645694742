import React, { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';

const AttributeForm = ({ addAttribute, attribute, editAttribute }) => {
  const [name, setName] = useState(attribute ? attribute.name : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      if (editAttribute && attribute) {
        editAttribute(attribute.id, name)
      }
      else {
        addAttribute(name);
        setName('');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlForf="name">
        Nombre de atributo
        <Input
          type="text"
          id="name"
          className="form-control"
          placeholder="Ingresa aqui el nombre del atributo"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Label>
      <Button type="submit" className='mt-3 btn'>
        {attribute ? 'Actualizar atributo' : 'Añadir atributo'}
      </Button>
    </form>
  );
};

export default AttributeForm;
