import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import RefundList from "../components/refund/RefundList";
import {
  getAllRefunds,
  postRefund,
  patchRefund,
  deleteRefund,
} from "../api/refund";

function Refund() {
  const [refunds, setRefund] = useState([]);
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const refunds = await getAllRefunds(domain, token);
        setRefund(refunds);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
    fetchData();
  }, [domain, token]);

  const addRefund = async (type, url, startFolio, endFolio) => {
    setLoader(true);
    await postRefund(domain, token, {
      type: type,
      url: url,
      start_folio: Number(startFolio),
      end_folio: Number(endFolio),
      current_folio: Number(startFolio),
    });
    const refund = await getAllRefunds(domain, token);
    setRefund(refund);
    setLoader(false);
  };

  const deleteRefund = async (id) => {
    setLoader(true);
    await deleteRefund(domain, token, id);
    const refund = await getAllRefunds(domain, token);
    setRefund(refund);
    setLoader(false);
  };

  const editRefund = async (id) => {

    try {

    } catch {

    } finally {

    }
  };
  return (
    <div>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <RefundList
          folios={refunds}
          deleteFolio={deleteRefund}
          editFolio={editRefund}
        />
      )}
    </div>
  );
}

export default Refund;
