import { _delete, get, patch, post, put } from "./request";

export async function getAllBranches(domain,token,){
    const data = await get(domain,token,'/api/branch')
    return data
}

export async function getOneBranch(domain,token,id){
    const data = await get(domain,token,`/api/branch/${id}`)
    return data
}

export async function postBranch(domain,token,body){
    const data = await post(domain,token,'/api/branch',body)
    return data
}

export async function putBranch(domain,token,body,id){
    const data = await put(domain,token,`/api/branch/${id}`,body)
    return data
}

export async function patchBranch(domain,token,body,id){
    const data = await patch(domain,token,`/api/branch/${id}`,body)
    return data
}

export async function deleteBranch(domain,token,id){
    const data = await _delete(domain,token,`/api/branch/${id}`)
    return data
}