import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import swal from "sweetalert2";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { patchSale, postSale } from "../../../api/sales";
import { postDetail } from "../../../api/saleDetails";
import { getAllFolios, patchFolio } from "../../../api/folios";
import ClientForm from "../../clients/ClientForm";

function Summary({
  cashRegister,
  warehouses,
  clients,
  setCartItems,
  cartItems,
  handleWarehouse,
  selectedWarehouse,
  updateStock,
  getStock,
  handleClient,
  selectedClient,
  clietForm,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  let subtotal = 0;

  cartItems.forEach((item) => {
    // Cambia item.net_price por item.gross_price
    const priceAfterDiscount = item.quantity * item.gross_price * (1 - (item.discount / 100 || 0));
    subtotal = Math.round(subtotal + priceAfterDiscount);
  });
  
  const taxRate = 0.19; // Tasa de impuesto (19%)
  let tax = Math.round(subtotal * taxRate);
  subtotal = subtotal - tax
  let total = Math.round(subtotal + tax);

  const [selectedDocument, setSelectedDocument] = useState(1);
  const [loadingSale, setLoadingSale] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0);
  const [successFolio, setSucessFolio] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState(1);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [valueDiscount, setValueDiscount] = useState(0);
  const { domain, token, succesSale, setSuccesSale, user } =
    useContext(AppContext);
  
  if (selectedDiscount === "$") {
    total = Math.round(total - valueDiscount);
    tax = Math.round(total - total / (taxRate+1));
    subtotal = Math.round(total / (taxRate+1));
  }
  //falta añadir nota de débito y nota de crédito
  const documents = [
    { id: 1, name: "Detalle de venta (Manual)" },
    { id: 2, name: "Boleta Electrónica" },
    { id: 3, name: "Factura Electrónica" },
    { id: 4, name: "Cotizaciones" },
  ];

  const kindDiscount = [
    {
      id: "$",
      name: "Por Monto",
    },
  ];

  const certEnvironment = [
    {
      id: 0,
      mean: "Producción",
    },
    {
      id: 1,
      mean: "Certificación",
    },
  ];

  const paymentMethods = [
    { id: 1, name: "efectivo", placeholder: "Efectivo" },
    { id: 2, name: "debito", placeholder: "Debito" },
    { id: 3, name: "credito", placeholder: "Credito" },
    { id: 3, name: "transferencia", placeholder: "Transferencia" },
  ];

  const [paymentDetails, setPaymentDetails] = useState([
    { method: "debito", amount: total },
  ]);

  useEffect(() => {
    setPaymentDetails([{ method: "debito", amount: total }]);
    calculateTotalPaid(total);
  }, [total]);

  const handlePaymentMethodChange = (index, selectedMethod) => {
    const updatedDetails = [...paymentDetails];
    updatedDetails[index].method = selectedMethod;
    setPaymentDetails(updatedDetails);
  };

  const handleAmountChange = (index, amount) => {
    const updatedDetails = [...paymentDetails];
    updatedDetails[index].amount = amount;
    setPaymentDetails(updatedDetails);
    calculateTotalPaid();
  };

  const handleAddPaymentMethod = () => {
    setPaymentDetails([...paymentDetails, { method: "", amount: 0 }]);
  };

  const handleRemovePaymentMethod = (index) => {
    const updatedDetails = [...paymentDetails];
    updatedDetails.splice(index, 1);
    setPaymentDetails(updatedDetails);
  };

  const filteredClients = clients.filter((client) =>
    `${client.names} ${client.last_names}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const calculateTotalPaid = (total) => {
    if (total) {
      setTotalPaid(total);
    } else {
      let totalPaidAmount = 0;
      paymentDetails.forEach((pd) => {
        totalPaidAmount += pd.amount;
      });
      setTotalPaid(totalPaidAmount);
    }
  };

  const calculateChange = () => {
    const change = totalPaid - total;
    return isNaN(change) ? total * -1 : change;
  };

  const salesDetailPDF = async (data, folio, payment_method) => {
    const isProduction = process.env.NODE_ENV === "production";
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    try {
      const response = await fetch(`${urlApi}/generate-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          folio: folio,
          detail: data,
          method: payment_method[0].method,
          rut: "77.797.015-1",
          name: "SOCIEDAD FARMACÉUTICA CYMAA SPA",
          address: "DIRECCIÓN: CLARO SOLAR 620 LC 101",
          discountGlobal: valueDiscount
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;

        // Abrir una nueva ventana para imprimir el PDF
        const newWindow = window.open(url, "_blank");
        if (newWindow) {
          newWindow.onload = function () {
            newWindow.print();
          };
        } else {
          console.error(
            "No se pudo abrir una nueva ventana para imprimir el PDF."
          );
        }
      } else {
        console.error("Error al generar el PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error al solicitar el PDF:", error);
    }
  };

  const handleSale = async () => {
    console.log("SelectedClient:" + selectedClient);
    try {
      const dataSales = {
        folio: 0, //manejar folio
        net_price: subtotal,
        tax: tax,
        gross_price: total,
        payment_method: JSON.stringify(paymentDetails),
        document: selectedDocument,
        seller: user.email,
        cashRegisterLog_id: cashRegister.id,
        client_id: selectedClient,
      };
      if (selectedDiscount !== "" && valueDiscount !== 0) {
        dataSales.value_type = selectedDiscount;
        dataSales.global_discount = valueDiscount;
        if (selectedDiscount === "$") {
          dataSales.gross_price = total - valueDiscount;
        }
        dataSales.tax = Math.round(dataSales.gross_price * (1 - 1 / taxRate));
        dataSales.net_price = Math.round(dataSales.gross_price / taxRate);
      }
      setLoadingSale(true);

      // Verificar stock antes de proceder con la venta
      const stockCheckPassed = cartItems.every((item) => {
        const remainingStock = item.stock - item.quantity;
        return remainingStock >= 0;
      });

      if (!stockCheckPassed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "No hay suficiente stock para completar la venta.",
        });
        setLoadingSale(false);
        return;
      }

      const data = await postSale(domain, token, dataSales);
      const dataPdf = [];
      const folio = data.id;
      setSucessFolio(data.id);
      await patchSale(domain, token, { folio }, data.id);
      for (const element of cartItems) {
        console.log(element.discount);

        if (element.discount) {
          let item = {
            NmbItem: element.name,
            QtyItem: element.quantity,
            PrcItem: element.gross_price,
            DescuentoPct: parseInt(element.discount, 10),
          };
          if (Number(selectedDocument) === 3) {
            item.PrcItem = element.net_price;
          }

          dataPdf.push(item);
        } else {
          let item = {
            NmbItem: element.name,
            QtyItem: element.quantity,
            PrcItem: element.gross_price,
          };
          if (Number(selectedDocument) === 3) {
            item.PrcItem = element.net_price;
          }

          dataPdf.push(item);
        }

        const detail = {
          sales_id: data.id,
          sku: JSON.stringify(element.sku),
          name: element.name,
          qty: element.quantity,
          discount: parseInt(element.discount, 10),
          price_unit: element.gross_price,
          price_total: Math.round(element.gross_price * element.quantity),
          ass_stat: "PAID",
        };
        await postDetail(domain, token, detail);
      }
      const folios = await getAllFolios(domain, token);
      if (parseInt(selectedDocument, 10) === 1) {
        await salesDetailPDF(dataPdf, folio, JSON.parse(data.payment_method));
      }
      /*generar boleta servidor node
  data= contiene el detalle de la venta
  agregar según postman url: dte/factura/enviar-xml-pdf
  trabajar variables de entorno
  arreglar body y ajustar petición, se imprima el pdf*por ahora*
  */

      if (Number(selectedDocument, 10) === 2) {
        const folio = folios.find((folio) => folio.type === 39);
        if (folio.current_folio > folio.end_folio) {
          swal.fire({
            title: "Folio caducado",
            text: "Debe agregar un nuevo folio",
            icon: "error",
            toast: true,
            timer: 6000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
        let body = {
          Detalle: dataPdf,
          pfxUrl:
            "https://d1zz550fvrtfis.cloudfront.net/certificado_digital/13153999-1.pfx",
          pfxPass: "Ame23rojas",
          boletaUrl: folio.url,
          fchResol: "2024-07-10",
          folio: folio.current_folio,
          emisor: {
            RUTEmisor: "77.797.015-1",
            RznSoc: "Sociedad farmacéutica CYMAA Spa",
            GiroEmis: "Farmacia (Venta al por menos de medicamentos)",
            Acteco: 477201,
            DirOrigen: "Claro Solar 620 LC 101",
            CmnaOrigen: "Temuco",
          },
          receptor: {
            RUTRecep: "60803000-K",
            RznSocRecep: "Empresa S.A.",
            GiroRecep: "Servicios jurídicos",
            DirRecep: "Santiago",
            CmnaRecep: "Santiago",
          },
          // LogoUrl:
          //   "https://publiccnd.blob.core.windows.net/company-logos/Logo_todo_farma-removebg-preview.png",
        };
        console.log("Enviando al sii");
        if (Number(valueDiscount) !== 0) {
          body.DscRcgGlobal = {
            TpoMov: "D",
            TpoValor: selectedDiscount,
            ValorDR: Number(valueDiscount),
          };
        }
        const isProduction = process.env.NODE_ENV === "production";
        const urlApi = isProduction
          ? `https://siiapi.siriuserp.cl`
          : `http://localhost:3050`;

        await fetch(
          `${urlApi}/dte/boleta/pdf?certificacion=${selectedEnvironment}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error en la solicitud al servidor.");
            }
            patchFolio(
              domain,
              token,
              { current_folio: folio.current_folio + 1 },
              folio.id
            );
            patchSale(
              domain,
              token,
              {
                xml_file: response.headers.get("XMLData"),
                folio: folio.current_folio + 1,
                global_discount: valueDiscount,
                value_type: setSelectedDiscount,
              },
              data.id
            );

            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            // Autoimprimir el archivo PDF
            const printWindow = window.open(url);
            printWindow.addEventListener("load", () => {
              printWindow.print();
              printWindow.onafterprint = () => {
                printWindow.close();
                window.URL.revokeObjectURL(url); // Revoca el URL del blob para liberar memoria
              };
            });
          })
          .catch((error) => {
            console.error(`Error en la solicitud: ${error.message}`);
          });
      }
      //agregar factura, nota de débito y crédito
      if (Number(selectedDocument) === 3) {
        const folio = folios.find((folio) => folio.type === 33);
        console.log("Selected Client ID:", selectedClient);
        console.log("Clients Array:", clients);
        let client = clients.find((element) => {
          if (typeof element.id === "string") {
            return element.id === String(selectedClient);
          } else if (typeof element.id === "number") {
            return element.id === Number(selectedClient);
          }
          return false;
        });
        if (!client) {
          console.error("Client not found");
          return;
        }

        // console.log("Client:", client);
        if (folio.current_folio > folio.end_folio) {
          swal.fire({
            title: "Folio caducado",
            text: "Debe agregar un nuevo folio",
            icon: "error",
            toast: true,
            timer: 6000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
        let body = {
          Detalle: dataPdf,
          pfxUrl:
            "https://d1zz550fvrtfis.cloudfront.net/certificado_digital/13153999-1.pfx",
          pfxPass: "Ame23rojas",
          FacturaUrl: folio.url,
          fchResol: "2024-07-10",
          folio: folio.current_folio,
          emisor: {
            RUTEmisor: "77.797.015-1",
            RznSoc: "Sociedad farmacéutica CYMAA Spa",
            GiroEmis: "Farmacia (Venta al por menos de medicamentos)",
            Acteco: 477201,
            DirOrigen: "Claro Solar 620 LC 101",
            CmnaOrigen: "Temuco",
          },
          // receptor: {
          //   RUTRecep: "60.803.000-K",
          //   RznSocRecep: "Empresa S.A.",
          //   GiroRecep: "Servicios jurídicos",
          //   DirRecep: "Santiago",
          //   CmnaRecep: "Santiago",
          // },
          receptor: {
            RUTRecep: client.rut,
            RznSocRecep: client.business_name,
            GiroRecep: client.activity,
            DirRecep: client.address,
            CmnaRecep: client.district,
          },

          // FchEmis: new Date().toISOString().split("T")[0],
        };
        console.log("Enviando al sii");

        if (Number(valueDiscount) !== 0) {
          body.DscRcgGlobal = {
            TpoMov: "D",
            TpoValor: selectedDiscount,
            ValorDR: Number(valueDiscount),
          };
        }
        const isProduction = process.env.NODE_ENV === "production";
        const urlApi = isProduction
          ? `https://siiapi.siriuserp.cl`
          : `http://localhost:3050`;
        await fetch(
          `${urlApi}/dte/factura/pdf?certificacion=${selectedEnvironment}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error en la solicitud al servidor.");
            }
            patchFolio(
              domain,
              token,
              { current_folio: folio.current_folio + 1 },
              folio.id
            );

            console.log(response.headers);
            patchSale(
              domain,
              token,
              {
                folio: folio.current_folio + 1,
                track_id: response.headers.get("TrackId"),
                xml_file: response.headers.get("XMLData"),
              },
              data.id
            );
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            // Autoimprimir el archivo PDF
            const printWindow = window.open(url);
            printWindow.addEventListener("load", () => {
              printWindow.print();
              printWindow.onafterprint = () => {
                printWindow.close();
                window.URL.revokeObjectURL(url); // Revoca el URL del blob para liberar memoria
              };
            });
          })
          .catch((error) => {
            console.error(`Error en la solicitud: ${error.message}`);
          });
      }
      // Actualizar stock después de la venta exitosa
      cartItems.forEach(async (item) => {
        const remainingStock = item.stock - item.quantity;
        await updateStock(item.stockId, remainingStock);
      });
      getStock(selectedWarehouse.id);
      // Resto del código para emitir boleta y mostrar mensaje de éxito
      setLoadingSale(false);
      setSuccesSale(true);

      setPaymentDetails([{ method: "", amount: 0 }]);
      setCartItems([]);
      setSelectedDiscount("")
      setValueDiscount(0)
    } catch (error) {
      console.error("Error al realizar la venta:", error);
    }
  };
  return (
    <div className="mt-3">
      {loadingSale && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="relative w-full max-w-4xl mx-auto bg-white rounded-lg shadow-lg z-50">
            <div className="p-4 text-center">
              <p className="mt-2 text-lg">
                Espera un momento. Se está enviando la información al servidor
              </p>
              <div className="flex justify-center my-4">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
        </div>
      )}

      {succesSale && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center"
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="z-50 relative w-full max-w-md mx-auto bg-white rounded-lg shadow-lg"
            role="document"
          >
            <div className="p-4 text-center">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-transparent p-2"
                  aria-label="Close"
                >
                  <span className="text-4xl text-red-600 absolute top-0 right-0">
                    &times;
                  </span>
                </button>
              </div>
              <div>
                <p className="text-xl">
                  Exito al realizar la venta N°{successFolio}
                </p>
                <Button className="w-full">Salir</Button>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
        </div>
      )}

      <div>
        <h5 className="">Resumen de la Venta</h5>
        <div className="">
          <Select
            value={selectedWarehouse?.toString()}
            onValueChange={(value) => handleWarehouse(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Seleccionar Bodega" />
            </SelectTrigger>
            <SelectContent>
              {warehouses.map((warehouses) => (
                <SelectItem key={warehouses.id} value={`${warehouses.id}`}>
                  {warehouses.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="hidden">
          <Select
            value={selectedClient?.toString()}
            onValueChange={(value) => handleClient(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Seleccionar Cliente" />
            </SelectTrigger>
            <SelectContent>
              {" "}
              <div className="p-2">
                <input
                  type="text"
                  placeholder="Buscar Cliente"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              {filteredClients.map((element) => (
                <SelectItem key={element.id} value={`${element.id}`}>
                  {element.names + element.last_names}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mb-3">
          <Select
            value={selectedDocument?.toString()}
            onValueChange={(value) => setSelectedDocument(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Seleccionar Documento" />
            </SelectTrigger>
            <SelectContent>
              {documents.map((document) => (
                <SelectItem key={document.id} value={`${document.id}`}>
                  {document.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mb-3 hidden">
          <Select
            value={selectedEnvironment?.toString()}
            onValueChange={(value) => setSelectedEnvironment(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Seleccionar Ambiente" />
            </SelectTrigger>
            <SelectContent>
              {certEnvironment.map((kind) => (
                <SelectItem key={kind.id} value={`${kind.id}`}>
                  {kind.mean}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="mb-3">
          <Select
            value={selectedDiscount?.toString()}
            onValueChange={(value) => setSelectedDiscount(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Tipo de Descuento" />
            </SelectTrigger>
            <SelectContent>
              {kindDiscount.map((discount) => (
                <SelectItem key={discount.id} value={`${discount.id}`}>
                  {discount.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {
          selectedDiscount &&
          <div className="mb-3">
            <Label htmlFor="value_discount">
              Descuento
              <Input
                type="Number"
                className="w-20"
                id="value_discount"
                name="name"
                value={valueDiscount}
                required
                onChange={(e) => setValueDiscount(e.target.value)}
              />
            </Label>
          </div>
        }
        <div className="mt-4">
          <p class="text-lg font-semibold text-gray-700">
            Subtotal:{" "}
            <span class="text-gray-900">
              {Number.isNaN(subtotal) ? 0 : subtotal}
            </span>
          </p>
          <p class="text-lg font-semibold text-gray-700">
            IVA:{" "}
            <span class="text-gray-900">{Number.isNaN(tax) ? 0 : tax}</span>
          </p>
          <p class="text-lg font-semibold text-gray-700">
            Descuento:{" "}
            <span class="text-gray-900">
              {Number.isNaN(valueDiscount) ? 0 : valueDiscount}{" "}
              {selectedDiscount}
            </span>
          </p>
          <p class="text-lg font-semibold text-gray-700">
            Total:{" "}
            <span class="text-gray-900">{Number.isNaN(total) ? 0 : total}</span>
          </p>
          <p class="text-lg font-semibold text-gray-700">
            Vuelto: <span class="text-gray-900">{calculateChange()}</span>
          </p>
          <div className="mt-4">
            <h5>Método(s) de Pago</h5>
            {paymentDetails.map((pd, index) => (
              <div key={index} className="flex">
                <div className="">
                  <Select
                    value={pd.method}
                    onValueChange={(value) =>
                      handlePaymentMethodChange(index, value)
                    }
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Seleccionar Método de pago" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem key={"efectivo"} value={`efectivo`}>
                        Efectivo
                      </SelectItem>
                      <SelectItem key={"debito"} value={`debito`}>
                        Debito
                      </SelectItem>
                      <SelectItem key={"credito"} value={`credito`}>
                        Credito
                      </SelectItem>
                      <SelectItem key={"transferencia"} value={`transferencia`}>
                        Transferencia
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="">
                  <Input
                    type="number"
                    className="form-control"
                    value={pd.amount}
                    onChange={(e) =>
                      handleAmountChange(index, parseInt(e.target.value))
                    }
                    min="0"
                  />
                </div>

                {index > 0 && (
                  <div className="">
                    <Button
                      variant="destructive"
                      onClick={() => handleRemovePaymentMethod(index)}
                    >
                      Eliminar
                    </Button>
                  </div>
                )}
              </div>
            ))}
            <div className="w-full mt-6">
              <Button
                className="w-full"
                variant="outline"
                onClick={handleAddPaymentMethod}
              >
                Añadir Método de Pago
              </Button>
            </div>
          </div>
          <Button className="w-full" onClick={handleSale}>
            Realizar venta
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Summary;
