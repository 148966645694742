import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { getAllProducts, getOneProduct } from "../../api/products";
import { Formik, Form, Field } from "formik";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import * as Yup from "yup";
import { Button } from "../ui/button";
import Swal from "sweetalert2";
import {
  getAllDiscount,
  patchDiscount,
  postDiscount,
} from "../../api/discount";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

import { DatePickerWithRange } from "../ui/date-picker-range";

const validationSchema = Yup.object().shape({
  discount_name: Yup.string().required("Descuento es requerido"),
  kind: Yup.string().required("Tipo de descuento es requerido"),
  discount_value: Yup.number().required("Nombre de la promoción es requerida"),
});
const DISCOUNT_TYPES = ["%", "$"];

const DiscountForm = ({
  initialValues,
  onlyView,
  edit,
  updateDiscount,
  handleClose,
}) => {
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);
  const [discount, setDiscount] = useState({});
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    if (initialValues) {
      setDiscount({
        discount_name: initialValues.discount_name,
        start_date: initialValues.start_date,
        end_date: initialValues.end_date,
        kind: initialValues.kind,
        discount_value: initialValues.discount_value,
        product_id: initialValues.product,
        id: initialValues.id,
      });
    }

    async function fetchProduct() {
      try {
        setLoader(true);
        if (discount.product_id) {
          const productResponse = await getOneProduct(
            domain,
            token,
            discount.product_id
          );
          setDiscount(productResponse);
          setSelectedProduct(productResponse);
          console.log(discount);
          console.log(productResponse);
        }
        const productsResponse = await getAllProducts(domain, token);
        setProducts(productsResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }

    fetchProduct();
  }, [domain, initialValues, token]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoader(true);
      console.log(values);
      const res = await postDiscount(domain, token, { ...values });
      if (res.id) {
        const discount = await getAllDiscount(domain, token);
        updateDiscount(discount);
        Swal.fire({
          title: "Descuento Ingresado con éxito",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error al ingresar Descuento",
        text: `Detalle: ${error}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
      setLoader(false);
      handleClose();
    }
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    try {
      setLoader(true);
      await patchDiscount(domain, token, { ...values }, initialValues.id);
      const discount = await getAllDiscount(domain, token);
      updateDiscount(discount);
      Swal.fire({
        title: "Descuento Actualizado con éxito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error al actualizar Descuento",
        text: `Detalle: ${error}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
      setLoader(false);
      handleClose();
    }
  };

  return (
    <Formik
      initialValues={
        initialValues || {
          discount_name: "",
          start_date: new Date(),
          end_date: new Date(),
          kind: "",
          discount_value: 0,
          product_id: null,
        }
      }
      validationSchema={!edit ? validationSchema : null}
      onSubmit={edit ? handleUpdate : handleSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          {loader ? (
            <div className="flex justify-center w-full align-center items-center">
              <img
                className="w-20 h-20 animate-spin"
                src="https://www.svgrepo.com/show/70469/loading.svg"
                alt="Loading icon"
              />
            </div>
          ) : (
            <>
              <Field name="discount_name">
                {({ field }) => (
                  <div className="mb-3">
                    <Label htmlFor="discount_name">
                      Nombre Descuento
                      <Input {...field} type="text" disabled={onlyView} />
                      {errors.discount_name && touched.discount_name && (
                        <div className="text-red-500">
                          {errors.discount_name}
                        </div>
                      )}
                    </Label>
                  </div>
                )}
              </Field>
              <div className="mb-3">
                <Label htmlFor="typeSelect" className="font-semibold">
                  Tipo de descuento:
                </Label>
                <Select
                  id="typeSelect"
                  name="type"
                  value={values.kind}
                  onValueChange={(value) => setFieldValue("kind", value)}
                  disabled={onlyView}
                >
                  <SelectTrigger className="w-[280px]">
                    <SelectValue placeholder="Selecciona el tipo de descuento" />
                  </SelectTrigger>
                  <SelectContent>
                    {DISCOUNT_TYPES.map((type) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.kind && touched.kind && (
                  <div className="text-red-500 text-sm mt-1">{errors.kind}</div>
                )}
              </div>
              <div className="mb-3">
                <Label htmlFor="datePickerRange" className="font-semibold">
                  Rango de fechas:
                </Label>
                <DatePickerWithRange
                  date={{ from: values.start_date, to: values.end_date }}
                  onChange={(range) => {
                    setFieldValue("start_date", range.from);
                    setFieldValue("end_date", range.to);
                  }}
                  disabled={onlyView}
                />
                {errors.start_date && touched.start_date && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.start_date}
                  </div>
                )}
                {errors.end_date && touched.end_date && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.end_date}
                  </div>
                )}
              </div>
              <Field name="discount_value">
                {({ field }) => (
                  <div className="mb-3">
                    <Label htmlFor="discount_name">
                      Valor Descuento
                      <Input {...field} type="number" disabled={onlyView} />
                      {errors.discount_value && touched.discount_value && (
                        <div className="text-red-500">
                          {errors.discount_value}
                        </div>
                      )}
                    </Label>
                  </div>
                )}
              </Field>

              {!onlyView && !edit && (
                <div className="mb-3">
                  <Label htmlFor="selectedProduct" className="font-semibold">
                    Seleccionar Producto
                  </Label>
                  <Select
                    id="selectedProduct"
                    name="product_id"
                    value={values.product_id}
                    onValueChange={(key) => {
                      setFieldValue("product_id", key);
                    }}
                  >
                    <SelectTrigger className="w-[280px]">
                      <SelectValue>{values.product_id ? values.product_id : "Seleccionar producto"}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {products.map((product) => (
                        <SelectItem key={product.name} value={product.id}>
                          {product.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors.product_id && touched.product_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.product_id}
                    </div>
                  )}
                </div>
              )}

              {onlyView && (
                <div className="mb-3">
                  <Label htmlFor="product">
                    Producto
                    <Input type="text" disabled value={values.product.name} />
                  </Label>
                </div>
              )}

              {!onlyView && !edit && (
                <div className="flex flex-col">
                  <Button type="submit" disabled={isSubmitting}>
                    Añadir Descuento
                  </Button>
                </div>
              )}
              {edit && (
                <div className="flex flex-col">
                  <Button type="submit" disabled={isSubmitting}>
                    Actualizar Descuento
                  </Button>
                </div>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DiscountForm;
