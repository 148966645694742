import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext';
import OpenPos from '../components/pos/OpenPos';
import PosUI from '../components/pos/PosUI';
import moment from 'moment-timezone';

export default function Pos() {
  const { domain, token } = useContext(AppContext);
  const [logs, setLogs] = useState([])
  const [loader, setLoader] = useState(true)
  const [posId, setPosId] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [cashRegister, setCashRegister] = useState({})

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')
    setPosId(id)
    async function fetchData() {
      try {
        await getLogs(id)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [])

  async function getLogs(id) {
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    const response = await fetch(`${urlApi}/api/cash_register`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
    const data = await response.json()

    const filteredData = data.filter(item => parseInt(item.pos, 10) === parseInt(id, 10));

    const todayChile = moment().tz("America/Santiago").format('YYYY-MM-DD');
    
    const isOpenToday = filteredData.some(item => {
      const openedAtChile = moment(item.opened_at).tz("America/Santiago").format('YYYY-MM-DD');
      if (item.open === true && item.opened_at && openedAtChile === todayChile) {
        setCashRegister(item);
        return true;
      }
      return false;
    });
   
    setIsOpen(isOpenToday)
    setLogs(data)
    setLoader(false)
  }

  return (
    <div>
      <>
        {
          loader ? <div className='flex justify-center w-full align-center items-center'>
          <img class="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/70469/loading.svg" alt="Loading icon"></img>
        </div>  :
            <>
              {
                isOpen ? <PosUI cashRegister={cashRegister}/> : <OpenPos 
                posId={posId} 
                setIsOpen={setIsOpen}
                setCashRegister={setCashRegister}
                 />
              }
            </>
        }
      </>
    </div>
  )
}
