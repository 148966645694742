import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Pencil from "../assets/icons/pencil.svg";
import Trash from "../assets/icons/trash.svg";
import Plus from "../assets/icons/plus-1.svg";
import Modal from "../components/common/Modal";
import StockForm from "../components/stock/StockForm";
import { AppContext } from "../context/AppContext";
import { getStock, postStock, putStock, deleteStock } from "../api/stock";
import { DataTable } from "../components/ui/data-table";
import { Button } from "../components/ui/button";
import { ArrowUpDown } from "lucide-react";
import Eye from "../assets/icons/eye.svg";
import { getAllProducts } from "../api/products";

function Stock() {
  const { domain, token } = useContext(AppContext);
  const [stock, setStock] = useState([]);
  const [products, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const data = await getStock(domain, token, 1);
        if (data) {
          setStock(data);
        }
        const products = await getAllProducts(domain, token);
        setProduct(products);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
    fetchData();
  }, []);

  async function addStock(productId, warehouseId, quantity) {
    await postStock(domain, token, {
      warehouse_id: warehouseId,
      product_id: productId,
      stock: quantity,
    });
    const data = await getStock(domain, token, 1);
    setStock(data);
  }

  async function updateStock(id, warehouseId, productId, quantity) {
    setLoader(true);
    await putStock(
      domain,
      token,
      {
        warehouse_id: warehouseId,
        stock: parseInt(quantity),
        product_id: productId,
      },
      id
    );
    const data = await getStock(domain, token, 1);
    setStock(data);
    setLoader(false);
  }

  async function deleteStockById(id) {
    setLoader(true);
    await deleteStock(domain, token, id);
    const data = await getStock(domain, token, 1);
    if (data) {
      setStock(data);
    }
    setLoader(false);
  }

  const columns = [
    {
      accessorKey: "internal_code",
      header: "Codigo Interno",
    },
    {
      accessorKey: "sku",
      header: "Codigo de barras",
      cell: ({ row }) => {
        const product = row.original;
        const stock = JSON.parse(product.sku);
        return (
          <>
            {stock.map((sku) => (
              <>
                <span key={sku.text}>{sku.text}</span>
                <br />
              </>
            ))}
          </>
        );
      },
      filterFn: (row, columnId, filterValue) => {
        const listSku = JSON.parse(row.original.sku);

        return listSku.some((item) => {
          const sku = item.text.trim();
          return sku.includes(filterValue.trim());
        });
      },
    },
    {
      accessorKey: "name",
      header: "Nombre",
    },
    {
      accessorKey: "stock",
      header: "Stock",
      cell: ({ row }) => {
        const product = row.original;
        return (
          <>
            <p>{product.stock}</p>
          </>
        );
      },
    },
    {
      accessorKey: "buy_price",
      header: "Precio de compra",
      cell: ({ row }) => {
        const product = row.original;
        return (
          <>
            <p>{product.buy_price}</p>
          </>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const product = row.original;
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className="text-dark" />}
              title={product.name}
              className={"px-2"}
              content={
                <StockForm
                  initialValues={product}
                  onlyView={false}
                  edit={true}
                  updateProducts={setStock}
                  updateStock={updateStock}
                  listStock={stock}
                  listProducts={products}
                />
              }
            />

            <Button
              variant="ghost"
              className="px-2"
              onClick={() => {
                deleteStockById(product.stockId);
              }}
            >
              <ReactSVG src={Trash} className="text-dark" />
            </Button>
          </>
        );
      },
    },
  ];

  async function exportToExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const data = [];
    if (stock.length > 0) {
      for (let index = 0; index < stock.length; index++) {
        const element = stock[index];
        let stringSku = "";
        for (let j = 0; j < element.sku.length; j++) {
          stringSku += element.sku[j].text + ",";
        }

        const product = {
          Id: element.id,
          Codigo_Interno: element.internal_code,
          Codigo_de_Barras: stringSku,
          Nombre: element.name,
          Stock: element.stock,
          Precio_de_Compra: element.buy_price,
        };
        data.push(product);
      }
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelBlob, "data" + fileExtension);
  }

  const extra = (
    <>
      <Modal
        name={
          <>
            {" "}
            Aumentar Stock <ReactSVG src={Plus} className="text-black ml-2" />
          </>
        }
        title="Stock"
        className={"btn"}
        content={
          <StockForm
            addStock={addStock}
            updateStock={updateStock}
            listStock={stock}
            listProducts={products}
          />
        }
      />
    </>
  );
  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Stock</h2>
          <DataTable
            data={stock}
            columns={columns}
            exportToExcel={exportToExcel}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Stock;
