import React, { useContext, useEffect, useState } from 'react'
import SupplierForm from './SupplierForm';
import SupplierList from './SupplierList';
import { AppContext } from '../../context/AppContext';

function Supplier() {
    const [suppliers, setSuppliers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [supplierToEdit, setSupplierToEdit] = useState(null);
    const { domain, token } = useContext(AppContext);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                await getSuppliers();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [])


    async function getSuppliers() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/suppliers`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        setSuppliers(data)
        setLoader(false)
    }


    const addSupplier = async (supplierData) => {
        setLoader(true);
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/suppliers`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(supplierData)
        });

        await response.json();
        await getSuppliers();
    };

    const deleteSupplier = async (index) => {
        setLoader(true)
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        await fetch(`${urlApi}/api/delete_suppliers/${suppliers[index].id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        await getSuppliers()
    };

    const editSupplier = async (id, supplierData) => {
        setLoader(true);
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        await fetch(`${urlApi}/api/update_suppliers/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(supplierData)
        });

        await getSuppliers();
    };

    function handleEdit(supplierData){
        setSupplierToEdit(supplierData)
    }

    return (
        <div className="container">
            <div className="mb-3">
                <SupplierForm
                    addSupplier={addSupplier}
                    editSupplier={editSupplier}
                    supplierToEdit={supplierToEdit}
                />
            </div>
            {
                loader ? <div className="d-flex justify-content-center my-2">
                    <div className="spinner-border" role="status" />
                </div> :
                    <SupplierList suppliers={suppliers} deleteSupplier={deleteSupplier} editSupplier={handleEdit} />
            }
        </div>
    );
}

export default Supplier