import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Combobox } from '../ui/combobox';
import { getAllWarehouses } from '../../api/warehouses';
import { getStock } from '../../api/stock';

const StockForm = ({ initialValues, edit, addStock, updateStock, listStock, listProducts, handleClose }) => {
  const [quantity, setQuantity] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(0)
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);

  const [product, setProduct] = useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const warehouses = await getAllWarehouses(domain, token);
        setWarehouses(warehouses);
        if (initialValues) {
          setProduct(initialValues);
          setSelectedWarehouse(initialValues.warehouseId.toString());
          setQuantity(initialValues.stock)
        } else {
          setSelectedWarehouse(warehouses[0].id.toString())
        }
        setLoader(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoader(false);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (edit) {
      await updateStock(product.stockId, selectedWarehouse, product.id, quantity);
    }
    else {
      let update = false
      let updateId = 0
      let total = 0
      for (let index = 0; index < listStock.length; index++) {
        if (
          parseInt(listStock[index].id) === parseInt(product.id) &&
          parseInt(listStock[index].warehouseId) === parseInt(selectedWarehouse)
        ) {
          update = true;
          updateId = listStock[index].stockId;
          total = parseInt(listStock[index].stock, 10) + parseInt(quantity, 10);
        }
      }
      if (update) {
        await updateStock(updateId, selectedWarehouse, product.id, total);
      } else {
        await addStock(product.id, selectedWarehouse, quantity);
      }
      setLoader(false);
    }
    handleClose();
  };

  function selectedProduct(product) {
    setProduct(product)
  }

  return (
    <form onSubmit={handleSubmit} className="mb-3">
      {loader ? (
        <div className="d-flex justify-content-center my-2">
          <div className="spinner-border" role="status" />
        </div>
      ) : (
        <div className='flex flex-col'>
          <Label>
            Cantidad
            <Input
              type="number"
              className="form-control mb-3"
              placeholder="Ingrese aqui la cantidad a añadir"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Label>

          <Label htmlFor="warehouse_id">Bodega</Label>
          <Select
            id="warehouse_id"
            name="warehouse_id"
            value={selectedWarehouse}
            onValueChange={(value) => { setSelectedWarehouse(value) }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Selecciona Bodega" />
            </SelectTrigger>
            <SelectContent>
              {warehouses.map((warehouse) => (
                <SelectItem key={warehouse.id} value={`${warehouse.id}`}>{warehouse.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Label className="flex flex-col mt-2 mb-1">
            Producto
            <Combobox
              options={listProducts}
              placeholder={"Buscar productos"}
              onSelect={selectedProduct}
              defaultValue={initialValues}
            />
          </Label>

          <Button type="submit" className="w-100">
            Añadir Stock
          </Button>
        </div>
      )}
    </form>
  );
};

export default StockForm;
