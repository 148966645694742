import { _delete, get, patch, post, put } from "./request";

export async function getAllPacks(domain, token) {
  const data = await get(domain, token, `/api/packs`);

  return data;
}

export async function getOnePacks(domain, token, id) {
  const data = await get(domain, token, `/api/packs/${id}`);
  return data;
}

export async function postPacks(domain, token, body) {
  console.log(body);
  const data = await post(domain, token, "/api/packs", body);
  return data;
}

export async function putPacks(domain, token, body, id) {
  const data = await put(domain, token, `/api/packs/${id}`, body);
  return data;
}

export async function patchPacks(domain, token, body, id) {
  const data = await patch(domain, token, `/api/packs/${id}`, body);
  return data;
}

export async function deletePacks(domain, token, id) {
  const data = await _delete(domain, token, `/api/packs/${id}`);
  return data;
}
