import React from "react"

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { Input } from "./input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table"
import { DatePickerWithRange } from "./date-picker-range"
import { Button } from "./button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select"


export function DataTable({ columns=[], data=[], exportToExcel ,Extra }) {
  const [columnFilters, setColumnFilters] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })
  return (
    <div className="p-2">
      <div className="flex">
        {exportToExcel && 
        <Button onClick={exportToExcel}>Exportar a excel</Button>
        }
        {Extra && Extra}
      </div>
      <br/>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽"
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {
            table.getRowModel().rows.length > 0 ?
              <>
                {table.getRowModel().rows.map(row => {
                  return (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </> :
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No hay resultados.
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          <div>Paginas</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} de {" "}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Ir a la pagina:
          <input
            type="number"
            min="1"
            max={table.getPageCount()}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <Select
          value={table.getState().pagination.pageSize}
          onValueChange={value => {
            table.setPageSize(Number(value))
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Seleccionar Paginas" />
          </SelectTrigger>
          <SelectContent>
            {[10, 20, 30, 40, 50].map(pageSize => (
              <SelectItem key={pageSize} value={pageSize}>
                Ver {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}

function Filter({ column }) {
  const [date, setDate] = React.useState({})
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return (
    <>
      {
        column.columnDef.accessorKey !== 'action' &&
        <>
          {

            filterVariant === "range" ? (
              <div>
                <div className="flex space-x-2">
                  <DebouncedInput
                    type="number"
                    value={columnFilterValue?.[0] ?? ""}
                    onChange={value => column.setFilterValue(old => [value, old?.[1]])}
                    placeholder={`Min`}
                    className="w-24 border shadow rounded"
                  />
                  <DebouncedInput
                    type="number"
                    value={columnFilterValue?.[1] ?? ""}
                    onChange={value => column.setFilterValue(old => [old?.[0], value])}
                    placeholder={`Max`}
                    className="w-24 border shadow rounded"
                  />
                </div>
                <div className="h-1" />
              </div>
            ) : filterVariant === "select" ? (
              <select
                onChange={e => column.setFilterValue(e.target.value)}
                value={columnFilterValue?.toString()}
              >
                <option value="">All</option>
                <option value="complicated">complicated</option>
                <option value="relationship">relationship</option>
                <option value="single">single</option>
              </select>
            )
              :
              filterVariant === "date" ? (
                <div className="flex gap-3">
                  <DatePickerWithRange
                    date={date}
                    onChange={(e) => {
                      setDate(e)
                      column.setFilterValue(e)
                    }}

                  />
                  <Button onClick={() => {
                    setDate({})
                    column.setFilterValue({})
                  }}>
                    Limpiar filtro
                  </Button>
                </div>
              )
                : (
                  <DebouncedInput
                    className="w-36 border shadow rounded"
                    onChange={value => column.setFilterValue(value)}
                    placeholder={`Buscar...`}
                    type="text"
                    value={columnFilterValue ?? ""}
                  />
                )
          }
        </>
      }
    </>
  )
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 0,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}