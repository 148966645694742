import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import FolioForm from "./FolioForm";
import FolioList from "./FolioList";
import {
  getAllFolios,
  postFolio,
  patchFolio,
  deleteFolio as _deleteFolio,
} from "../../api/folios";
import swal from "sweetalert2";

function Folio() {
  const [folios, setFolios] = useState([]);
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const folios = await getAllFolios(domain, token);
        setFolios(folios);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const addFolio = async (type, url, startFolio, endFolio, cert) => {
    setLoader(true);
    console.log(domain);
    console.log(token);
    await postFolio(domain, token, {
      type: type,
      url: url,
      start_folio: Number(startFolio),
      end_folio: Number(endFolio),
      current_folio: Number(startFolio),
    });
    const folio = await getAllFolios(domain, token);
    setFolios(folio);
    setLoader(false);
  };

  const deleteFolio = async (id) => {
    setLoader(true);
    await _deleteFolio(domain, token, id);
    const folio = await getAllFolios(domain, token);
    setFolios(folio);
    setLoader(false);
  };

  const editFolio = async (id, startFolio, endFolio, url, type) => {
    setLoader(true);

    const folioData = {};
    if (type !== undefined) folioData.type = type;
    if (startFolio !== undefined) {
      folioData.start_folio = Number(startFolio);
      folioData.current_folio = Number(startFolio);
    }
    if (endFolio !== undefined) folioData.end_folio = Number(endFolio);
    if (url !== undefined) folioData.url = url;

    if (id) {
      console.log(folioData);
      // Editar folio existente
      await patchFolio(domain, token, folioData, id);
    } else {
      // Crear nuevo folio
      await postFolio(domain, token, folioData);
    }

    const folios = await getAllFolios(domain, token);
    setFolios(folios);
    console.log("Folios after editing:", folios);
    setLoader(false);
  };
  return (
    <div>
      <FolioForm addFolio={addFolio} />
      <FolioList
        folios={folios}
        deleteFolio={deleteFolio}
        editFolio={editFolio}
      />
    </div>
  );
}

export default Folio;
