import { _delete, get, patch, post, put } from "./request";

export async function getAllCategories(domain,token,){
    const data = await get(domain,token,'/api/category')
    return data
}

export async function getOneCategory(domain,token,id){
    const data = await get(domain,token,`/api/category/${id}`)
    return data
}

export async function postCategory(domain,token,body){
    const data = await post(domain,token,'/api/category',body)
    return data
}

export async function putCategory(domain,token,body,id){
    const data = await put(domain,token,`/api/category/${id}`,body)
    return data
}

export async function patchCategory(domain,token,body,id){
    const data = await patch(domain,token,`/api/category/${id}`,body)
    return data
}

export async function deleteCategory(domain,token,id){
    const data = await _delete(domain,token,`/api/category/${id}`)
    return data
}