import * as React from "react";
import { useState, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button } from "../ui/button";

const TagItem = ({ tag, index, handleDelete, moveTag }) => {
  const [, ref] = useDrag({
    type: "tag",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "tag",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveTag(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="flex items-center bg-gray-100 rounded px-1 mr-2"
    >
      <span>{tag.text}</span>
      <button
        onClick={() => handleDelete(index)}
        className="ml-2 text-red-500 hover:text-red-700"
      >
        &times;
      </button>
    </div>
  );
};

const TagInput = ({
  tags: initialTags,
  placeholder,
  name,
  handleDelete,
  handleAddition,
  handleDrag,
}) => {
  const [tags, setTags] = useState(initialTags);
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const addTag = (e) => {
    if (e) {
      e.preventDefault()
    }
    if (input.trim() !== "") {
      const newTag = { id: input, text: input, className: "" };
      handleAddition(newTag);
      setTags([...tags, newTag]);
      setInput("");
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    }
  };

  const deleteTag = (index) => {
    handleDelete(index);
    setTags(tags.filter((_, i) => i !== index));
  };

  const moveTag = (fromIndex, toIndex) => {
    const updatedTags = [...tags];
    const [movedTag] = updatedTags.splice(fromIndex, 1);
    updatedTags.splice(toIndex, 0, movedTag);
    setTags(updatedTags);
    handleDrag(fromIndex, toIndex);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-wrap border border-gray-300 rounded p-1">
        {tags.map((tag, index) => (
          <TagItem
            key={index}
            index={index}
            tag={tag}
            handleDelete={deleteTag}
            moveTag={moveTag}
          />
        ))}
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className="flex-grow border-none focus:ring-0"
        />
        <Button onClick={addTag}>
          Añadir
        </Button>
      </div>
    </DndProvider>
  );
};

export default TagInput;