import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { Input } from '../components/ui/input'
import { Label } from '../components/ui/label'

function Login() {

  const { loginUser } = useContext(AuthContext)
  const handleSubmit = e => {
    e.preventDefault()
    const email = e.target.email.value
    const password = e.target.password.value
    email.length > 0 && loginUser(email, password)
  }

  return (
    <div>
      <section className="h-screen">
        <div className="container mx-auto py-5 h-full">
          <div className="flex justify-center items-center h-full">
            <div className="w-full max-w-3xl">
              <div className="rounded-lg overflow-hidden">
                <div className="flex justify-center items-center h-full">
                  <div className="w-full md:w-1/2 flex items-center justify-center">
                    <div className="p-8 md:p-12">
                      <form onSubmit={handleSubmit}>
                        <div className="flex items-center mb-6 pb-1">
                          <i className="fas fa-cubes text-2xl text-orange-600 mr-3" />
                          <span className="text-2xl font-bold">Bienvenido de nuevo</span>
                        </div>
                        <h5 className="text-lg font-normal mb-6 pb-3 tracking-wider">
                          Iniciar sesión en su cuenta
                        </h5>
                        <div className="mb-4">
                          <Label htmlFor="email">Email</Label>
                          <Input id="email" />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="password">Contraseña</Label>
                          <Input id="password" />
                        </div>
                        <div className="pt-1 mb-4">
                          <button
                            className="w-full bg-gray-800 text-white text-lg py-2 rounded"
                            type="submit"
                          >
                            Iniciar sesión
                          </button>
                        </div>
                        <a className="text-sm text-gray-500" href="#!">
                          ¿Has olvidado tu contraseña?
                        </a>
                        <p className="text-sm my-2 text-gray-700">
                          ¿No tienes una cuenta?
                        <Link to="/register" className="ml-2 text-indigo-600">
                          Regístrate ahora
                        </Link>
                        </p>
                        
                        <a href="#" className="text-sm mr-2 text-gray-600">
                          Condiciones de uso
                        </a>
                        <a href="#" className="text-sm text-gray-600">
                          Política de privacidad
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login