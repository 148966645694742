import React from 'react';

const CompanyData = () => {
  // Datos de la empresa (ejemplo)
  const empresa = {
    razonSocial: 'Mi Empresa S.A.',
    rut: '12.345.678-9',
    giro: 'Venta de Productos',
    direccion: 'Av. Principal 1234, Santiago',
    email: 'contacto@miempresa.com',
    telefono: '+56 2 1234 5678'
  };

  return (
    <div>
      <h4>Datos de la Empresa</h4>
      <ul className="list-group">
        <li className="list-group-item"><strong>Razón Social:</strong> {empresa.razonSocial}</li>
        <li className="list-group-item"><strong>RUT:</strong> {empresa.rut}</li>
        <li className="list-group-item"><strong>Giro:</strong> {empresa.giro}</li>
        <li className="list-group-item"><strong>Dirección:</strong> {empresa.direccion}</li>
        <li className="list-group-item"><strong>Email:</strong> {empresa.email}</li>
        <li className="list-group-item"><strong>Teléfono:</strong> {empresa.telefono}</li>
      </ul>
    </div>
  );
};

export default CompanyData;
