import { _delete, get, patch, post, put } from "./request";

export async function getAllProducts(domain,token,){
    const data = await get(domain,token,'/api/product')
    for (let index = 0; index < data.length; index++) {
        data[index].sku = JSON.parse(data[index].sku);
        data[index].tags = JSON.parse(data[index].tags);
    }
    return data
}

export async function getOneProduct(domain,token,id){
    const data = await get(domain,token,`/api/product/${id}`)
    return data
}

export async function postProduct(domain,token,body){
    const data = await post(domain,token,'/api/product',body)
    return data
}

export async function putProduct(domain,token,body,id){
    const data = await put(domain,token,`/api/product/${id}`,body)
    return data
}

export async function patchProduct(domain,token,body,id){
    const data = await patch(domain,token,`/api/product/${id}`,body)
    return data
}

export async function deleteProduct(domain,token,id){
    const data = await _delete(domain,token,`/api/product/${id}`)
    return data
}