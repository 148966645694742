import { _delete, get, patch, post, put } from "./request";

export async function getAllRefundsDetail(domain, token) {
  const data = await get(domain, token, "/api/refund_detail");
  return data;
}

export async function getOneRefundDetail(domain, token, id) {
  const data = await get(domain, token, `/api/refund_detail/${id}`);
  return data;
}

export async function postRefundDetail(domain, token, body) {
  const data = await post(domain, token, "/api/refund_detail", body);
  return data;
}

export async function putRefundDetail(domain, token, body, id) {
  const data = await put(domain, token, `/api/refund_detail/${id}`, body);
  return data;
}

export async function patchRefundDetail(domain, token, body, id) {
  const data = await patch(domain, token, `/api/refund_detail/${id}`, body);
  return data;
}

export async function deleteRefundDetail(domain, token, id) {
  const data = await _delete(domain, token, `/api/refund_detail/${id}`);
  return data;
}
