import React, { useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Formik } from "formik";
import { string, object, number } from "yup";
const validationSchema = object().shape({
  motive: string().required("Agregar motivo"),
  kind_of_note: string().required("Tipo de nota es requerido"),
});

const RefundEdit = ({ addRefund, refund, editRefund, onlyView, edit }) => {
  const [updateRefund, setUpdateRefund] = useState({
    motive: "",
    folio: null,
    kind_of_note: "",
    sale_id: null,
    client_id: null,
    track_id: "",
    xml_file: "",
  });
  const [loader, setLoader] = useState(false);

  const foliosType = [
    {
      type: 61,
      folio: "Nota de Crédito Electrónica",
    },
    {
      type: 39,
      folio: "Boleta Electrónica",
    },
  ];

  const handleSubmit = (e) => {};

  const handleUpdate = (e) => {};

  return (
    <Formik
      initialValues={refund}
      validationSchema={validationSchema}
      onSubmit={edit ? handleUpdate : handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {loader ? (
            <div className="flex justify-center w-full align-center items-center">
              <img
                className="w-20 h-20 animate-spin"
                src="https://www.svgrepo.com/show/70469/loading.svg"
                alt="Loading icon"
              ></img>
            </div>
          ) : (
            <div className="columns-2">
              <Label htmlFor="url">
                Motivo
                <Input
                  id="motive"
                  type="text"
                  name="motive"
                  value={values.motive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  disabled={onlyView && true}
                  placeholder="Agregar motivo"
                />
                {errors.motive && touched.motive && (
                  <div className="text-red-500">{errors.motive}</div>
                )}
              </Label>
              <Button type="submit" className="mt-3 btn">
                {refund ? "Actualizar Devolución" : "Añadir Devolución"}
              </Button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default RefundEdit;
