import React, { useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { patchFolio } from "../../api/folios";
import {
  deleteDetail,
  getAllDetails,
  getOneDetail,
  patchDetail,
} from "../../api/saleDetails";
import { getAllProducts } from "../../api/products";
import { getAllStock, patchStock } from "../../api/stock";
import { deleteSale, getOneSale } from "../../api/sales";
import { getAllRefunds, postRefund } from "../../api/refund";
import { postRefundDetail } from "../../api/refundDetails";

const RefundForm = ({
  sale,
  clients,
  details,
  folios,
  handleClose,
  token,
  domain,
}) => {
  const [type, setType] = useState();
  const [reason, setReason] = useState();
  const [editedDetails, setEditedDetails] = useState(details);

  const foliosType = [
    {
      type: 56,
      folio: "Nota de Débito Electrónica",
    },
    {
      type: 61,
      folio: "Nota de Crédito Electrónica",
    },
  ];

  const motive = [
    {
      refCod: 2,
      rsnCod: "Corrige dirección del receptor",
    },
    {
      refCod: 1,
      rsnCod: "Anula documento",
    },
    {
      refCod: 3,
      rsnCod: "Devolución mercadería",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    let mapping = {
      name: "NmbItem",
      qty: "QtyItem",
      price_unit: "PrcItem",
      discount: "DescuentoPct",
    };

    let modifiedEditedDetails = editedDetails.map((obj) => {
      let nuevoObj = {};
      // Cambiar los nombres de los atributos para cada objeto
      for (let key in obj) {
        if (["name", "qty", "price_unit", "discount"].includes(key)) {
          if (obj[key] != null) {
            let nuevoKey = mapping[key] || key; // Si hay un mapeo, lo usa; si no, mantiene el original
            nuevoObj[nuevoKey] = obj[key];
          }
        }
      }
      return nuevoObj;
    });

    let refType = Number(sale.document) === 3 ? 33 : null;
    let referenceObject = {};
    let reasonObject = motive.find((motive) => motive.rsnCod === reason);
    let referenceArray = [];
    let folio = folios.find((folio) => folio.type === refType);
    let folioCreditOrDebit = folios.find(
      (folio) => folio.type === Number(type)
    );
    let client = clients.find((client) => client.id === sale.clients);

    let referenceMainObject = {
      TpoDocRef: "SET",
      FolioRef: folioCreditOrDebit.current_folio,
      RazonRef: "Nota de Credito",
    };
    referenceArray.push(referenceMainObject);
    if (refType !== null) {
      referenceObject.TpoDocRef = refType;
      referenceObject.FolioRef = folio.current_folio;
      referenceObject.RazonRef = reasonObject.rsnCod;
      referenceObject.CodRef = reasonObject.refCod;
    }
    referenceArray.push(referenceObject);
    console.log(reason);

    let receptor = {};
    receptor.RUTRecep = client.rut.replaceAll(".", "");
    receptor.RznSocRecep = client.business_name;
    receptor.GiroRecep = client.activity;
    receptor.DirRecep = client.address;
    receptor.CmnaRecep = client.district;

    const requestData = {
      Detalle: modifiedEditedDetails,
      Referencia: referenceArray,
      Receptor: receptor,
      pfxUrl:
        "https://d1zz550fvrtfis.cloudfront.net/certificado_digital/13153999-1.pfx",
      pfxPass: "Ame23rojas",
      fchResol: "2024-07-10",
      folio: folioCreditOrDebit.current_folio,
      emisor: {
        RUTEmisor: "77797015-1",
        RznSoc: "Sociedad farmacéutica CYMAA Spa",
        GiroEmis: "Farmacia (Venta al por menos de medicamentos)",
        Acteco: 477201,
        DirOrigen: "Claro Solar 620 LC 101",
        CmnaOrigen: "Temuco",
      },
    };
    console.log(typeof type);
    console.log(typeof type);
    if (type === "61") {
      requestData.NotaCreditoUrl = folioCreditOrDebit.url;
    }
    if (type === "56") {
      requestData.NotaDebitoUrl = folioCreditOrDebit.url;
    }
    if (Number(sale.global_discount) !== 0) {
      requestData.DscRcgGlobal = {
        TpoMov: "D",
        TpoValor: sale.value_type,
        ValorDR: sale.global_discount,
      };
    }
    const isProduction = process.env.NODE_ENV === "production";
    const urlApi = isProduction
      ? `https://siiapi.siriuserp.cl`
      : `http://localhost:3050`;

    const uriApi =
      type === "61"
        ? "/dte/notadecredito/pdf?certificacion=1"
        : "/dte/notadedebito/pdf?certificacion=1";
    await fetch(urlApi + uriApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Error en la solicitud al servidor.");
        }
        await patchFolio(
          domain,
          token,
          { current_folio: folioCreditOrDebit.current_folio + 1 },
          folioCreditOrDebit.id
        );

        await postRefund(domain, token, {
          motive: "Nota de crédito",
          folio: folioCreditOrDebit.current_folio,
          kind_of_note: "61",
          client_id: client.id,
          sale_id: sale.id,
          track_id: response.headers.get("TrackId"),
          xml_file: response.headers.get("XMLData"),
        });
        let products = await getAllProducts(domain, token);
        console.log(products);
        let stocks = await getAllStock(domain, token);
        console.log("Stock:" + stocks);
        let refunds = await getAllRefunds(domain, token);
        console.log("Refunds: " + refunds);
        editedDetails.forEach(async (detail) => {
          patchDetail(domain, token, detail, detail.id);
          let saleDetail = await getOneDetail(domain, token, detail.id);
          if (saleDetail.qty === 0) {
            patchDetail(domain, token, {
              att_stat: "ANNULED",
              ...saleDetail.id,
            });
          }
          let product = products.map((product) => {
            if (product.name === detail.name) {
              return product;
            }
          });
          let stock = stocks.find((stock) => stock.name === product.id);
          await patchStock(
            domain,
            token,
            { stock: stock.stock + detail.qty },
            stock.id
          );
          let refund = refunds.map((refund) => {
            if (refund.sale_id === detail.sales_id) {
              return refund;
            }
          });
          console.log(refund);
          await postRefundDetail(domain, token, {
            sku: detail.sku,
            name: detail.name,
            qty: detail.qty,
            discount: detail.discount,
            price_unit: detail.price_unit,
            price_total: detail.price_total,
            refund_id: refund[0].id,
          });
        });
        await patchFolio(
          domain,
          token,
          {
            current_folio: folioCreditOrDebit.current_folio + 1,
          },
          folioCreditOrDebit.id
        );
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // Autoimprimir el archivo PDF
        const printWindow = window.open(url);
        printWindow.addEventListener("load", () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
            window.URL.revokeObjectURL(url); // Revoca el URL del blob para liberar memoria
          };
        });
      })
      .catch((error) => {
        console.error(`Error en la solicitud: ${error.message}`);
        console.error(error.stack);
      });
    // Aquí puedes realizar la solicitud POST con requestData

    // Cerrar el formulario si es necesario
    handleClose();
  };
  const handleDetailChange = (id, field, value) => {
    setEditedDetails((prevDetails) =>
      prevDetails.map((detail) => {
        if (detail.id === id) {
          // Actualiza el valor del campo (cantidad o precio unitario)
          const updatedDetail = { ...detail, [field]: value };

          // Calcula el precio total si se ha cambiado la cantidad o el precio unitario
          if (field === "qty" || field === "price_unit") {
            updatedDetail.price_total =
              updatedDetail.qty * updatedDetail.price_unit;
          }

          return updatedDetail;
        }
        return detail;
      })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label>
        Folio
        <Input disabled value={sale.folio} />
      </Label>
      <Label>
        Fecha
        <Input disabled value={sale.date} />
      </Label>
      <Label>
        Precio Neto
        <Input disabled value={sale.net_price} />
      </Label>
      <Label>
        Impuesto
        <Input disabled value={sale.tax} />
      </Label>
      <Label>
        Precio Bruto
        <Input disabled value={sale.gross_price} />
      </Label>
      <Label>
        Método de pago
        <Input disabled value={JSON.parse(sale.payment_method)[0].method} />
      </Label>
      <Label>
        Vendedor
        <Input disabled value={sale.seller} />
      </Label>
      <Label>
        Descuento global
        <Input disabled value={sale.global_discount + sale.value_type} />
      </Label>
      <div>
        <p>Detalle de la devolución</p>
        <Label htmlFor="type">Tipo de Folio</Label>
        <Select
          id="type"
          name="type"
          value={type}
          onValueChange={(value) => setType(value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Seleccionar Tipo" />
          </SelectTrigger>
          <SelectContent>
            {foliosType.map((folio) => (
              <SelectItem key={folio.type} value={folio.type.toString()}>
                {folio.folio}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Label htmlFor="type">Motivo</Label>
        <Select
          id="type"
          name="type"
          value={reason}
          onValueChange={(value) => setReason(value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Seleccionar Motivo" />
          </SelectTrigger>
          <SelectContent>
            {motive.map((element) => (
              <SelectItem key={element} value={element.rsnCod}>
                {element.rsnCod}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {editedDetails.map((detail) => (
          <div key={detail.id}>
            <Label htmlFor={`name-${detail.id}`}>
              Nombre
              <Input
                type="text"
                id={`name-${detail.id}`}
                value={detail.name}
                onChange={(e) =>
                  handleDetailChange(detail.id, "name", e.target.value)
                }
              />
            </Label>

            <Label htmlFor={`qty-${detail.id}`}>
              Cantidad
              <Input
                type="number"
                id={`qty-${detail.id}`}
                value={detail.qty}
                onChange={(e) =>
                  handleDetailChange(detail.id, "qty", Number(e.target.value))
                }
              />
            </Label>

            <Label htmlFor={`price_unit-${detail.id}`}>
              Precio Unitario
              <Input
                type="number"
                id={`price_unit-${detail.id}`}
                value={detail.price_unit}
                onChange={(e) =>
                  handleDetailChange(
                    detail.id,
                    "price_unit",
                    Number(e.target.value)
                  )
                }
              />
            </Label>

            <Label htmlFor={`price_total-${detail.id}`}>
              Precio Total
              <Input
                disabled
                value={detail.qty * detail.price_unit}
                onChange={(e) =>
                  handleDetailChange(
                    detail.id,
                    "price_total",
                    Number(e.target.value)
                  )
                }
              />
            </Label>
          </div>
        ))}
      </div>
      <Button type="submit" className="mt-3 btn">
        Añadir Devolución
      </Button>
    </form>
    // <form onSubmit={handleSubmit}>
    //   <Label htmlFor="type">Tipo de Documento:</Label>
    //   <Select
    //     id="type"
    //     name="type"
    //     value={type}
    //     onValueChange={(value) => setType(value)}
    //   >
    //     <SelectTrigger className="w-[180px]">
    //       <SelectValue placeholder="Seleccionar Tipo" />
    //     </SelectTrigger>
    //     <SelectContent>
    //       {foliosType.map((folio) => (
    //         <SelectItem key={folio.type} value={folio.type.toString()}>
    //           {folio.folio}
    //         </SelectItem>
    //       ))}
    //     </SelectContent>
    //   </Select>

    //   <Label htmlFor="url">Archivo:</Label>
    //   <Input
    //     id="url"
    //     type="file"
    //     onChange={(e) => setFile(e.target.files[0])}
    //     placeholder="Subir Archivo"
    //   />

    //   <Label htmlFor="startFolio">Folio Inicial:</Label>
    //   <Input
    //     id="startFolio"
    //     type="number"
    //     value={startFolio}
    //     onChange={(e) => setStartFolio(e.target.value)}
    //     placeholder="Ingrese el Folio Inicial"
    //   />

    //   <Label htmlFor="endFolio">Folio Final:</Label>
    //   <Input
    //     id="endFolio"
    //     type="number"
    //     value={endFolio}
    //     onChange={(e) => setEndFolio(e.target.value)}
    //     placeholder="Ingrese el Folio Final"
    //   />

    //   <Button type="submit" className="mt-3 btn">
    //     {refund ? "Actualizar Devolución" : "Añadir Devolución"}
    //   </Button>
    // </form>
  );
};

export default RefundForm;
