import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import React, { useContext, useEffect, useState } from "react";
import Eye from "../assets/icons/eye.svg";
import Pencil from "../assets/icons/pencil.svg";
import Trash from "../assets/icons/trash.svg";
import { ReactSVG } from "react-svg";
import Modal from "../components/common/Modal";
import ProductForm from "../components/product/ProductForm";
import { AppContext } from "../context/AppContext";
import Swal from "sweetalert2";
import { deleteDiscount, getAllDiscount } from "../api/discount";
import { DataTable } from "../components/ui/data-table";
import moment from "moment-timezone";
import { Button } from "../components/ui/button";
import { ArrowUpDown } from "lucide-react";
import Category from "../components/category/Category";
import Brand from "../components/brand/Brand";
import Attribute from "../components/attribute/Attribute";
import DiscountForm from "../components/discount/DiscountForm";

function Discount() {
  const { domain, token } = useContext(AppContext);
  const [discount, setDiscount] = useState([]);
  const [loader, setLoader] = useState(false);

  const columns = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "discount_name",
      header: "Nombre del Descuento",
      filterFn: (row, columnId, filterValue) => {
        return row.original.discount_name.toString().includes(filterValue);
      },
    },
    {
      accessorKey: "start_date",
      header: "Fecha de Inicio",
      cell: ({ row }) => {
        const discount = row.original;
        return (
          <>
            {moment
              .tz(discount.start_date, "America/Santiago")
              .format("YYYY-MM-DD HH:mm:ss")}
          </>
        );
      },
      meta: {
        filterVariant: "date",
      },
      filterFn: (row, columnId, filterValue) => {
        const timeZone = "America/Santiago";
        if (filterValue.to !== undefined) {
          const dateFrom = moment.tz(filterValue.from, timeZone);
          const dateTo = moment.tz(filterValue.to, timeZone);
          const filterDate = moment.tz(row.original.start_date, timeZone);

          return filterDate.isBetween(dateFrom, dateTo);
        } else {
          if (filterValue.from) {
            const filterDate = moment.tz(row.original.date, timeZone);
            const dateFrom = moment.tz(filterValue.from, timeZone);

            return (
              filterDate.format("YY-MM-DD") === dateFrom.format("YY-MM-DD")
            );
          } else {
            return true;
          }
        }
      },
    },
    {
      accessorKey: "end_date",
      header: "Fecha de Término",
      cell: ({ row }) => {
        const discount = row.original;
        return (
          <>
            {moment
              .tz(discount.end_date, "America/Santiago")
              .format("YYYY-MM-DD HH:mm:ss")}
          </>
        );
      },
      meta: {
        filterVariant: "date",
      },
      filterFn: (row, columnId, filterValue) => {
        const timeZone = "America/Santiago";
        const filterDate = moment.tz(row.original.end_date, timeZone);

        // Si hay un rango de fechas (from y to)
        if (filterValue.from && filterValue.to) {
          const dateFrom = moment.tz(filterValue.from, timeZone).startOf("day");
          const dateTo = moment.tz(filterValue.to, timeZone).endOf("day");
          return filterDate.isBetween(dateFrom, dateTo, null, "[]"); // '[ ]' incluye límites
        }

        // Si solo hay una fecha 'from', comparar solo con esa
        if (filterValue.from) {
          const dateFrom = moment.tz(filterValue.from, timeZone).startOf("day");
          return filterDate.isSame(dateFrom, "day");
        }

        // Si no hay ningún filtro de fecha, pasar todos los valores
        return true;
      },
    },

    {
      accessorKey: "kind",
      header: "Tipo",
    },
    {
      accessorKey: "discount_value",
      header: "Descuento",
      cell: ({ row }) => {
        const amount = parseInt(row.getValue("discount_value"));
        // const formatted = new Intl.NumberFormat("en-US", {
        //   style: "currency",
        //   currency: "CLP",
        // }).format(amount);
        return <div>{amount}</div>;
      },
      filterFn: (row, columnId, filterValue) => {
        return row.original.discount_value.toString().includes(filterValue);
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const discount = row.original;
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className="text-dark" />}
              title={discount.discount_name}
              className={"px-2 button-no-style"}
              content={
                <DiscountForm initialValues={discount} onlyView={true} />
              }
            />
            <Modal
              large={true}
              name={<ReactSVG src={Pencil} className="text-dark" />}
              title={discount.name}
              className={"px-2 button-no-style"}
              content={
                <DiscountForm
                  initialValues={discount}
                  onlyView={false}
                  edit={true}
                  updateDiscount={setDiscount}
                />
              }
            />

            <button
              className="px-2 button-no-style"
              onClick={() => {
                deleteDiscount(domain, token, discount.id);
              }}
            >
              <ReactSVG src={Trash} className="text-dark" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        await getDiscounts();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }

    fetchData();
  }, []);

  async function getDiscounts() {
    const data = await getAllDiscount(domain, token);
    setDiscount(data);
  }

  //   async function deleteDiscount(item) {
  //     if (item.id) {
  //       setLoader(true);
  //       await deleteProduct(domain, token, item.id);
  //       await getDiscounts();
  //       Swal.fire({
  //         title: "Producto Eliminado con éxito",
  //         icon: "success",
  //         toast: true,
  //         timer: 6000,
  //         position: "top-right",
  //         timerProgressBar: true,
  //         showConfirmButton: false,
  //       });
  //       setLoader(false);
  //     }
  //   }

  //   async function exportToExcel() {
  //     const fileType =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //     const fileExtension = ".xlsx";
  //     const data = [];
  //     for (let index = 0; index < discount.length; index++) {
  //       const element = discount[index];
  //       let stringSku = "";
  //       for (let j = 0; j < element.sku.length; j++) {
  //         stringSku += element.sku[j].text + ",";
  //       }

  //       const product = {
  //         Id: element.id,
  //         Codigo_Interno: element.internal_code,
  //         Codigo_de_Barras: stringSku,
  //         Nombre: element.name,
  //         Marca: element.brand.name,
  //         Categoria: element.category.name,
  //         Precio_de_compra: element.buy_price,
  //         Precio_neto: element.net_price,
  //         Precio_de_venta: element.gross_price,
  //         IVA: element.total_vat,
  //         Margen_de_ganancia: element.margin_of_gain,
  //         Faccionable: element.fractionable ? "Si" : "No",
  //         Precio_fracción: element.price_fraction,
  //         Descripción: element.description,
  //         Bioequivalente: element.bioequivalent ? "Si" : "No",
  //         Receta: element.recipe ? "Si" : "No",
  //         Principio_Activo: element.active_principle,
  //       };
  //       data.push(product);
  //     }
  //     const ws = XLSX.utils.json_to_sheet(data);
  //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const excelBlob = new Blob([excelBuffer], { type: fileType });
  //     FileSaver.saveAs(excelBlob, "data" + fileExtension);
  //   }

  const extra = (
    <>
      <Modal
        name="Añadir Descuento"
        title="Nuevo Descuento"
        className={"w-100"}
        content={<DiscountForm />}
      />
    </>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Descuentos</h2>
          <DataTable
            // exportToExcel={exportToExcel}
            columns={columns}
            data={discount}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Discount;
