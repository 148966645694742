import React, { useContext, useEffect } from "react";
import Grid from "../../assets/icons/grid.svg";
import Archive from "../../assets/icons/archive.svg";
import Shop from "../../assets/icons/shop-1.svg";
import Cart from "../../assets/icons/cart.svg";
import Cube from "../../assets/icons/cube.svg";
import CreditCard from "../../assets/icons/creditCard.svg";
import GrowthGraph from "../../assets/icons/growthGraph.svg";
import Customer from "../../assets/icons/customer.svg";
import Back from "../../assets/icons/back.svg";
import Sale from "../../assets/icons/sale.svg";
import { ReactSVG } from "react-svg";
import { AppContext } from "../../context/AppContext";
import { Button } from "../ui/button";

function SideBar() {
  const { openSideBar, setOpenSideBar } = useContext(AppContext);
  const { currentRoute } = useContext(AppContext);

  /* Bloquea el scroll - solo en móvil */
  const useLockBodyScroll = (isLocked) => {
    useEffect(() => {
      if (isLocked) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }

      return () => {
        document.body.style.overflow = "";
      };
    }, [isLocked]);
  };

  const isMobile = () => window.innerWidth <= 768;
  useLockBodyScroll(openSideBar && isMobile());

  return (
    <div>
      <div
        className="bg-white shadow-md flex flex-col flex-shrink-0 p-3"
        style={{
          width: "250px",
          height: "100vh",
          position: "fixed",
          zIndex: "1000",
        }}
      >
        <ul className="flex flex-col">
          <li className="mb-3">
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/pos/list" ? "" : "ghost"}`}
            >
              <a href="/pos/list" className="p-2 text-dark">
                <ReactSVG src={Cart} />
                <p className={`ml-2`}>Punto de venta</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/dashboard" ? "" : "ghost"}`}
            >
              <a href="/dashboard" className="text-dark">
                <ReactSVG src={Grid} />
                <p className={`ml-2`}>Dashboard</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/company" ? "" : "ghost"}`}
            >
              <a href="/company" className="text-dark">
                <ReactSVG src={Shop} />
                <p className={`ml-2`}>Mi empresa</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/cash_register" ? "" : "ghost"}`}
            >
              <a href="/cash_register" className="text-dark">
                <ReactSVG src={Cart} />
                <p className={`ml-2`}>Registros de POS</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/sales" ? "" : "ghost"}`}
            >
              <a href="/sales" className="text-dark">
                <ReactSVG src={CreditCard} />
                <p className={`ml-2`}>Ventas</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/products" ? "" : "ghost"}`}
            >
              <a href="/products" className="text-dark">
                <ReactSVG src={Archive} />
                <p className={`ml-2`}>Productos</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/stock" ? "" : "ghost"}`}
            >
              <a href="/stock" className="text-dark">
                <ReactSVG src={GrowthGraph} />
                <p className={`ml-2`}>Stock</p>
              </a>
            </Button>
          </li>
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/warehouses" ? "" : "ghost"}`}
            >
              <a href="/warehouses" className="text-dark">
                <ReactSVG src={Cube} />
                <p className={`ml-2`}>Bodegas</p>
              </a>
            </Button>
          </li>
          <li className="hidden">
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/clients" ? "" : "ghost"}`}
            >
              <a href="/clients" className="text-dark">
                <ReactSVG
                  src={Customer}
                  className={`fill-current ${
                    currentRoute === "/clients" ? "text-white" : "text-black"
                  }`}
                />
                <p className={`ml-2`}>Clientes</p>
              </a>
            </Button>
          </li>
          <li className="hidden">
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/refunds" ? "" : "ghost"}`}
            >
              <a href="/refunds" className="text-dark">
                <ReactSVG
                  src={Back}
                  className={`fill-current ${
                    currentRoute === "/refunds" ? "text-white" : "text-black"
                  }`}
                />
                <p className={`ml-2`}>Devolución</p>
              </a>
            </Button>
          </li>
          {/* <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/packs" ? "" : "ghost"}`}
            >
              <a href="/packs" className="text-dark">
                <ReactSVG
                  src={Back}
                  className={`fill-current ${
                    currentRoute === "/packs" ? "text-white" : "text-black"
                  }`}
                />
                <p className={`ml-2`}>Packs</p>
              </a>
            </Button>
          </li> */}
          <li>
            <Button
              asChild
              className="w-full justify-start"
              variant={`${currentRoute === "/discount" ? "" : "ghost"}`}
            >
              <a href="/discount" className="text-dark">
                <ReactSVG
                  src={Sale}
                  className={`fill-current ${
                    currentRoute === "/discount" ? "text-white" : "text-black"
                  }`}
                />
                <p className={`ml-2`}>Promociones</p>
              </a>
            </Button>
          </li>
          {/**
                        
                    <li>
                        <Button asChild className="w-full justify-start" variant={`${currentRoute === '/purchases' ? '' : 'ghost'}`}>
                            <a href="/purchases" className="text-dark">
                                <ReactSVG src={Basket} />
                                <p className={`ml-2`}>Compras</p>
                            </a>
                        </Button>
                    </li>
                        */}
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
