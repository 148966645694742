import React, { useState, useEffect, useContext, useCallback } from "react";
import { object, string, number } from "yup";
import { Formik, Form, Field } from "formik";
import { AppContext } from "../../context/AppContext";
import { getAllPacks, postPacks, patchPacks } from "../../api/packs";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import Swal from "sweetalert2";

const validationSchema = object().shape({
  type: string().required("Tipo de descuento es requerido"),
  quantity: number().required("Descuento es requerido"),
  name_pack: string().required("Nombre de la promoción es requerida"),
});

const DISCOUNT_TYPES = ["%", "$"];

const PackForm = ({
  initialValues,
  onlyView,
  edit,
  newPack,
  updatePacks,
  handleClose,
  products,
}) => {
  const { domain, token } = useContext(AppContext);
  const [packProducts, setPackProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const [amountPack, setAmountPack] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [editableProducts, setEditableProducts] = useState([]);

  useEffect(() => {
    if (edit || onlyView) {
      setEditableProducts(initialValues.products);
      setAmountPack(initialValues.amount_pack);
    }
  }, [edit, onlyView, initialValues]);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddProduct = useCallback(
    (product) => {
      if (edit) {
        // Update editable products for edit mode
        setEditableProducts((prevProducts) => {
          // Check if product already exists
          const existingProductIndex = prevProducts.findIndex(
            (p) => p.product.name === product.name
          );

          let updatedProducts;
          if (existingProductIndex !== -1) {
            // If product exists, update its quantity
            updatedProducts = prevProducts.map((p, index) =>
              index === existingProductIndex
                ? {
                    ...p,
                    quantity: p.quantity + selectedProductQuantity,
                  }
                : p
            );
          } else {
            // If product doesn't exist, add it
            updatedProducts = [
              ...prevProducts,
              { product: product, quantity: selectedProductQuantity },
            ];
          }

          // Calculate new total amount
          const newAmount = updatedProducts.reduce(
            (sum, item) => sum + item.product.net_price * item.quantity,
            0
          );

          // Update amount pack
          setAmountPack(newAmount);

          // Update initialValues to keep form state in sync
          initialValues.products = updatedProducts;
          initialValues.amount_pack = newAmount;

          return updatedProducts;
        });
      } else {
        // Existing logic for new pack creation
        setPackProducts((prev) => [
          ...prev,
          { product: product, quantity: selectedProductQuantity },
        ]);
        setAmountPack(
          (prev) => prev + product.net_price * selectedProductQuantity
        );
      }
    },
    [edit, selectedProductQuantity, initialValues]
  );

  const addProduct = (product) => () => handleAddProduct(product);

  const handleRemoveProduct = useCallback(
    (productName) => {
      if (edit) {
        setEditableProducts((prevProducts) => {
          const updatedProducts = prevProducts.filter(
            (p) => p.product.name !== productName
          );

          // Calculate the new total amount
          const newAmount = updatedProducts.reduce(
            (sum, item) => sum + item.product.net_price * item.quantity,
            0
          );

          // Update the amount pack
          setAmountPack(newAmount);

          // Update the initialValues products
          initialValues.products = updatedProducts;
          initialValues.amount_pack = newAmount;

          return updatedProducts;
        });
      } else {
        setPackProducts((prev) => {
          const updatedPackProducts = prev.filter(
            (p) => p.product.name !== productName
          );

          // Calculate new amount for non-edit mode
          const newAmount = updatedPackProducts.reduce(
            (sum, item) => sum + item.product.net_price * item.quantity,
            0
          );

          setAmountPack(newAmount);
          return updatedPackProducts;
        });
      }
    },
    [edit, setAmountPack]
  );
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const packs = await getAllPacks(domain, token);
      if (packs.some((pack) => pack.name_pack === values.name_pack)) {
        Swal.fire({
          title: "Promoción ya ingresado anteriormente",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      const res = await postPacks(domain, token, {
        ...values,
        packProducts,
        amount_pack: amountPack,
      });
      if (res.id) {
        await updatePacks(packs);
        handleClose();
        Swal.fire({
          title: "Promoción Ingresado con exito",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        window.location.reload();
      } else {
        throw new Error(JSON.stringify(res));
      }
    } catch (error) {
      Swal.fire({
        title: "Error al ingresar promoción",
        text: `Detalle: ${error.message}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    try {
      await patchPacks(
        domain,
        token,
        {
          discount: values.discount,
          id: values.id,
          name_pack: values.name_pack,
          packProducts: editableProducts,
          amount_pack: amountPack,
        },
        initialValues.id
      );
      const packs = await getAllPacks(domain, token);
      await updatePacks(packs);
      handleClose();
      Swal.fire({
        title: "Pack Actualizado con exito",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error al actualizar pack",
        text: `Detalle: ${error.message}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        initialValues || {
          name_pack: "",
          quantity: 0,
          type: "",
          amount_pack: 0,
          discount: 0,
        }
      }
      validationSchema={!edit ? validationSchema : null}
      onSubmit={edit ? handleUpdate : handleSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          {isSubmitting ? (
            <div className="flex justify-center w-full align-center items-center">
              <img
                className="w-20 h-20 animate-spin"
                src="https://www.svgrepo.com/show/70469/loading.svg"
                alt="Loading icon"
              />
            </div>
          ) : (
            <>
              <Field name="name_pack">
                {({ field }) => (
                  <div className="mb-3">
                    <Label htmlFor="name_pack">
                      Nombre paquete
                      <Input {...field} type="text" disabled={onlyView} />
                      {errors.name_pack && touched.name_pack && (
                        <div className="text-red-500">{errors.name_pack}</div>
                      )}
                    </Label>
                  </div>
                )}
              </Field>

              <div className="mb-3">
                <Label htmlFor="typeSelect" className="font-semibold">
                  Tipo de descuento:
                </Label>
                <Select
                  id="typeSelect"
                  name="type"
                  value={values.type}
                  onValueChange={(value) => setFieldValue("type", value)}
                  disabled={onlyView}
                >
                  <SelectTrigger className="w-[280px]">
                    <SelectValue placeholder="Selecciona el tipo de descuento" />
                  </SelectTrigger>
                  <SelectContent>
                    {DISCOUNT_TYPES.map((type) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.type && touched.type && (
                  <div className="text-red-500 text-sm mt-1">{errors.type}</div>
                )}
              </div>

              <Field name="discount">
                {({ field }) => (
                  <div className="mb-3">
                    <Label htmlFor="discount">
                      Descuento
                      <Input {...field} type="number" disabled={onlyView} />
                    </Label>
                  </div>
                )}
              </Field>

              <div className="mb-3">
                <Label htmlFor="amount_pack">
                  Precio Total
                  <Input type="number" value={amountPack} disabled={true} />
                </Label>
              </div>

              {!onlyView && (
                <div className="mb-3">
                  <Label htmlFor="productSelect">Seleccionar Producto:</Label>
                  <Select
                    id="productSelect"
                    name="productSelect"
                    onValueChange={(value) => {
                      const selectedProductHere = products.find(
                        (product) => product.name === value
                      );
                      setSelectedProduct(selectedProductHere);
                    }}
                  >
                    <SelectTrigger className="w-[280px]">
                      <SelectValue placeholder="Seleccionar producto" />
                    </SelectTrigger>
                    <SelectContent>
                      <div className="p-2">
                        <input
                          type="text"
                          placeholder="Buscar Producto"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded"
                        />
                      </div>
                      {filteredProducts.map((product) => (
                        <SelectItem key={product.id} value={product.name}>
                          {product.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <div className="flex-row">
                    <div className="mt-2">
                      <Label htmlFor="productQuantity">Cantidad:</Label>
                      <Input
                        type="number"
                        id="productQuantity"
                        name="productQuantity"
                        value={selectedProductQuantity}
                        onChange={(e) =>
                          setSelectedProductQuantity(Number(e.target.value))
                        }
                        min="1"
                        className="form-control"
                      />
                    </div>
                    <div className="mt-2">
                      <Button
                        type="button"
                        onClick={addProduct(selectedProduct)}
                      >
                        Agregar
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-3 border-2 border-[#1e1e1f] p-4 rounded-lg">
                <Label className="font-medium ">Productos Seleccionados:</Label>
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      {!onlyView && (
                        <>
                          <th className="py-2 pr-[30rem]">Nombre</th>
                          <th className="py-2 pr-[30rem]">Cantidad</th>
                          <th className="py-2 pr-[30rem]">Acciones</th>
                        </>
                      )}

                      {onlyView && (
                        <>
                          <th className="py-2 pr-[50rem]">Nombre</th>
                          <th className="py-2 pr-[50rem]">Cantidad</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!onlyView &&
                      newPack &&
                      packProducts.map((product, index) => (
                        <tr key={index}>
                          <td className="py-2 m-auto">
                            {product.product["name"]}
                          </td>
                          <td className="py-2 m-auto">{product.quantity}</td>
                          <td className="py-2 m-auto">
                            <Button
                              type="button"
                              onClick={() => handleRemoveProduct(product.name)}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                      ))}

                    {(onlyView || edit) &&
                      editableProducts.map((product, index) => (
                        <tr key={index}>
                          <td className="py-2 m-auto">
                            {product.product.name}
                          </td>
                          <td className="py-2 m-auto">{product.quantity}</td>
                          {edit && (
                            <td className="py-2 m-auto">
                              <Button
                                type="button"
                                onClick={() =>
                                  handleRemoveProduct(product.product.name)
                                }
                              >
                                Eliminar
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {!onlyView && (
            <div className="flex flex-col">
              <Button type="submit">
                {edit ? "Actualizar promoción" : "Añadir promoción"}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PackForm;
