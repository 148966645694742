import React, { useContext, useEffect, useState } from 'react';
import Eye from '../assets/icons/pencil.svg';
import Pencil from '../assets/icons/pencil.svg';
import Trash from '../assets/icons/trash.svg';
import Plus from '../assets/icons/plus-1.svg';
import { ReactSVG } from 'react-svg';
import Modal from '../components/common/Modal';
import { AppContext } from '../context/AppContext';

function Employee() {
    const { domain, token } = useContext(AppContext);
    const [employees, setEmployees] = useState([]);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try{
                setLoader(true)
                await getEmployees();
            } catch(error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [])

    /*async function getEmployees(){
        try {
            const response = await fetch(`http://${domain}:4000/api/list_employee`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const text = await response.text();
            let data;

            try {
                data = JSON.parse(text)
            } catch (error) {
                throw new Error('Response is not valid JSON');
            }
            for (let index = 0; index < data.length; index++) {
                data[index].sku = JSON.parse(data[index].sku);
                data[index].tags = JSON.parse(data[index].tags);
            }
            console.log(data)
            setEmployees(data)
            setLoader(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }*/

    async function getEmployees(){
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi= isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/list_employee`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        for (let index = 0; index < data.length; index++) {
            data[index].sku = JSON.parse(data[index].sku);
            data[index].tags = JSON.parse(data[index].tags);
        }
        console.log(data)
        setEmployees(data)
        setLoader(false)
    }

  return (
    <div>
        <div className='container my-3'>
            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                <input 
                    placeholder='Buscar por Nombre o Email'
                    className='form-control mb-3 mb-md-0 me-md-3'
                 />
                 <div className='d-flex flex-column flex-md-row'>
                    <div className='mb-2 mb-md-0 p-2'>
                        <a className='btn' href="/new_employee">
                            <span className='d-inline-flex align-items-center tex-nowrap'>
                                Nuevo Empleado <ReactSVG src={Plus} className='text-white ml-2' />
                            </span>
                        </a>
                    </div>
                 </div>
            </div>
        </div>
        {
            loader ?
                <div>
                    <div className='spinner-border' role="status" />
                </div> :
                <div className='table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>Codigo Interno</th>
                                <th scope='col'>Email</th>
                                <th scope='col'>Nombre</th>
                                <th scope='col'>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee) => (
                                <tr>
                                    <td className='d-flex flex-column'>
                                        {employee.sku && employee.sku.map((item, index) => (
                                            <span key={index}>{item.text}</span>
                                        ))}
                                    </td>

                                    <td>{employee.email}</td>
                                    <td>{employee.first_name}</td>
                                    <td>{employee.role}</td>
                                    <td>
                                        <Modal
                                            large={true}
                                            name={<ReactSVG src={Eye} className='text-dark' />}
                                            title={employee.first_name}
                                            className={"px-2 button-no-style"}
                                            
                                        />
                                        <button className='px-2 button-no-style'>
                                            <ReactSVG src={Pencil} className='text-dar' />
                                        </button>
                                        <button className='px-2 button-no-style'>
                                            <ReactSVG src={Trash} className='text-dark' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        }
    </div>
  )
}

export default Employee