import React, { useContext, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "../../ui/input";
import { Combobox } from "../../ui/combobox";
import { Button } from "../../ui/button";

function Cart({
  products,
  cartItems,
  addToCart,
  removeFromCart,
  updateQuantity,
  updateDiscounts,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [otherDiscount, setOtherDiscount] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleOtherDiscountChange = (productId, event) => {
    const selectedDiscount = JSON.parse(event.target.value);

    // Obtener el producto correspondiente para acceder al gross_price
    const product = cartItems.find((item) => item.id === productId);

    let discountAmount;

    if (selectedDiscount.kind === "%") {
      discountAmount = selectedDiscount.discount_value;
    } else if (selectedDiscount.kind === "$" && product) {
      // Convertir el descuento fijo a porcentaje en base al precio bruto
      discountAmount =
        (selectedDiscount.discount_value / product.gross_price) * 100;
    } else {
      discountAmount = 0; // Si no es un tipo válido, el descuento es 0
    }

    setOtherDiscount((prevDiscounts) => ({
      ...prevDiscounts,
      [productId]: selectedDiscount,
    }));

    // Aplicar el descuento convertido a porcentaje
    updateDiscounts(productId, discountAmount);
  };

  const handleSearchCode = (e) => {
    e.preventDefault();
    if (searchTerm === "") {
    } else {
      const filtered = products.filter((product) =>
        JSON.parse(product.sku).some((codebar) =>
          codebar.text.includes(searchTerm)
        )
      );
      if (filtered.length > 0) {
        addToCart(filtered[0]);
      }
    }
    setSearchTerm("");
  };

  const handleQuantityChange = (productId, quantity) => {
    updateQuantity(productId, quantity);
  };

  const handleDiscountChange = (productId, discount) => {
    discount = Math.min(100, Math.max(0, discount));
    updateDiscounts(productId, discount);
  };

  const calculateSubtotal = (item) => {
    let subtotal =
      item.quantity * item.gross_price * (1 - (item.discount / 100 || 0));
    subtotal = Math.round(subtotal);
    return Number.isNaN(subtotal) ? 0 : subtotal;
  };
  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const handleProductSelect = (selected) => {
    addToCart(selected);
  };
  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-12 col-md-12 mb-3">
          <form onSubmit={handleSearchCode} className="mb-2">
            <Input
              placeholder="Código de barras"
              value={searchTerm}
              onChange={handleSearch}
            />
          </form>
          <Combobox
            options={products}
            placeholder={"Buscar productos"}
            onSelect={handleProductSelect}
            className="w-full"
          />
        </div>
      </div>
      <div
        className="mt-4"
        style={{
          minHeight: "calc(100vh - 300px)",
        }}
      >
        {cartItems.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead className="bg-gray-200">
                <tr>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Receta
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Cantidad
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Stock Actual
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Precio Bruto
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Descuento (%)
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Descuento convenio
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Subtotal
                  </th>
                  <th
                    scope="col"
                    className="py-2 px-4 border-b border-gray-300 text-left"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b border-gray-300">
                      {item.recipe ? "SI" : "NO"}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {item.name}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      <input
                        type="number"
                        className="w-16 p-1 border rounded"
                        value={item.quantity}
                        min={1}
                        onChange={(e) =>
                          handleQuantityChange(
                            item.id,
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {item.stock}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {item.gross_price}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      <input
                        type="number"
                        className="w-16 p-1 border rounded"
                        value={item.discount || 0}
                        onChange={(e) =>
                          handleDiscountChange(
                            item.id,
                            parseFloat(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={JSON.stringify(otherDiscount[item.id] || {})}
                        onChange={(e) => handleOtherDiscountChange(item.id, e)}
                      >
                        <option value="">
                          Escoger Promoción
                        </option>
                        {item.other_discount.map((discount) => (
                          <option
                            key={discount.id}
                            value={JSON.stringify(discount)}
                          >
                            {discount.discount_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {calculateSubtotal(item)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      <Button
                        variant="destructive"
                        onClick={() => handleRemoveFromCart(item.id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-muted">
            Aún no hay productos ingresados para esta venta. Busca un producto
            por nombre o por código para comenzar.
          </p>
        )}
      </div>
    </div>
  );
}

export default Cart;
