import { _delete, get, patch, post, put } from "./request";

export async function getAllRefunds(domain, token) {
  const data = await get(domain, token, "/api/refunds");
  return data;
}

export async function getOneRefund(domain, token, id) {
  const data = await get(domain, token, `/api/refunds/${id}`);
  return data;
}

export async function postRefund(domain, token, body) {
  const data = await post(domain, token, "/api/refunds", body);
  return data;
}

export async function putRefund(domain, token, body, id) {
  const data = await put(domain, token, `/api/refunds/${id}`, body);
  return data;
}

export async function patchRefund(domain, token, body, id) {
  const data = await patch(domain, token, `/api/refunds/${id}`, body);
  return data;
}

export async function deleteRefund(domain, token, id) {
  const data = await _delete(domain, token, `/api/refunds/${id}`);
  return data;
}
