import React, { useState } from 'react';

const BranchForm = ({ addBranch, branch, editBranch, handleClose }) => {
  const [name, setName] = useState(branch ? branch.name : '');
  const [address, setAddress] = useState(branch ? branch.address : '');
  const [email, setEmail] = useState(branch ? branch.email : '')

  const handleSubmit = (e) => {
    e.preventDefault();
    let id = 0;
    if (name.trim()) {
      {
        branch ?
          editBranch(id=branch.id,name, address, email) :
          addBranch(name, address, email);
      }
      setName('');
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-3">
      <div className="d-flex flex-column">
        <input
          type="text"
          className="form-control"
          placeholder="Nombre de sucursal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          className="form-control my-3"
          placeholder="Dirreción de sucursal"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Email de sucursal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className='ml-3 mt-3 btn'>
          {
            branch ? 'Editar Sucursal' : 'Añadir Sucursal'
          }
        </button>
      </div>
    </form>
  );
};

export default BranchForm;
