import { _delete, get, patch, post, put } from "./request";

export async function getAllBrands(domain,token,){
    const data = await get(domain,token,'/api/brand')
    return data
}

export async function getOneBrand(domain,token,id){
    const data = await get(domain,token,`/api/brand/${id}`)
    return data
}

export async function postBrand(domain,token,body){
    const data = await post(domain,token,'/api/brand',body)
    return data
}

export async function putBrand(domain,token,body,id){
    const data = await put(domain,token,`/api/brand/${id}`,body)
    return data
}

export async function patchBrand(domain,token,body,id){
    const data = await patch(domain,token,`/api/brand/${id}`,body)
    return data
}

export async function deleteBrand(domain,token,id){
    const data = await _delete(domain,token,`/api/brand/${id}`)
    return data
}