import React, { useContext, useEffect, useState } from 'react'
import BrandForm from './BrandForm';
import BrandList from './BrandList';
import { AppContext } from '../../context/AppContext';
import { getAllBrands,deleteBrand as _deleteBrand, putBrand, postBrand } from '../../api/brands';

function Brand() {
    const [brands, setBrands] = useState([]);
    const [loader, setLoader] = useState(false);
    const { domain, token } = useContext(AppContext);


    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                const data = await getAllBrands(domain,token)
                setBrands(data)
                setLoader(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [])

    const deleteBrand = async (id) => {
        setLoader(true)
        await _deleteBrand(domain,token,id)
        const data = await getAllBrands(domain,token)
        setBrands(data)
        setLoader(false)
    };

    async function editBrand(id, newName) {
        setLoader(true)
        await putBrand(domain,token,{
            name: newName
        },id)
        const data = await getAllBrands(domain,token)
        setBrands(data)
        setLoader(false)
    };

    async function addBrand(name) {
        setLoader(true)
        await postBrand(domain,token,{
            name: name
        })
        const data = await getAllBrands(domain,token)
        setBrands(data)
        setLoader(false)
    }

    return (
        <div>
             <BrandForm addBrand={addBrand} />
            <BrandList brands={brands} deleteBrand={deleteBrand} editBrand={editBrand} />
        </div>
    );
}

export default Brand