import React, { useContext, useEffect, useState } from 'react';
import Modal from '../common/Modal';
import BranchForm from './BranchForm';
import { AppContext } from '../../context/AppContext';
import { getAllBranches, putBranch } from '../../api/branches';

const Branch = () => {
  const [branches, setBranches] = useState([]);
  const [loader, setLoader] = useState(false);
  const { domain, token } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true)
        await getBranches();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [])

  async function getBranches() {
    const data = await getAllBranches(domain,token)
    setBranches(data)
    setLoader(false)
  }

  const editBranch = async (id, name, address, email) => {
    setLoader(true)
    await putBranch(domain,token,{
      name: name,
      address: address,
      email: email
    },id)
    await getBranches()
  };

  const addBranch = async (name, address, email) => {
    setLoader(true)
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi= isProduction ? `https://${domain}` : `http://${domain}:4000`;
    const response = await fetch(`${urlApi}/api/branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name: name,
        address: address,
        email: email
      })
    })
    await response.json()
    await getBranches()
  };

  return (
    <div>
      <h4>Sucursales</h4>
      <ul className="list-group">
        {
          loader ?
            <div className="d-flex justify-content-center my-2">
              <div className="spinner-border" role="status" />
            </div> :
            <>
              {
                branches.length > 0 ?
                  <>
                    {branches.map((branch) => (
                      <li key={branch.id} className="list-group-item d-flex justify-content-between align-items-center">
                        {branch.name}
                        <div className='ml-2'>
                          <Modal
                            name="Editar"
                            title="Editar Sucursal"
                            className={"w-100 mb-2 btn-primary"}
                            content={<BranchForm branch={branch} editBranch={editBranch} />}
                          />
                        </div>
                      </li>
                    ))}
                  </>
                  : <>
                    <p>No hay sucursales</p>
                  </>
              }
            </>
        }
      </ul>
      <Modal
        name="Agregar Sucursal"
        title="Agregar Sucursal"
        className={"w-100 mt-3 btn-secondary"}
        content={<BranchForm addBranch={addBranch} />}
      />
    </div>
  );
};

export default Branch;