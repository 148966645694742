import { _delete, get, patch, post, put } from "./request";

export async function getAllAttributes(domain,token,){
    const data = await get(domain,token,'/api/attributes')
    return data
}

export async function getOneAttribute(domain,token,id){
    const data = await get(domain,token,`/api/attributes/${id}`)
    return data
}

export async function postAttribute(domain,token,body){
    const data = await post(domain,token,'/api/attributes',body)
    return data
}

export async function putAttribute(domain,token,body,id){
    const data = await put(domain,token,`/api/attributes/${id}`,body)
    return data
}

export async function patchAttribute(domain,token,body,id){
    const data = await patch(domain,token,`/api/attributes/${id}`,body)
    return data
}

export async function deleteAttribute(domain,token,id){
    const data = await _delete(domain,token,`/api/attributes/${id}`)
    return data
}