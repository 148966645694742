import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../../context/AppContext';

const PosForm = ({ addPos, pos, editPos, handleClose }) => {
  const [name, setName] = useState(pos ? pos.name : '');
  const [loader, setLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const { domain, token } = useContext(AppContext);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleBranchChange = (event) => {
    const selectedBranchId = parseInt(event.target.value);
    setSelectedBranch(selectedBranchId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let id = 0;
    if (name.trim()) {
      {
        pos ?
          await editPos(id = pos.id, name) :
          await addPos(name,selectedBranch);
      }
      setName('');
      handleClose();
    }
    setLoader(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true)
        await getBranches();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [])

  async function getBranches() {
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi= isProduction ? `https://${domain}` : `http://${domain}:4000`;

    const response = await fetch(`${urlApi}/api/branch`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
    const data = await response.json()
    setBranches(data)
    setLoader(false)
  }

  return (
    <form onSubmit={handleSubmit} className="mb-3">
      {

        loader ?
          <div className="d-flex justify-content-center my-2">
            <div className="spinner-border" role="status" />
          </div> :
          <div className="d-flex flex-column">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Nombre de la caja"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <select className="form-select" aria-label="Select branch" onChange={handleBranchChange}>
              <option value="" selected disabled>Selecciona la sucursal</option>
              {branches.map(branch => (
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              ))}
            </select>
            <button type="submit" className='ml-3 mt-3 btn'>
              {
                pos ? 'Editar POS' : 'Añadir POS'
              }
            </button>
          </div>
      }
    </form>
  );
};

export default PosForm;
