"use client";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { getAllSales } from "../api/sales";
import { getAllDetails } from "../api/saleDetails";
import { AppContext } from "../context/AppContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../components/ui/select";

export default function Dashboard() {
  const [loader, setLoader] = useState(false);
  const [dataSales, setDataSales] = useState([]);
  const [filterDataSales, setFilterDataSales] = useState([]);
  const [dataDetails, setDataDetails] = useState([]);
  const [timeFilter, setTimeFilter] = useState("7d");
  const { domain, token } = useContext(AppContext);

  const [startDate, setStartDate] = useState(moment().startOf('day').toISOString());
  const [endDate, setEndDate] = useState(moment().endOf('day').toISOString());
  const [heatmapData, setHeatmapData] = useState([]);
  const [averageSales, setAverageSales] = useState(0);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  async function fetchData() {
    try {
      setLoader(true);
      console.log('hi')
      const sales = await getAllSales(domain, token);
      setDataSales(sales);

      const filteredData = sales.filter(sale => {
        const saleDate = moment(sale.date);
        return saleDate.isBetween(moment(startDate), moment(endDate), null, '[]');
      });

      const hourlySales = Array.from({ length: 24 }, () => ({ total: 0, count: 0 }));

      filteredData.forEach(sale => {
        const hour = moment(sale.date).hour();
        hourlySales[hour].total += sale.gross_price;
        hourlySales[hour].count += 1;
      });

      setHeatmapData(hourlySales);

      const totalSales = hourlySales.reduce((acc, curr) => acc + curr, 0);
      const hoursWithSales = hourlySales.filter(value => value > 0).length;
      const average = hoursWithSales ? totalSales / hoursWithSales : 0;
      setAverageSales(average);

      const details = await getAllDetails(domain, token);
      const top = getTop10Products(details);
      setDataDetails(top);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const filteredSales = filterSalesByTime(dataSales);
    const salesFormat = formatSales(filteredSales);
    setFilterDataSales(salesFormat);
  }, [timeFilter, loader]);

  function getTop10Products(salesArray) {
    const productMap = {};

    salesArray.forEach((sale) => {
      const { name, qty } = sale;
      if (productMap[name]) {
        productMap[name] += qty;
      } else {
        productMap[name] = qty;
      }
    });

    const productArray = Object.keys(productMap).map((name) => ({
      name,
      qty: productMap[name],
    }));

    productArray.sort((a, b) => b.qty - a.qty);

    return productArray.slice(0, 10);
  }

  function filterSalesByTime(dataSales) {
    return dataSales.filter((sale) => {
      const saleDate = moment(sale.date);
      if (timeFilter === "7d") {
        return (
          moment(moment().toDate(), "MM-DD-YYYY").isoWeek() ===
          moment(saleDate, "MM-DD-YYYY").isoWeek()
        );
      } else if (timeFilter === "month") {
        return saleDate.isSame(moment(), "month");
      }
    });
  }

  function formatSales(sales) {
    const salesByDate = {};
    for (let index = 0; index < sales.length; index++) {
      const sale = sales[index];
      const date = moment(sale.date).tz("America/Santiago").format("YYYY-MM-DD");

      if (!salesByDate[date]) {
        salesByDate[date] = 0;
      }

      salesByDate[date] += sale.gross_price;
    }

    const result = Object.keys(salesByDate).map((date) => ({
      date: date,
      total: salesByDate[date],
    }));

    return result;
  }

  const getHeatmapColor = (value) => {
    if (value.total === 0) return "#ffcccc";
    else if (value.total < averageSales * 0.5) return "#ffcccc";
    else if (value.total < averageSales * 1.5) return "#ffff99";
    else return "#ccffcc";
  };
  return (
    <div className="space-y-4">
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            className="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Card>
              <CardHeader>
                <CardTitle>Resumen de ventas</CardTitle>
                <CardDescription>
                  Ingresos totales durante el período seleccionado
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Select
                  onValueChange={(value) => setTimeFilter(value)}
                  defaultValue={timeFilter}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Selecciona el tiempo para filtrar" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="7d">Esta semana</SelectItem>
                    <SelectItem value="month">Este mes</SelectItem>
                  </SelectContent>
                </Select>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={filterDataSales}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="total" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Top Productos</CardTitle>
                <CardDescription>
                  Cantidad de productos vendidos
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={dataDetails}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="qty" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            <Card className="col-span-2">
              <CardHeader>
                <CardTitle>Mapa de Calor de Ventas</CardTitle>
                <CardDescription>Ventas por hora</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex space-x-4 mb-4">
                  <label>
                    Fecha Inicio:
                    <input
                      type="date"
                      value={moment(startDate).format("YYYY-MM-DD")}
                      onChange={(e) => setStartDate(moment(e.target.value).startOf('day').toISOString())}
                      className="border border-gray-300 rounded-lg py-2 px-4"
                    />
                  </label>
                  <label>
                    Fecha Fin:
                    <input
                      type="date"
                      value={moment(endDate).format("YYYY-MM-DD")}
                      onChange={(e) => setEndDate(moment(e.target.value).endOf('day').toISOString())}
                      className="border border-gray-300 rounded-lg py-2 px-4"
                    />
                  </label>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(8, 1fr)", gap: "10px" }}>
                  {heatmapData.map((hourData, hour) => (
                    <div
                      key={hour}
                      style={{
                        backgroundColor: getHeatmapColor(hourData),
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                        {hour}:00 - {hour + 1}:00
                      </strong>
                      <div>
                        Total vendido:{" "}
                        {(hourData.total || 0).toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                          minimumFractionDigits: 0,
                        })}
                      </div>
                      <div>
                        Cantidad de ventas: {hourData.count}
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}
