import React, { useState } from 'react';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

const BrandForm = ({ addBrand, brand, editBrand }) => {
  const [name, setName] = useState(brand ? brand.name : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      if (editBrand && brand) {
        editBrand(brand.id, name)
      }
      else {
        addBrand(name);
        setName('');
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Label htmlForf="name">
        Nombre de marca
        <Input
          type="text"
          id="name"
          className="form-control"
          placeholder="Ingresa aqui el nombre de la marca"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Label>
      <Button type="submit" className='mt-3 btn'>
        {brand ? 'Actualizar marca' : 'Añadir marca'}
      </Button>
    </form>
  );
};

export default BrandForm;
