import React, { useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const FolioForm = ({ addFolio, folio, editFolio }) => {
  const [type, setType] = useState();
  const [file, setFile] = useState(null);
  const [startFolio, setStartFolio] = useState();
  const [endFolio, setEndFolio] = useState();
  const [cert, setCert] = useState();

  const foliosType = [
    {
      type: 33,
      folio: "Factura Electrónica",
    },
    {
      type: 56,
      folio: "Nota de Débito Electrónica",
    },
    {
      type: 61,
      folio: "Nota de Crédito Electrónica",
    },
    {
      type: 39,
      folio: "Boleta Electrónica",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64File = reader.result.split(",")[1]; // Get the base64 string
          if (editFolio && folio) {
            editFolio(folio.id, startFolio, endFolio, base64File, type);
          } else {
            addFolio(type, base64File, startFolio, endFolio);
            setType("");
            setStartFolio(0);
            setEndFolio(0);
            setFile(null);
          }
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        if (editFolio && folio) {
          editFolio(folio.id, startFolio, endFolio, "", type);
        } else {
          addFolio(type, file, startFolio, endFolio);
          setType("");
          setStartFolio(0);
          setEndFolio(0);
          setFile(null);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor="type">Tipo de Documento:</Label>
      <Select
        id="type"
        name="type"
        value={type}
        onValueChange={(value) => setType(value)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Seleccionar Tipo" />
        </SelectTrigger>
        <SelectContent>
          {foliosType.map((folio) => (
            <SelectItem key={folio.type} value={folio.type.toString()}>
              {folio.folio}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Label htmlFor="url">Archivo:</Label>
      <Input
        id="url"
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
        placeholder="Subir Archivo"
      />

      <Label htmlFor="startFolio">Folio Inicial:</Label>
      <Input
        id="startFolio"
        type="number"
        value={startFolio}
        onChange={(e) => setStartFolio(e.target.value)}
        placeholder="Ingrese el Folio Inicial"
      />

      <Label htmlFor="endFolio">Folio Final:</Label>
      <Input
        id="endFolio"
        type="number"
        value={endFolio}
        onChange={(e) => setEndFolio(e.target.value)}
        placeholder="Ingrese el Folio Final"
      />

      <Button type="submit" className="mt-3 btn">
        {folio ? "Actualizar Folio" : "Añadir Folio"}
      </Button>
    </form>
  );
};

export default FolioForm;
