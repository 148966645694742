import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Cart from "./posUi/Cart";
import Summary from "./posUi/Summary";
import { getAllWarehouses } from "../../api/warehouses";
import { getStock, patchStock } from "../../api/stock";
import { Button } from "../ui/button";
import { getAllClients } from "../../api/clients";
import Modal from "../common/Modal";
import ClientForm from "../clients/ClientForm";
import { getAllDiscount } from "../../api/discount";

function PosUI({ cashRegister }) {
  const { domain, token } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [discount, setDiscount] = useState([]);
  const [selectedDiscount, setSetectedDiscount] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const warehouse = await getAllWarehouses(domain, token);
        setWarehouses(warehouse);
        setSelectedWarehouse(warehouse[0].id);
        const products = await getStock(domain, token, warehouse[0].id);
        setProducts(products);
        const client = await getAllClients(domain, token);
        setClients(client);
        const discount = await getAllDiscount(domain, token);
        setDiscount(discount);
        setSelectedClient(client[0].id);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }

    fetchData();
  }, []);

  async function updateStock(stockId, stock) {
    await patchStock(
      domain,
      token,
      {
        stock: parseInt(stock),
      },
      stockId
    );
  }

  /**
     const extra = (
    <Modal
      name="Añadir cliente"
      title="Nuevo cliente"
      className={"w-full"}
      content={<ClientForm updateClients={setClients} />}
    />
  );
   */

  const addToCart = (product) => {
    const otherDiscount = discount.filter(
      (item) => item.product.id === product.id
    );
    setCartItems((prevItems) => {
      const itemExists = prevItems.find((item) => item.id === product.id);
      if (itemExists) {
        return prevItems.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [
        ...prevItems,
        {
          ...product,
          quantity: 1,
          discount: "",
          other_discount: otherDiscount,
        },
      ];
    });
  };

  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity: quantity } : item
      )
    );
  };

  const updateDiscounts = (productId, discount) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId
          ? {
              ...item,
              discount:
                discount === "" ? 0 : discount.toString().replace(/^0+/, ""),
            }
          : item
      )
    );
  };

  function handleWarehouse(value) {
    setSelectedWarehouse(value);
  }

  function handleClient(value) {
    setSelectedClient(value);
  }

  return (
    <div className="grid grid-cols-4 gap-2">
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-subgrid col-span-3">
            <Cart
              products={products}
              cartItems={cartItems}
              updateDiscounts={updateDiscounts}
              addToCart={addToCart}
              updateQuantity={updateQuantity}
              removeFromCart={removeFromCart}
            />
            <Button variant="destructive">
              <a href={`/close_pos?id=${cashRegister.id}`} className="">
                Cierre de caja
              </a>
            </Button>
          </div>
          <div className="">
            {
              /**
              extra
              */
            }
            <Summary
              setCartItems={setCartItems}
              selectedWarehouse={selectedWarehouse}
              cashRegister={cashRegister}
              updateStock={updateStock}
              setClients={setClients}
              getStock={async () => {
                const products = await getStock(
                  domain,
                  token,
                  selectedWarehouse
                );
                setProducts(products);
              }}
              warehouses={warehouses}
              cartItems={cartItems}
              handleWarehouse={handleWarehouse}
              selectedClient={selectedClient}
              clients={clients}
              handleClient={handleClient}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PosUI;
