import { _delete, get, patch, post, put } from "./request";

export async function getAllWarehouses(domain,token,){
    const data = await get(domain,token,'/api/warehouse')
    return data
}

export async function getOneWarehouse(domain,token,id){
    const data = await get(domain,token,`/api/warehouse/${id}`)
    return data
}

export async function postWarehouse(domain,token,body){
    const data = await post(domain,token,'/api/warehouse',body)
    return data
}

export async function putWarehouse(domain,token,body,id){
    const data = await put(domain,token,`/api/warehouse/${id}`,body)
    return data
}

export async function patchWarehouse(domain,token,body,id){
    const data = await patch(domain,token,`/api/warehouse/${id}`,body)
    return data
}

export async function deleteWarehouse(domain,token,id){
    const data = await _delete(domain,token,`/api/warehouse/${id}`)
    return data
}