import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Pencil from '../../assets/icons/pencil.svg';
import Trash from '../../assets/icons/trash.svg';
import Save from '../../assets/icons/save.svg';
import BrandForm from './BrandForm';
import Modal from '../common/Modal';
import { Button } from '../ui/button';
import { DataTable } from '../ui/data-table';

const BrandList = ({ brands, deleteBrand, editBrand }) => {
    const columns = [
        {
            accessorKey: "id",
            header: "Id",
        },
        {
            accessorKey: "name",
            header: "Nombre",
        },
        {
            accessorKey: "actions",
            header: "Acciones",
            cell: ({ row }) => {
                const brand = row.original
                return (
                    <>
                        <Modal
                            name="Editar"
                            title="Editar Categoria"
                            className={"w-100 mb-2 btn-primary"}
                            content={<BrandForm brand={brand} editBrand={editBrand} />} />
                        <Button onClick={() => { deleteBrand(brand.id) }}>Eliminar Marca</Button>
                    </>
                )
            }
        },
    ]

    return (
        <div>
            <div>
                <h4>Lista de Marcas</h4>
                <DataTable columns={columns} data={brands} />
            </div>
        </div>
    );
};

export default BrandList;
