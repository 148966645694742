import { _delete, get, patch, post, put } from "./request";

export async function getAllStock(domain, token) {
  const data = await get(domain, token, "/api/stock");
  // for (let index = 0; index < data.length; index++) {
  //     data[index].sku = JSON.parse(data[index].sku);
  //     data[index].tags = JSON.parse(data[index].tags);
  // }
  return data;
}

export async function getStock(domain, token, warehouseId) {
  const data = await get(
    domain,
    token,
    `/api/warehouse/${warehouseId}/products`
  );
  return data;
}

export async function getOneStock(domain, token, id) {
  const data = await get(domain, token, `/api/stock/${id}`);
  return data;
}

export async function postStock(domain, token, body) {
  const data = await post(domain, token, "/api/stock", body);
  return data;
}

export async function putStock(domain, token, body, id) {
  const data = await put(domain, token, `/api/stock/${id}`, body);
  return data;
}

export async function patchStock(domain, token, body, id) {
  const data = await patch(domain, token, `/api/stock/${id}`, body);
  return data;
}

export async function deleteStock(domain, token, id) {
  const data = await _delete(domain, token, `/api/stock/${id}`);
  return data;
}
