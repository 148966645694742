import { useContext } from 'react'
import jwt_decode from "jwt-decode"
import AuthContext from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppContext';

function Navbar() {

  const { user, logoutUser } = useContext(AuthContext)
  const token = localStorage.getItem("authTokens")
  const { currentRoute, setCurrentRoute } = useContext(AppContext);

  if (token) {
    const decoded = jwt_decode(token)
    var user_id = decoded.user_id
  }

  return (
    <div>
      <nav className="bg-gray-800 fixed w-full z-10 top-0 shadow">
        <div className="container mx-auto flex flex-wrap items-center justify-between py-3">
          <a className="flex items-center" href="#">
            <img className="h-12 w-12 p-1" src="https://via.placeholder.com/48x48" alt="" />
          </a>
          <button className="text-gray-500 hover:text-white focus:text-white focus:outline-none lg:hidden" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block" id="navbarNav">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {token === null &&
                <>
                  <li className="nav-item">
                    <Link className={`nav-link ${currentRoute === '/login' && 'active'} px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75`} to="/login" onClick={() => { setCurrentRoute("/login") }}>Iniciar sesión</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${currentRoute === '/register' && 'active'} px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75`} to="/register" onClick={() => { setCurrentRoute("/register") }}>Registro</Link>
                  </li>
                </>
              }

              {token !== null &&
                <>
                  <li className="nav-item">
                    <a className={`nav-link ${currentRoute === '/dashboard' && 'active'} px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75`} href="/dashboard" onClick={() => { setCurrentRoute("/register") }}>Dashboard</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75`} onClick={logoutUser} style={{ cursor: "pointer" }}>Logout</a>
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
