import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext';
import { ReactSVG } from 'react-svg';
import Pencil from '../assets/icons/pencil.svg';
import Trash from '../assets/icons/trash.svg';
import Plus from '../assets/icons/plus-1.svg';
import Save from '../assets/icons/save.svg';
import Cancel from '../assets/icons/cancel.svg';
import Modal from '../components/common/Modal'
import PosForm from '../components/pos/PosForm';
import moment from 'moment-timezone';
import { Button } from '../components/ui/button';

function PosList() {
  const [loader, setLoader] = useState(false)
  const { domain, token } = useContext(AppContext);
  const [pos, setPos] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true)
        await getPos()
        setLoader(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [])

  async function getPos() {
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    const response = await fetch(`${urlApi}/api/pos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
    const data = await response.json()
    await getLogs(data)
  }

  async function addPos(name, branchId) {
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    await fetch(`${urlApi}/api/pos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name: name,
        branch_id: branchId
      })
    })
    await getPos()
  }

  async function getLogs(pos) {
    const isProduction = process.env.NODE_ENV === 'production';
    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

    const response = await fetch(`${urlApi}/api/cash_register`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
    const data = await response.json()
    const todayChile = moment().tz("America/Santiago").format('YYYY-MM-DD');

    const updatedPos = pos.map(posItem => {
      const log = data.find(item => item.pos === posItem.id && item.open === true);
      if (log) {
        const openedAtChile = moment(log.opened_at).tz("America/Santiago").format('YYYY-MM-DD');
        return {
          ...posItem,
          open: log.open === true,
          log_id: log.id
        };
      }
      return { ...posItem, open: false };
    });
    setPos(updatedPos);
  }

  return (
    <div>
      <Modal
        name={<>Nuevo POS <ReactSVG src={Plus} className='text-black ml-2' /></>}
        title="Nuevo Punto de venta"
        content={<PosForm addPos={addPos} />}
      />
      {
        loader ?
          <div className='flex justify-center w-full align-center items-center'>
            <img class="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/70469/loading.svg" alt="Loading icon"></img>
          </div> :
          <div>
            <div className='flex justify-center flex-col md:flex-row'>
              {
                pos.map((item) => (
                  <div className={`m-3`}
                    style={{
                      width: '18rem',
                      backgroundColor: item.open ? '#cfffca' : '#fd9999'
                    }}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg-bIb8rse04igcRMHf_yC8Vtcpp210jIXbA&s"
                      className="p-3"
                      alt="Imagen" />

                    <div className='flex flex-col'>
                      <h3 className='text-center font-bold'>{item.name}</h3>
                      {
                        item.open ?
                          <div className='flex justify-center align-center p-2'>
                            <Button className="mr-3">
                              <a href={`/close_pos?id=${item.log_id}`} className="btn w-100">Cerrar caja</a>
                            </Button>

                            <Button variant="secondary">
                              <a href={`/pos?id=${item.id}`} className="btn w-100">Continuar</a>
                            </Button>
                          </div> :
                          <Button><a href={`/pos?id=${item.id}`} className="btn w-100">Abrir caja</a></Button>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

      }
    </div>
  )
}

export default PosList