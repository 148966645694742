import React, { useState, useEffect, useContext } from 'react';
import TagsInput from '../common/TagsInput';
import { AppContext } from '../../context/AppContext';
import { getAllBrands } from '../../api/brands';
import { getAllCategories } from '../../api/categories';
import { getAllAttributes } from '../../api/attributes';
import { getAllProducts, postProduct, putProduct } from '../../api/products';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Checkbox } from '../ui/checkbox';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Textarea } from '../ui/textarea';
const swal = require('sweetalert2')

const ProductForm = ({ initialValues, onlyView, edit, updateProducts, handleClose }) => {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loader, setLoader] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const { domain, token } = useContext(AppContext);
    const [product, setProduct] = useState({
        name: '',
        net_price: 0,
        gross_price: 0,
        total_vat: 0,
        margin_of_gain: 0,
        buy_price: 0,
        sku: [],
        internal_code: '',
        fractionable: false,
        price_fraction: 0,
        description: '',
        brand_id: '',
        category_id: '',
        image_url: 'https://t3.ftcdn.net/jpg/02/55/18/94/360_F_255189484_yTqDLPoDFs6X90iUCDEchY5ZSz0ml7Va.jpg',
        tags: [],
        bioequivalent: false,
        recipe: false,
        active_principle: ''
    });
    const [valueAttributes, setValueAttributes] = useState({})

    // Si se proporcionan initialValues, establecer el estado inicial del producto
    useEffect(() => {

        if (initialValues) {
            setProduct(
                {
                    name: initialValues.name,
                    net_price: initialValues.net_price,
                    gross_price: initialValues.gross_price,
                    total_vat: initialValues.total_vat,
                    margin_of_gain: initialValues.margin_of_gain,
                    buy_price: initialValues.buy_price,
                    sku: initialValues.sku,
                    internal_code: initialValues.internal_code,
                    fractionable: initialValues.fractionable,
                    price_fraction: initialValues.price_fraction,
                    description: initialValues.description,
                    brand_id: initialValues.brand.id.toString(),
                    category_id: initialValues.category.id.toString(),
                    image_url: initialValues.image_url,
                    tags: initialValues.tags,
                    bioequivalent: initialValues.bioequivalent,
                    recipe: initialValues.recipe,
                    active_principle: initialValues.active_principle
                }
            );
        }
        async function fetchData() {
            try {
                setLoader(true)

                const [brandsResponse, categoriesResponse, attributesResponse] = await Promise.all([
                    getAllBrands(domain, token),
                    getAllCategories(domain, token),
                    getAllAttributes(domain, token),
                ]);


                setBrands(brandsResponse);
                setCategories(categoriesResponse);
                setAttributes(attributesResponse);

                setLoader(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === "total_vat" || name === 'net_price' || name === "gross_price" || name === "margin_of_gain" || name === 'buy_price') {
            let cleanValue = value.replace(/[^\d]/g, '');
            if (name === 'buy_price') {
                const margin = product.margin_of_gain / 100;
                let netPrice = Math.round(cleanValue * margin);
                netPrice += parseInt(cleanValue);
                let totalVat = Math.round((netPrice * 0.19));
                let salePrice = netPrice + totalVat

                if (isNaN(netPrice)) {
                    netPrice = 0;
                }
                if (isNaN(totalVat)) {
                    totalVat = 0;
                }
                if (isNaN(salePrice)) {
                    salePrice = 0;
                }
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    [name]: cleanValue,
                    total_vat: totalVat,
                    gross_price: salePrice,
                    net_price: netPrice
                }));
            }

            else if (name === "margin_of_gain") {
                // Convertir el margen de ganancia a un decimal
                const profitMargin = value;
                const marginDecimal = profitMargin / 100;
                // Calcular el precio de venta con el Margen de ganancia
                let netPrice = Math.round(product.buy_price * marginDecimal) + parseFloat(product.buy_price);
                // IVA
                const vatRate = 0.19;
                // Calcular IVA
                let totalVat = Math.round(netPrice * vatRate);
                let salePrice = netPrice + totalVat;


                if (isNaN(totalVat)) {
                    totalVat = 0;
                }
                if (isNaN(salePrice)) {
                    salePrice = 0;
                }
                if (isNaN(netPrice)) {
                    netPrice = 0;
                }
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    [name]: value,
                    total_vat: totalVat,
                    gross_price: salePrice,
                    net_price: netPrice
                }));
            }

            else if (name === "gross_price") {
                const vatRate = 0.19;
                // Calcula el precio de venta sin IVA
                const sellPriceWithoutVAT = cleanValue / (1 + vatRate);
                // Calcula el margen de ganancia
                const profitAmount = sellPriceWithoutVAT - product.buy_price;
                //  Calcula el margen de ganancia como %
                const profitMargin = (profitAmount / product.buy_price) * 100;
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    [name]: cleanValue,
                    total_vat: Math.round(cleanValue - sellPriceWithoutVAT),
                    net_price: Math.round(sellPriceWithoutVAT),
                    margin_of_gain: profitMargin.toFixed(2)
                }));
            }
            else {
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    [name]: cleanValue,
                }));
            }
        } else {
            setProduct((prevProduct) => ({
                ...prevProduct,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    const handleChangeAttributes = (e) => {
        const { name, value, type, checked } = e.target;
        setValueAttributes((prevValue) => ({
            ...prevValue,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault();
        if (
            product.internal_code !== '' &&
            product.internal_code !== null &&
            product.sku.length > 0 &&
            product.name !== '' &&
            product.gross_price > 0 &&
            product.brand_id !== '' &&
            product.category_id !== ''
        ) {

            const data = {
                name: product.name,
                net_price: product.net_price,
                gross_price: product.gross_price,
                total_vat: product.total_vat,
                margin_of_gain: product.margin_of_gain,
                buy_price: product.buy_price,
                sku: JSON.stringify(product.sku),
                internal_code: product.internal_code,
                fractionable: product.fractionable,
                price_fraction: product.price_fraction,
                description: product.description,
                brand_id: product.brand_id,
                category_id: product.category_id,
                image_url: product.image_url,
                tags: JSON.stringify(product.tags),
                bioequivalent: product.bioequivalent,
                recipe: product.recipe,
                active_principle: product.active_principle
            }
            const res = await postProduct(domain, token, data)
            try {
                if (res.id) {
                    const products = await getAllProducts(domain, token)
                    await updateProducts(products)
                    setProduct({
                        name: '',
                        net_price: 0,
                        gross_price: 0,
                        total_vat: 0,
                        margin_of_gain: 0,
                        buy_price: 0,
                        sku: [],
                        internal_code: '',
                        fractionable: false,
                        price_fraction: 0,
                        description: '',
                        brand_id: '',
                        category_id: '',
                        tags: [],
                        image_url: 'https://t3.ftcdn.net/jpg/02/55/18/94/360_F_255189484_yTqDLPoDFs6X90iUCDEchY5ZSz0ml7Va.jpg'
                    })
                    setLoader(false)
                    handleClose()
                    swal.fire({
                        title: "Producto Ingresado con exito",
                        icon: "success",
                        toast: true,
                        timer: 6000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                }
                else{
                    swal.fire({
                        title: 'Error al ingresar producto',
                        text: `Detalle: ${JSON.stringify(res)}`,
                        icon: "error",
                        toast: true,
                        timer: 6000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                }
            } catch (error) {
                
                swal.fire({
                    title: 'Error al ingresar producto',
                    text: `Detalle: ${JSON.stringify(res)}`,
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            }

        }
        else {
            setLoader(false)
            swal.fire({
                title: "Error al ingresar producto, faltan atributos",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const data = {
            name: product.name,
            net_price: product.net_price,
            gross_price: product.gross_price,
            total_vat: product.total_vat,
            margin_of_gain: product.margin_of_gain,
            buy_price: product.buy_price,
            sku: JSON.stringify(product.sku),
            internal_code: product.internal_code,
            fractionable: product.fractionable,
            price_fraction: product.price_fraction,
            description: product.description,
            brand_id: product.brand_id,
            category_id: product.category_id,
            image_url: product.image_url,
            tags: JSON.stringify(product.tags),
            bioequivalent: product.bioequivalent,
            recipe: product.recipe,
            active_principle: product.active_principle
        }
        setLoader(true)
        await putProduct(domain, token, data, initialValues.id)
        const products = await getAllProducts(domain, token)
        await updateProducts(products)
        handleClose()
        setLoader(false)
        swal.fire({
            title: "Producto Acutalizado con exito",
            icon: "success",
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        })
    };

    const handleDeleteTags = (i) => {
        const tags = (product.tags.filter((tag, index) => index !== i));
        setProduct((prevProduct) => ({
            ...prevProduct,
            tags: tags,
        }));
    };

    const handleAdditionTags = (tag) => {
        const tags = [...product.tags, tag]
        setProduct((prevProduct) => ({
            ...prevProduct,
            tags: tags,
        }));
    };

    const handleDragTags = (tag, currPos, newPos) => {
        const newTags = product.tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setProduct((prevProduct) => ({
            ...prevProduct,
            tags: newTags,
        }));
    };

    const handleDeleteSku = (i) => {
        const skuFilter = (product.sku.filter((sku, index) => index !== i));
        setProduct((prevProduct) => ({
            ...prevProduct,
            sku: skuFilter,
        }));
    };

    const handleAdditionSku = (sku) => {
        const skuArray = [...product.sku, sku]
        setProduct((prevProduct) => ({
            ...prevProduct,
            sku: skuArray,
        }));
    };

    const handleDragSku = (sku, currPos, newPos) => {
        const newSku = product.sku.slice();
        newSku.splice(currPos, 1);
        newSku.splice(newPos, 0, sku);
        setProduct((prevProduct) => ({
            ...prevProduct,
            sku: newSku,
        }));
    };


    return (
        <form onSubmit={handleSubmit}>
            {loader ? <div className='flex justify-center w-full align-center items-center'>
                <img class="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/70469/loading.svg" alt="Loading icon"></img>
            </div> :
                <>
                    <div className="columns-2">
                        <div className="">
                            <div className="mb-3">
                                <Label htmlFor="name">
                                    Nombre
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={product.name}
                                        onChange={handleChange}
                                        required
                                        disabled={onlyView && true} />
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="buy_price">
                                    Precio de compra
                                    <Input
                                        type="text"
                                        step="0.01"
                                        id="buy_price"
                                        name="buy_price"
                                        value={product.buy_price}
                                        onChange={handleChange}
                                        required
                                        disabled={onlyView && true}
                                    />
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="margin_of_gain">
                                    Margen de ganancia
                                    <Input
                                        type="number"
                                        step="0.01"
                                        id="margin_of_gain"
                                        name="margin_of_gain"
                                        value={product.margin_of_gain}
                                        onChange={handleChange}
                                        required
                                        disabled={onlyView && true}
                                    />
                                </Label>
                            </div>

                            <div className="mb-3">
                                <Label>
                                    Margen de ganancia (CLP)
                                    <Input
                                        value={Math.round((product.gross_price / 1.19) - product.buy_price)}
                                        disabled
                                    />
                                </Label>
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="net_price">
                                    Precio neto
                                    <Input
                                        type="text"
                                        step="0.01"
                                        id="net_price"
                                        name="net_price"
                                        value={product.net_price}
                                        onChange={handleChange}
                                        required
                                        disabled={onlyView && true}
                                    />
                                </Label>
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="total_vat">
                                    IVA
                                    <Input
                                        type="text"
                                        step="0.01"
                                        id="total_vat"
                                        name="total_vat"
                                        disabled
                                        value={product.total_vat}
                                        onChange={handleChange}
                                        required
                                    />
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="gross_price">
                                    Precio de venta (bruto)
                                    <Input
                                        type="text"
                                        step="0.01"
                                        id="gross_price"
                                        name="gross_price"
                                        value={product.gross_price}
                                        onChange={handleChange}
                                        required
                                        disabled={onlyView && true}
                                    />
                                </Label>
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="bioequivalent">
                                    Bioequivalente
                                    <Checkbox
                                        id="bioequivalent"
                                        name="bioequivalent"
                                        checked={product.bioequivalent}
                                        onCheckedChange={(checked) => {
                                            handleChange({
                                                target: {
                                                    name: "bioequivalent",
                                                    value: checked,
                                                    type: 'checkbox',
                                                    checked
                                                }
                                            })
                                        }}
                                        disabled={onlyView && true}
                                        className="ml-2"
                                    />
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="recipe">
                                    Venta con receta
                                    <Checkbox
                                        id="recipe"
                                        name="recipe"
                                        checked={product.recipe}
                                        onCheckedChange={(checked) => {
                                            handleChange({
                                                target: {
                                                    name: "recipe",
                                                    value: checked,
                                                    type: 'checkbox',
                                                    checked
                                                }
                                            })
                                        }}
                                        disabled={onlyView && true}
                                        className="ml-2"
                                    />
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="fractionable">
                                    Fraccionable
                                    <Checkbox
                                        id="fractionable"
                                        name="fractionable"
                                        checked={product.fractionable}
                                        onCheckedChange={(checked) => {
                                            handleChange({
                                                target: {
                                                    name: "fractionable",
                                                    value: checked,
                                                    type: 'checkbox',
                                                    checked
                                                }
                                            })
                                        }}
                                        disabled={onlyView && true}
                                        className="ml-2"
                                    />
                                </Label>
                            </div>

                            <Label htmlFor="price_fraction">Precio por fracción:</Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="price_fraction"
                                name="price_fraction"
                                value={product.price_fraction}
                                onChange={handleChange}
                                required
                                disabled={onlyView ? true : !product.fractionable}
                            />
                        </div>
                        <div className="">
                            {
                                onlyView ?
                                    <div className="mb-3">
                                        <Label htmlFor="sku">Código de barras</Label>
                                        <ol style={{ listStyleType: "none", padding: 0 }}>
                                            {
                                                product.sku.map((codebar, index) => (
                                                    <li key={index} style={{
                                                        padding: "10px",
                                                        marginBottom: "5px",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#f8f9fa",
                                                        fontFamily: "'Courier New', Courier, monospace",
                                                        fontSize: "14px",
                                                    }}>
                                                        {codebar.text}
                                                    </li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                    :
                                    <div className="mb-3">
                                        <Label htmlFor="sku">Código de barras</Label>
                                        <TagsInput
                                            tags={product.sku}
                                            placeholder={"Escribe el codigo de barras y presiona enter"}
                                            name="sku"
                                            handleDelete={handleDeleteSku}
                                            handleAddition={handleAdditionSku}
                                            handleDrag={handleDragSku}
                                            disabled={onlyView && true}
                                        />
                                    </div>
                            }
                            <div className="mb-3">
                                <Label htmlFor="internal_code">
                                    Código Interno
                                </Label>
                                <Input
                                    type="text"
                                    id="internal_code"
                                    name="internal_code"
                                    value={product.internal_code}
                                    onChange={handleChange}
                                    required
                                    disabled={onlyView && true} />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="description">Descripción</Label>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={product.description}
                                    onChange={handleChange}
                                    disabled={onlyView && true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="active_principle">Principio Activo</Label>
                                <Input
                                    type="text"
                                    id="active_principle"
                                    name="active_principle"
                                    value={product.active_principle}
                                    onChange={handleChange}
                                    disabled={onlyView && true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="brand_id">Marca:</Label>
                                <Select
                                    id="brand_id"
                                    name="brand_id"
                                    value={product.brand_id}
                                    onValueChange={(value) => {
                                        handleChange({
                                            target:
                                            {
                                                name: "brand_id",
                                                value: value,
                                                type: 'select',
                                            }
                                        })
                                    }}
                                    disabled={onlyView && true}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Seleccionar Marca" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {brands.map((brand) => (
                                            <SelectItem key={brand.id} value={`${brand.id}`}>{brand.name}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="tags">Etiquetas:</Label>
                                {
                                    onlyView ?
                                        <ol style={{ listStyleType: "none", padding: 0 }}>
                                            {
                                                product.tags.map((tag, index) => (
                                                    <li key={index} style={{
                                                        padding: "10px",
                                                        marginBottom: "5px",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#f8f9fa",
                                                        fontFamily: "'Courier New', Courier, monospace", // Tipografía de código
                                                        fontSize: "14px",
                                                    }}>
                                                        {tag.text}
                                                    </li>
                                                ))
                                            }
                                        </ol>

                                        :
                                        <TagsInput
                                            tags={product.tags}
                                            placeholder={"Escribe alguna etiqueta y presiona enter"}
                                            name="tags"
                                            handleDelete={handleDeleteTags}
                                            handleAddition={handleAdditionTags}
                                            handleDrag={handleDragTags}
                                        />
                                }
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="category_id">Categoría:</Label>
                                <Select
                                    id="category_id"
                                    name="category_id"
                                    value={product.category_id}
                                    onValueChange={(value) => {
                                        handleChange({
                                            target:
                                            {
                                                name: "category_id",
                                                value: value,
                                                type: 'select',
                                            }
                                        })
                                    }}
                                    disabled={onlyView && true}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Seleccionar Categoría" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {categories.map((category) => (
                                            <SelectItem key={category.id} value={`${category.id}`}>{category.name}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                {
                                    attributes.map((attribute) => (
                                        <>
                                            <Label htmlFor={attribute.name}>{attribute.name}</Label>
                                            <Input
                                                type="text"
                                                id={attribute.id}
                                                name={attribute.name}
                                                value={valueAttributes[attribute.name]}
                                                onChange={handleChangeAttributes}
                                                disabled={onlyView && true}
                                            />
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {
                        !onlyView && !edit &&
                        <div className='flex flex-col'>
                            <Button type="submit">Añadir producto</Button>
                        </div>
                    }
                    {
                        edit &&
                        <div className='flex flex-col'>
                            <Button onClick={handleUpdate} className="w-full">Actualizar producto</Button>
                        </div>
                    }
                </>}
        </form>
    );
};

export default ProductForm;
