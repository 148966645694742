import { _delete, get, patch, post, put } from "./request";

export async function getAllDiscount(domain, token) {
  const data = await get(domain, token, `/api/discount`);

  return data;
}

export async function getOneDiscount(domain, token, id) {
  const data = await get(domain, token, `/api/discount/${id}`);
  return data;
}

export async function postDiscount(domain, token, body) {
  const data = await post(domain, token, "/api/discount", body);
  return data;
}

export async function putDiscount(domain, token, body, id) {
  const data = await put(domain, token, `/api/discount/${id}`, body);
  return data;
}

export async function patchDiscount(domain, token, body, id) {
  const data = await patch(domain, token, `/api/discount/${id}`, body);
  return data;
}

export async function deleteDiscount(domain, token, id) {
  const data = await _delete(domain, token, `/api/discount/${id}`);
  return data;
}
