import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Pencil from '../../assets/icons/pencil.svg';
import Trash from '../../assets/icons/trash.svg';
import Save from '../../assets/icons/save.svg';

const SupplierList = ({ suppliers, deleteSupplier,editSupplier }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = suppliers.slice(indexOfFirstItem, indexOfLastItem);

    const handleEditClick = (supplier) => {
        editSupplier(supplier)
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <h4>Lista de Proveedores</h4>
            <ul className="list-group">
                {currentItems.map((supplier, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{supplier.name}</span>
                        <div className='d-flex'>
                            <button className="button-no-style mx-3" onClick={() => handleEditClick(supplier)}>
                            <ReactSVG src={Pencil} className='text-dark' />
                            </button>
                            <button className="button-no-style" onClick={() => deleteSupplier(indexOfFirstItem + index)}>
                                <ReactSVG src={Trash} className='text-dark' />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
            {suppliers.length > itemsPerPage && (
                <nav className="mt-3">
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(suppliers.length / itemsPerPage) }, (_, index) => index + 1).map((pageNumber) => (
                            <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => paginate(pageNumber)}>
                                    {pageNumber}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default SupplierList;
