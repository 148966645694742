import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppContext } from '../../context/AppContext';
const swal = require('sweetalert2')

function PurchaseForm({ purchaseDefault }) {
    const { domain, token } = useContext(AppContext);
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [purchase, setPurchase] = useState({
        purchase_date: purchaseDefault?.purchase_date || '',
        supplier: purchaseDefault?.supplier.id || '',
        folio: purchaseDefault?.folio || 0,
        total_purchase: purchaseDefault?.total_purchase || 0,
        net_price: purchaseDefault?.net_price || 0,
        tax: purchaseDefault?.tax || 0,
    });
    const [details, setDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                await getProducts();
                await getSuppliers();
                if (purchaseDefault) {
                    await getDetails()
                }
                setLoader(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    async function getProducts() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/product`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        for (let index = 0; index < data.length; index++) {
            data[index].sku = JSON.parse(data[index].sku);
            data[index].tags = JSON.parse(data[index].tags);
        }
        setProducts(data)
    }

    async function getSuppliers() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/suppliers`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        setSuppliers(data)
    }

    async function getDetails() {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}/api/purchases_details`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
  
        const filteredPurchases = data.filter(details => details.purchase.id === purchaseDefault.id);
     
        const details = []
        for (let index = 0; index < filteredPurchases.length; index++) {
            const data = {
                quantity: filteredPurchases[index].quantity,
                unit_price: filteredPurchases[index].unit_price,
                discount: filteredPurchases[index].discount,
                tax: filteredPurchases[index].tax,
                total: filteredPurchases[index].total,
                product: filteredPurchases[index].product.id,
                productName: filteredPurchases[index].product.name
            }
            details.push(data)
        }
        setDetails(details)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleProductSearch();
        }
    };

    const handlePurchaseChange = (e) => {
        const { name, value } = e.target;
        setPurchase(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDetailChange = (index, e) => {
        const { name, value } = e.target;
        const newDetails = [...details];
        newDetails[index][name] = value;
        newDetails[index].total = Math.round(newDetails[index].quantity * newDetails[index].unit_price * (1 - newDetails[index].discount / 100));
        setDetails(newDetails);

        // Actualizar el total de la compra
        const totalPurchase = newDetails.reduce((acc, detail) => acc + detail.total * 1.19, 0);
        const tax = newDetails.reduce((acc, detail) => acc + detail.total * 0.19, 0);
        setPurchase(prevState => ({
            ...prevState,
            total_purchase: isNaN(Math.round(totalPurchase)) ? 0 : Math.round(totalPurchase),
            tax: isNaN(Math.round(tax)) ? 0 : Math.round(tax),
            net_price: isNaN(Math.round(totalPurchase - tax)) ? 0 : Math.round(totalPurchase - tax)
        }));
    };

    const addDetail = (product) => {
        setDetails([...details, { quantity: 0, unit_price: product.price, discount: 0, tax: 0.19, total: 0, product: product.id, productName: product.name }]);
    };

    const handleProductSearch = () => {
        const foundProduct = products.find(product =>
            product.sku.includes(searchQuery) || product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (foundProduct) {
            const existingDetail = details.find(detail => detail.product === foundProduct.id);
            if (existingDetail) {
                const updatedDetails = details.map(detail =>
                    detail.product === foundProduct.id ? { ...detail, quantity: detail.quantity + 1 } : detail
                );
                setDetails(updatedDetails);
            } else {
                addDetail(foundProduct);
            }
            setSearchQuery("")
        } else {
            alert('Product not found');
        }
    };
    const handleRemoveDetail = (index) => {
        const newDetails = details.filter((_, i) => i !== index);
        setDetails(newDetails);

        // Actualizar el total de la compra
        const totalPurchase = newDetails.reduce((acc, detail) => acc + detail.total * 1.19, 0);
        const tax = newDetails.reduce((acc, detail) => acc + detail.total * 0.19, 0);
        setPurchase(prevState => ({
            ...prevState,
            total_purchase: isNaN(Math.round(totalPurchase)) ? 0 : Math.round(totalPurchase),
            tax: isNaN(Math.round(tax)) ? 0 : Math.round(tax),
            net_price: isNaN(Math.round(totalPurchase - tax)) ? 0 : Math.round(totalPurchase - tax)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(purchase)
        console.log(details)
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;
        setLoader(true)
        await fetch(`${urlApi}/api/purchases`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                purchase_date: purchase.purchase_date,
                supplier_id: purchase.supplier,
                folio: purchase.folio,
                total_purchase: purchase.total_purchase,
                tax: purchase.tax,
                net_price: purchase.net_price
            })
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                setLoader(false)
                swal.fire({
                    title: "Error al ingresar compra",
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
                throw new Error('Network response was not ok.');
            }
        })
            .then(async data => {
                for (let index = 0; index < details.length; index++) {
                    const isProduction = process.env.NODE_ENV === 'production';
                    const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;
                    const detail = {
                        quantity: details[index].quantity,
                        unit_price: details[index].unit_price,
                        discount: details[index].discount,
                        tax: details[index].tax,
                        total: details[index].total,
                        purchase_id: data.id,
                        product_id: details[index].product,
                    }
                    await fetch(`${urlApi}/api/purchases_details`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`
                        },
                        body: JSON.stringify(detail)
                    })
                        .then(async (res) => {
                            const data = await res.json()
                            setPurchase({
                                purchase_date: '',
                                supplier: '',
                                folio: 0,
                                total_purchase: 0,
                                net_price: 0,
                                tax: 0,
                            })
                            setDetails([])
                            setSearchQuery('')
                            swal.fire({
                                title: "Compra Ingresada con exito",
                                icon: "success",
                                toast: true,
                                timer: 6000,
                                position: 'top-right',
                                timerProgressBar: true,
                                showConfirmButton: false,
                            })
                            setLoader(false)
                            console.log(data)
                        })
                }
            })
    };

    return (
        <div className="container">
            {
                loader ? <div className="d-flex justify-content-center my-2">
                    <div className="spinner-border" role="status" />
                </div> :
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Fecha de compra</label>
                            <input type="date" className="form-control" name="purchase_date" value={purchase.purchase_date} onChange={handlePurchaseChange} required />
                        </div>
                        <div className="form-group">
                            <label>Proveedor</label>
                            <select className="form-control" name="supplier" value={purchase.supplier} onChange={handlePurchaseChange} required>
                                <option value="">Selecciona un proveedor</option>
                                {suppliers.map(supplier => (
                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Folio</label>
                            <input type="number" className="form-control" name="folio" value={purchase.folio} onChange={handlePurchaseChange} required />
                        </div>
                        {
                            !purchaseDefault &&
                            <div className="form-group">
                                <label>Buscar producto por sku o Nombte</label>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchQuery}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Inbresa SKU o Nombre"
                                    />
                                    <div className="input-group-append">
                                        <button type="button" className="btn" onClick={handleProductSearch}>Añadir Producto</button>
                                    </div>
                                </div>
                            </div>
                        }
                        <h4>Detalle</h4>
                        {details.map((detail, index) => (
                            <div key={index} className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">{detail.productName}</h5>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Cantidad</label>
                                                <input type="number" className="form-control" name="quantity" value={detail.quantity} onChange={(e) => handleDetailChange(index, e)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Detalle</label>
                                                <input type="text" className="form-control" disabled value={detail.productName} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>$/unidad</label>
                                                <input type="number" className="form-control" name="unit_price" value={detail.unit_price} onChange={(e) => handleDetailChange(index, e)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Descuento</label>
                                                <input type="number" className="form-control" name="discount" value={detail.discount} onChange={(e) => handleDetailChange(index, e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Total Neto</label>
                                                <input type="number" className="form-control" name="total" value={detail.total} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                   {
                                    !purchaseDefault &&  <button type="button" className="w-100 mt-3 btn-danger" onClick={() => handleRemoveDetail(index)}>Eliminar</button>
                                   }
                                </div>
                            </div>
                        ))}

                       {
                        !purchaseDefault &&  <button type="submit" className="btn w-100">Añadir Compra</button>
                       }
                        <div className="mt-3">
                            <h4>Neto: {purchase.net_price}</h4>
                            <h4>IVA: {purchase.tax}</h4>
                            <h4>Total: {purchase.total_purchase}</h4>
                        </div>
                    </form>
            }
        </div>
    );
}

export default PurchaseForm;