import swal from 'sweetalert2';

export async function get(domain,token,endpoint) {
    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;
        const response = await fetch(`${urlApi}${endpoint}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar al servidor',
        });
        console.error(error);
    }
}

export async function post(domain,token,endpoint, body) {
    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;
        console.log(body)
        const response = await fetch(`${urlApi}${endpoint}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })

        const data = await response.json()
        return data
    } catch (error) {
        swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar al servidor',
        });
        console.error(error);
    }
}

export async function put(domain,token,endpoint, body) {
    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}${endpoint}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        const data = await response.json()
        return data
    } catch (error) {
        swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar al servidor',
        });
        console.error(error);
    }
}

export async function patch(domain,token,endpoint, body) {
    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        const response = await fetch(`${urlApi}${endpoint}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        const data = await response.json()
        return data
    } catch (error) {
        swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar al servidor',
        });
        console.error(error);
    }
}

export async function _delete(domain,token,endpoint) {
    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const urlApi = isProduction ? `https://${domain}` : `http://${domain}:4000`;

        await fetch(`${urlApi}${endpoint}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
    } catch (error) {
        swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar al servidor',
        });
        console.error(error);
    }
}