import React, { createContext, useEffect, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [succesSale, setSuccesSale] = useState(false);
  const [branchId, setBranchId] = useState(1);

  const [token, setToken] = useState("");
  const [domain, setDomain] = useState("");
  const [user, setUser] = useState({});

  const contextData = {
    succesSale,
    setSuccesSale,
    currentRoute,
    setCurrentRoute,
    currentBranch,
    setCurrentBranch,
    openSideBar,
    setOpenSideBar,
    token,
    setToken,
    domain,
    setDomain,
    user,
    setUser,
    branchId,
    setBranchId,
  };

  useEffect(() => {
    try {
      const authTokens = localStorage.getItem("authTokens");
      const domain = JSON.parse(authTokens).domain;
      const token = JSON.parse(authTokens).access;
      const user = JSON.parse(authTokens).user;
      setToken(token);
      setDomain(domain);
      setUser(user);
    } catch {
      console.log();
    }
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
