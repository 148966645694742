import { _delete, get, patch, post, put } from "./request";

export async function getAllClients(domain, token) {
  const data = await get(domain, token, "/api/clients");
  return data;
}

export async function getOneClients(domain, token, id) {
  const data = await get(domain, token, `/api/clients/${id}`);
  return data;
}

export async function postClients(domain, token, body) {
  const data = await post(domain, token, "/api/clients", body);
  return data;
}

export async function putClients(domain, token, body, id) {
  const data = await put(domain, token, `/api/clients/${id}`, body);
  return data;
}

export async function patchClients(domain, token, body, id) {
  const data = await patch(domain, token, `/api/clients/${id}`, body);
  return data;
}

export async function deleteClients(domain, token, id) {
  const data = await _delete(domain, token, `/api/clients/${id}`);
  return data;
}
