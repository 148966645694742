import { _delete, get, patch, post, put } from "./request";

export async function getAllCashRegisters(domain,token,){
    const data = await get(domain,token,'/api/cash_register')
    return data
}

export async function getOneCashRegister(domain,token,id){
    const data = await get(domain,token,`/api/cash_register/${id}`)
    return data
}

export async function postCashRegister(domain,token,body){
    const data = await post(domain,token,'/api/cash_register',body)
    return data
}

export async function putCashRegister(domain,token,body,id){
    const data = await put(domain,token,`/api/cash_register/${id}`,body)
    return data
}

export async function patchCashRegister(domain,token,body,id){
    const data = await patch(domain,token,`/api/cash_register/${id}`,body)
    return data
}

export async function deleteCashRegister(domain,token,id){
    const data = await _delete(domain,token,`/api/cash_register/${id}`)
    return data
}