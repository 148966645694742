import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'


function Register() {

  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [companyName, setCompanyName] = useState("")

  const { registerUser } = useContext(AuthContext)

  const handleSubmit = async e => {
    e.preventDefault()
    registerUser(email, username, password, companyName)
  }


  return (
    <div>
      <>
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-10">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <div className="row g-0 d-flex justify-content-center align-items-center h-100">
                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form onSubmit={handleSubmit}>
                          <div className="d-flex align-items-center mb-3 pb-1">
                            <i
                              className="fas fa-cubes fa-2x me-3"
                              style={{ color: "#ff6219" }}
                            />
                            <span className="h2 fw-bold mb-0">
                              Inscríbete ahora
                            </span>
                          </div>
                          <div className="form-outline mb-4">
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              placeholder="Email"
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-outline mb-4">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Nombre de usuario"
                              onChange={e => setUsername(e.target.value)}

                            />
                          </div>
                          <div className="form-outline mb-4">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Nombre de empresa"
                              onChange={e => setCompanyName(e.target.value)}

                            />
                          </div>
                          <div className="form-outline mb-4">
                            <input
                              type="password"
                              className="form-control form-control-lg"
                              placeholder="Contraseña"
                              onChange={e => setPassword(e.target.value)}

                            />
                          </div>
                          <div className="pt-1 mb-4">
                            <button
                              className="btn btn-dark btn-lg btn-block"
                              type="submit"
                            >
                              Registro
                            </button>
                          </div>
                          <a className="small text-muted" href="#!">
                            ¿Has olvidado tu contraseña?
                          </a>
                          <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                            Ya tienes una cuenta?{" "}
                            <Link to="/login" style={{ color: "#393f81" }}>
                              Inicia sesión ahora
                            </Link>
                          </p>
                          <a href="#!" className="small text-muted">
                            Condiciones de uso.
                          </a>
                          <a href="#!" className="small text-muted">
                            Política de privacidad
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="bg-light text-center text-lg-start">
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            © 2019 - till date Copyright:
            <a className="text-dark" href="https://mdbootstrap.com/">
              desphixs.com
            </a>
          </div>
          {/* Copyright */}
        </footer>
      </>

    </div>
  )
}

export default Register