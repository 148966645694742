import React, { useState, useEffect } from 'react';

const SupplierForm = ({ addSupplier, editSupplier, supplierToEdit }) => {
  const [formData, setFormData] = useState({
    name: '',
    giro: '',
    address: '',
    phone: '',
    email: ''
  });

  useEffect(() => {
    if (supplierToEdit) {
      setFormData({
        name: supplierToEdit.name || '',
        giro: supplierToEdit.giro || '',
        address: supplierToEdit.address || '',
        phone: supplierToEdit.phone || '',
        email: supplierToEdit.email || ''
      });
    }
  }, [supplierToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (supplierToEdit) {
      editSupplier(supplierToEdit.id, formData);
    } else {
      addSupplier(formData);
    }
    setFormData({
      name: '',
      giro: '',
      address: '',
      phone: '',
      email: ''
    });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-3">
      <div className="input-group mb-3">
        <input
          type="text"
          name="name"
          className="form-control"
          placeholder="Nombre del proveedor"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          name="giro"
          className="form-control"
          placeholder="Giro"
          value={formData.giro}
          onChange={handleChange}
        />
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          name="address"
          className="form-control"
          placeholder="Dirección"
          value={formData.address}
          onChange={handleChange}
        />
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          name="phone"
          className="form-control"
          placeholder="Teléfono"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="input-group mb-3">
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Correo Electrónico"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="btn">
        {supplierToEdit ? 'Actualizar Proveedor' : 'Añadir Proveedor'}
      </button>
    </form>
  );
};

export default SupplierForm;