import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import "moment/locale/es";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { AppContext } from "../context/AppContext";
import { DataTable } from "../components/ui/data-table";
import Modal from "../components/common/Modal";
import { ReactSVG } from "react-svg";
import Eye from "../assets/icons/eye.svg";
import Pencil from "../assets/icons/pencil.svg";
import { getAllCashRegisters } from "../api/cashRegister";
import { getAllSales } from "../api/sales";

const CashRegister = () => {
  const { domain, token } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoader(true);
      const logsData = await getAllCashRegisters(domain, token);
      const salesData = await getAllSales(domain, token);

      const logsWithTotalSales = logsData.map((log) => ({
        ...log,
        totalSales: calculateTotalSales(salesData, log.id),
        sumTotalSales: calculateTotalSumSales(salesData, log.id),
      }));

      setLogs(logsWithTotalSales);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const calculateTotalSales = (sales, cashRegisterLogId) => {
    const filteredSales = sales.filter(
      (sale) => sale.cashRegisterLog.id === cashRegisterLogId
    );
    const totalSales = filteredSales.length;
    return totalSales;
  };

  const calculateTotalSumSales = (sales, cashRegisterLogId) => {
    const filteredSales = sales.filter(
      (sale) => sale.cashRegisterLog.id === cashRegisterLogId
    );
    let sum = 0;
    for (let index = 0; index < filteredSales.length; index++) {
      sum += parseInt(filteredSales[index].gross_price);
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "CLP",
    }).format(sum);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "Id",
      filterFn: (row, columnId, filterValue) => {
        return row.original.id.toString().includes(filterValue);
      },
    },
    {
      accessorKey: "opened_at",
      header: "Abierto el",
      meta: {
        filterVariant: "date",
      },
      cell: ({ row }) => {
        const log = row.original;
        return moment(log.opened_at)
          .tz("America/Santiago")
          .format("DD MMMM YYYY hh:mm:ss");
      },
      filterFn: (row, columnId, filterValue) => {
        const timeZone = "America/Santiago";
        if (filterValue.to !== undefined) {
          const dateFrom = moment.tz(filterValue.from, timeZone);
          const dateTo = moment.tz(filterValue.to, timeZone);
          const filterDate = moment.tz(row.original.opened_at, timeZone);
          return filterDate.isBetween(dateFrom, dateTo);
        } else {
          if (filterValue.from) {
            const filterDate = moment.tz(row.original.opened_at, timeZone);
            const dateFrom = moment.tz(filterValue.from, timeZone);

            return (
              filterDate.format("YY-MM-DD") === dateFrom.format("YY-MM-DD")
            );
          } else {
            return true;
          }
        }
      },
    },
    {
      accessorKey: "closed_at",
      header: "Cerrado el",
      meta: {
        filterVariant: "date",
      },
      cell: ({ row }) => {
        const log = row.original;
        return moment(log.closed_at)
          .tz("America/Santiago")
          .format("DD MMMM YYYY hh:mm:ss");
      },
      filterFn: (row, columnId, filterValue) => {
        const timeZone = "America/Santiago";
        if (filterValue.to !== undefined) {
          const dateFrom = moment.tz(filterValue.from, timeZone);
          const dateTo = moment.tz(filterValue.to, timeZone);
          const filterDate = moment.tz(row.original.closed_at, timeZone);
          return filterDate.isBetween(dateFrom, dateTo);
        } else {
          if (filterValue.from) {
            const filterDate = moment.tz(row.original.closed_at, timeZone);
            const dateFrom = moment.tz(filterValue.from, timeZone);

            return (
              filterDate.format("YY-MM-DD") === dateFrom.format("YY-MM-DD")
            );
          } else {
            return true;
          }
        }
      },
    },
    {
      accessorKey: "totalSales",
      header: "Numero de ventas",
    },
    {
      accessorKey: "sumTotalSales",
      header: "Total vendido",
    },
    {
      accessorKey: "starting_money",
      header: "Dinero Inicial",
      cell: ({ row }) => {
        const log = row.original;
        const money = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "CLP",
        }).format(log.starting_money);
        return money;
      },
    },
    {
      accessorKey: "open",
      header: "Estado",
      cell: ({ row }) => {
        const log = row.original;
        return <p> {log.open ? "Abierto" : "Cerrado"}</p>;
      },
    },
  ];

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h4>Registros de cajas</h4>
          <DataTable
            data={logs}
            columns={columns}
            filterDate={true}
            columnDate={"opened_at"}
          />
        </div>
      )}
    </>
  );
};

export default CashRegister;
