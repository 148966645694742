import React, { useContext, useEffect, useState } from 'react'
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';
import { AppContext } from '../../context/AppContext';
import { getAllCategories, postCategory, deleteCategory as _deleteCategory, putCategory } from '../../api/categories';

function Category() {
    const [categories, setCategories] = useState([]);
    const [loader, setLoader] = useState(false);
    const { domain, token } = useContext(AppContext);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoader(true)
                const category = await getAllCategories(domain, token);
                setCategories(category);
                setLoader(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [])

    const addCategory = async (name) => {
        setLoader(true)
        await postCategory(domain, token, {
            name: name
        })
        const category = await getAllCategories(domain, token);
        setCategories(category);
        setLoader(false)
    };

    const deleteCategory = async (id) => {
        setLoader(true)
        await _deleteCategory(domain, token, id)
        const category = await getAllCategories(domain, token);
        setCategories(category);
        setLoader(false)
    };

    const editCategory = async (id, newName) => {
        setLoader(true)
        await putCategory(domain, token, {
            name: newName
        }, id)
        const category = await getAllCategories(domain, token);
        setCategories(category);
        setLoader(false)
    };

    return (
        <div>
            <CategoryForm addCategory={addCategory} />
            <CategoryList categories={categories} deleteCategory={deleteCategory} editCategory={editCategory} />
        </div>
    );
}

export default Category