import React, { useState } from 'react';
import AttributeForm from './AttributeForm';
import Modal from '../common/Modal';
import { Button } from '../ui/button';
import { DataTable } from '../ui/data-table';

const AttributeList = ({ attributes, deleteAttribute, editAttribute }) => {
    const columns = [
        {
            accessorKey: "id",
            header: "Id",
        },
        {
            accessorKey: "name",
            header: "Nombre",
        },
        {
            accessorKey: "actions",
            header: "Acciones",
            cell: ({ row }) => {
                const attribute = row.original
                return (
                    <>
                        <Modal
                            name="Editar"
                            title="Editar Atributo"
                            className={"w-100 mb-2 btn-primary"}
                            content={<AttributeForm attribute={attribute} editAttribute={editAttribute} />} />
                        <Button onClick={() => { deleteAttribute(attribute.id) }}>Eliminar Atributo</Button>
                    </>
                )
            }
        },
    ]

    return (
        <div>
            <h4>Lista de Atributos</h4>
            <DataTable columns={columns} data={attributes} />
        </div>
    );
};

export default AttributeList;
