"use client"

import * as React from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { cn } from "../../lib/utils"
import { Button } from "../ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover"

export function Combobox({options, placeholder, onSelect, defaultValue, className, ...props}) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(defaultValue ? defaultValue.name : '')
  const [filterResults, setFilterResults] = React.useState(options)

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          id="cb-btn"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-[200px] justify-between ${className}`}
        >
          {value
            ? options.find((option) => option.name === value)?.name
            : placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent combobox={true} className={`w-[200px] p-0 ${className}`}>
        <Command>
          <CommandInput placeholder={placeholder} className="h-9" onOpenChange={(e)=>{
            const results = options.filter(option => 
              option.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilterResults(results)
          }} />
          <CommandList>
            <CommandEmpty>No hay resultados.</CommandEmpty>
            <CommandGroup>
              {filterResults.map((option) => (
                <CommandItem
                  key={option.id}
                  value={option.id}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue)
                    onSelect(option)
                    setOpen(false)
                  }}
                >
                  {option.name}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      value === option.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
