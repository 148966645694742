import React from "react";
import Branch from "../components/branch/Branch";
import CompanyData from "../components/company/CompanyData";

function Company() {
  return (
    <div className="p-2">
      <h3 className="font-bold text-lg text-center mb-4">Mi Empresa</h3>
      <div className="columns-2">
        <div className="">
          <CompanyData />
        </div>
        <div className="mt-3">
          <Branch />
        </div>
      </div>
    </div>
  );
}

export default Company;
